import React, { useEffect,useState} from 'react';
import axios from 'axios';
import { url } from '../../helper/helper';
import { withTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';
import { useHistory } from 'react-router-dom';
const ImpersonateUser = () => {
    const history = useHistory();
    const token = localStorage.getItem('token');
    const Lang = localStorage.getItem('_lng');
    const [loggedIn, setLoggedIn] = useState(false);
    const [user_id, setUser_id] = useState('');
    useEffect(() => {
        const auth_token = window.location.pathname.split('/').pop(); // Extract the token from the URL
        handleImpersonation(auth_token);
        if (Lang) {
            i18n.changeLanguage(Lang);
          }
          if (token) {
            setLoggedIn(true);
          }
      }, [Lang, token]);
 
      const handleImpersonation = async (auth_token) => {
        try {
          // Make a secure API request to Laravel backend to validate the token
          const res = await axios.get(`${url}/api/impersonate/${auth_token}`);
          console.log(res);
         // const userData = await response.json();
          setUser_id(res?.data?.success.login_user_id);

        localStorage.setItem('token', res?.data?.success.token);
        localStorage.setItem('Login_user_id', res?.data?.success.login_user_id);
        localStorage.setItem('Login_user_role', res?.data?.role[0]);
        localStorage.setItem(
          'Login_user_uuid',
          res?.data?.success.login_user_uuid
        );

        localStorage.setItem(
          'Login_user_permissions',
          JSON.stringify(res?.data?.permissions)
        );
        history.push('/Dashboard');
          // Use the user data to establish a session for the impersonated user in the React app
          // You might want to store the user data in your app's state or context
        //  console.log(userData);
        } catch (error) {
          // Handle errors, e.g., unauthorized request
          console.error(error);
        }
      };
      
//       const handleImpersonation  = async (token) => {
//     try {
//       const response = await axios.get(`${url}/api/impersonate/${token}`);
//       console.log(response.data);
//     } catch (error) {
//       console.error('Impersonation failed:', error.response.data);
//     }
//   };

 

return <div>...</div>;
};

export default ImpersonateUser;
