import React, { useEffect, useState, useCallback } from 'react';

export const filterContacts = (searchField, contacts) => {
  return contacts?.filter((contact) =>
    contact?.group_name.toLowerCase().includes(searchField.toLowerCase())
  );
};

export const filterClients = (searchField, contacts) => {
  return contacts
    ?.map((contact) => {
      return {
        ...contact,
        group_name: contact.first_name + ' ' + contact.last_name,
      };
    })
    .filter((contact) => {
      return contact?.group_name
        .toLowerCase()
        .includes(searchField.toLowerCase());
    });
};

export const HandleWindowResizing = () => {
  const [windowSize, setWindowSize] = useState(0);
  const handleWindowResize = useCallback((event) => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    handleWindowResize();
  }, []);
  return [windowSize];
};

const AccordionItemChat = ({
  messages,
  setId,
  groupId,
  isExpanded,
  fetchData,
  setGroupId,
  setProjectId,
  windowSize,
  setOpen,
}) => {
  return (
    <>
      {messages?.map((message) => {
        return (
           
                <li
                  onClick={() => {
                    setId(message?.group_id);
                    setGroupId(message?.group_id);
                    fetchData(message?.group_id,message?.group_name);
                    setProjectId(message?.project_id);
                    setOpen(false);
                  }}
                  key={message.id}
                  className={
                    message.group_id === groupId
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <div className="group relative flex items-center px-5 py-6">
                            <a   className="-m-1 block flex-1 p-1">
                              <div className="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true" />
                              <div className="relative flex min-w-0 flex-1 items-center">
                              
                                <div className="ml-4 truncate">
                                  <p className="truncate text-xl  text-gray-900">{message?.group_name}</p>
                                  
                                  

                                  {message.unread_message_count > 0 &&
                      message.group_id !== groupId ? (
                        <div class="absolute inline-flex items-center justify-center w-10 h-10 text-base font-normal text-white bg-red-500 border-2 border-white rounded-full -top-0 -end-0 dark:border-gray-900">
                          {message?.unread_message_count}
                          </div>
                      ) : (
                        ''
                      )}
                                  
                                  
                                  
                                </div>
                              </div>
                            </a>


                   
                    
                  </div>
                </li>
               
        );
      })}
    </>
  );
};
export default AccordionItemChat;
