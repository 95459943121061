import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Helper, url } from '../../../helper/helper';
import { Multiselect } from 'multiselect-react-dropdown';
import {
  loadResources,
   
} from '../../../helper/generalFunctions/generalFunctions';
const lest = [];
class InviteModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resoucres:[],
      email: '',
      loading: false,
      email_err: false,
      success: '',
      selectedValue: [], // Add this line
    };
  }

  dateFormat(num) {
    return num.replaceAll('-', '.');
  }
  onSelect(selectedList, selectedItem) {
    lest.push(selectedItem.email);
  }

  onRemove(selectedList, removedItem) {
    const d = lest.indexOf(removedItem.email);
    lest.splice(d, 1);
  }
  // onSelect(selectedItem) {
  //   const updatedSelectedValues = [...this.state.selectedValue, selectedItem];
  //   this.setState({ selectedValue: updatedSelectedValues });
  // }
  
  // onRemove(removedItem) {
  //   const updatedSelectedValues = this.state.selectedValue.filter(
  //     (item) => item !== removedItem
  //   );
  //   this.setState({ selectedValue: updatedSelectedValues });
  // }
  
  componentDidMount = () => {
    this._isMounted = true;
    if (this._isMounted) {
      loadResources().then((res) => {
        if (res.length > 0) {
          const d = res.filter((x) => x.email);
          console.log("RR-------", d);
          this.setState({ resoucres: d });
        }
      //   if (Array.isArray(res) && res.length > 0 && res[0].email) {
      //     const emails = res.map((item) => item.email);
      //     console.log("RR-------", emails);
  
      //     this.setState({ resoucres: emails });
      //   } else {
      //     console.error("RR------- Invalid API response format:", res);
      //   }
       });
    }
  };
  
  
 
  handleInviteSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    if (this.state.email === '' || this.state.email === undefined) {
      this.setState({ email_err: true, loading: false });
      return;
    }

    const token = await localStorage.getItem('token');
    const data = new FormData();
    data.set('email', this.state.email);
    data.set('resources', lest);
    
    try {
        await axios.post(`${url}/api/invite-by-email`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {

      this.setState({
        email: '',
        success: 'Your Request has been submitted successfully',
        loading: false,
      });
    });

    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 401) {
        // Handle 401 status code (validation error for email)
        // For example, update the state to show an error message
        this.setState({
          loading: false,
          email_err: true,
        });
      } else {
        // Handle other errors
        this.setState({
          loading: false,
        });
      }
    }
  };

  render() {
    const { t, show, handleClose } = this.props;
    const { email, loading, email_err, success } = this.state;
  //  console.log("RR-------",this.state.resoucres);
    return (
      <Modal
        isOpen={show}
        toggle={() => handleClose()}
        className={'modalPropu preview-modal'}
        centered
      >
        <ModalHeader toggle={() => handleClose()}>
          {t('marketplace.material.create_material_list.request.invite_by_email')}
        </ModalHeader>
        <ModalBody>
          <div className='pdf-section'>
            <div className='col-md'>
              <div className='details-content'>
                <div className='head'>
                  {/* <h4>{t('marketplace.material.create_material_list.request.invite_by_email')}</h4> */}
                </div>

                <div className='row'>
                  <div className='form-group'>
                  <div className='col-12 col-md-12'>
                    <label htmlFor='email'>
                      {t('marketplace.material.create_material_list.request.add_resources_email')}
                    </label>
                    <Multiselect
  options={this.state.resoucres}
  selectedValues={this.state.selectedValue}
  onSelect={(e) => this.check(e)}
  onSelect={this.onSelect}
  onRemove={this.onRemove}
  displayValue='email' // Change this to the property you want to display
  emptyRecordMsg={t(
    'marketplace.material.create_material_list.offer.No_Options_Available'
  )}
  placeholder={t('marketplace.material.create_material_list.request.input_email')}
/>

<br/>
<label htmlFor='email'>
                      {t('marketplace.material.create_material_list.request.multiple_email')}
                    </label>
                    <input
                      required
                      id='email'
                      name='email'
                      type='text'
                      className='form-control'
                      value={email}
                      onChange={(e) => this.setState({ email: e.target.value, email_err: false })}
                    />
                    {email_err && <span style={{ color: 'red' }}>{t('marketplace.material.create_material_list.request.email_err')}</span>}
                  </div>
                  </div>
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <div className='col-12 col-md-12'>
                    <button
                      className='btn btn-primary'
                      onClick={(e) => this.handleInviteSubmit(e)}
                    >
                      {t('marketplace.material.create_material_list.request.invite_button')}
                    </button>
                    </div>
                  )}
                </div>
                {success && <p style={{ color: 'green' }}>{success}</p>}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default withTranslation()(InviteModel);
