import React, { Component } from 'react';
import axios from 'axios';
import { url } from '../../helper/helper';
import Logo from '../../images/Full-Logo-lighter.png';
import FavLogo from '../../images/favicon-32.png';
import { Link, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';
import img from '../../images/DefaultImg.png';
import { initializeUserData } from '../../store/userSlice/userActionCreators';
import { store } from '../../store/appStore';
import { Fragment } from 'react';
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { FaRegCommentAlt, FaRegBell } from 'react-icons/fa';
import { ReactComponent as English } from '../../images/english.svg';
import { ReactComponent as Finland } from '../../images/finland.svg';
import { ReactComponent as Estonia } from '../../images/estonia.svg';
import {
  getData,
  modifyDataWithToken,
  postDataWithToken,
} from '../../helper/api';
import './header.css';
import Banner from './Banner';
import { loadAccount } from '../../helper/generalFunctions/generalFunctions';

class Header extends Component {
  state = {
    loggedIn: true,
    count: 0,
    unread: 0,
    info: [],
    notif: [],
    show: false,
    token: localStorage.getItem('token'),
    clickedNotif: 0,
    Login_user_permissions: localStorage.getItem('Login_user_permissions')
      ? localStorage.getItem('Login_user_permissions')
      : [],
  };

  componentDidMount = () => {
    i18n.changeLanguage(this.props.i18n.language);
    // localStorage.setItem('i18nextLng', this.props.i18n.language);
    // localStorage.setItem('_lng', this.props.i18n.language);
    this._isMounted = true;

    this.loadToken();
    this.loadNotif();
    this.loadUnreadNotif();
    loadAccount().then((res) => {
      if (this._isMounted) {
        this.setState({
          package: res?.package_price,
          country_id: res?.address_country,
        });
        i18n.changeLanguage(res?.language);
        localStorage.setItem('i18nextLng', res?.language);
        localStorage.setItem('_lng', res?.language);
        this.setState({ info: res });
        store.dispatch(initializeUserData(res));
      }
    });
  };

  componentDidUpdate = (prevState, prevProps) => {
    this._isMounted = true;
    if (this.state.count !== this.state.unread) {
      this.loadNotif();
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadNotif = async () => {
    const token = localStorage.getItem('token');
    await getData(`${url}/api/getBidsNotif`, token)
      .then((response) => {
        this.setState({
          notif: response.data.data,
          count: response.data.count,
        });
      })
      .catch((err) => {
        if (err?.response?.status >= 400 && err?.response?.status <= 499) {
          localStorage.clear();
          this.setState({ loggedIn: false });
        }
        if (axios.isCancel(err)) {
        }
      });
  };

  loadUnreadNotif = async () => {
    if (this._isMounted) {
      const token = this.state.token;
      await getData(`${url}/api/notification/unread`, token)
        .then((response) => {
          if (this._isMounted) {
            this.setState({
              unread: response.data.data,
            });
          }
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
          }
        });
      setTimeout(() => {
        this.loadUnreadNotif();
      }, 30000);
    }
  };

  readNotif = async (id) => {
    const token = localStorage.getItem('token');
    await modifyDataWithToken(`${url}/api/notification/read/${id}`, null, token)
      .then(() => {
        this.loadUnreadNotif();
      })
      .catch(() => {});
  };

  readNotifAll = async () => {
    const token = localStorage.getItem('token');
    await modifyDataWithToken(`${url}/api/notification/readall`, null, token)
      .then(() => {
        this.loadUnreadNotif();
      })
      .catch(() => {});
  };

  loadToken = async () => {
    const token = localStorage.getItem('token');
    if (token == null) {
      this.setState({ loggedIn: false });
    }
  };

  Set_leng = async (lng) => {
    const data = new FormData();
    data.set('user_id', localStorage.getItem('Login_user_id'));
    data.set('language_code', lng);

    const token = localStorage.getItem('token');
    await postDataWithToken(`${url}/api/update_language_flag`, data, token)
      .then(() => {
        this.setState({ success: true, password_err: false });
        this.myRef.current.scrollTo(0, 0);
      })
      .catch(() => {});
  };

  classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  render() {
    if (
      this.state.loggedIn === false
      // !window.location.pathname.includes('api/profile')
    ) {
      return <Redirect to='/' />;
    }

    const { t, setSidebarOpen } = this.props;

    const notif = this.state.notif
      ? this.state.notif.map((notification, index) => (
          <div key={index}>
            {notification.sender_isLogged &&
            notification.notification_type === 'tender_message' ? (
              <Link
                className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                onClick={() => this.readNotif(notification.notification_id)}
                to={
                  notification.notification_user_id ===
                  notification.tender_user_id
                    ? `/bid-detail/${notification.tender_bid_id}/${notification.notification_sender_id}`
                    : `/Biddetails/${notification.tender_bid_id}`
                }
              >
                {t('b_sidebar.messaging.message')} {notification.sender}
              </Link>
            ) : notification.sender_isLogged &&
              notification.notification_type === 'project_task_created' ? (
              <Link
                className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                onClick={() => this.readNotif(notification.notification_id)}
                to='/manage-projects'
              >
                {notification.notification_message}
              </Link>
            ) : notification.sender_isLogged &&
              notification.notification_type === 'proposal_message' ? (
              <Link
                className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                onClick={() => this.readNotif(notification.notification_id)}
                to={
                  notification.notification_user_id ===
                  notification.tender_user_id
                    ? '/my-actions/'
                    : '/my-actions/offers'
                }
              >
                {t('b_sidebar.messaging.message')} {notification.sender}
              </Link>
            ) : notification.sender_isLogged &&
              notification.notification_type === 'project_report_sent' ? (
              <Link
                className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                onClick={() => this.readNotif(notification.notification_id)}
                to={
                  notification.notification_user_id ===
                  notification.tender_user_id
                    ? '/create-report'
                    : '/create-report'
                }
              >
                {notification.sender} {t('b_sidebar.messaging.newReport')}
              </Link>
            ) : notification.sender_isLogged &&
              notification.notification_type === 'agreement_message' ? (
              <Link
                className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                onClick={() => this.readNotif(notification.notification_id)}
                to={
                  notification.notification_user_id ===
                  notification.tender_user_id
                    ? '/my-actions/'
                    : '/my-actions/contracts'
                }
              >
                {t('b_sidebar.messaging.message')} {notification.sender}
              </Link>
            ) : notification.sender_isLogged &&
              notification.notification_type === 'submit-offer' ? (
              <Link
                className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                to='/my-actions/bids'
                onClick={() => this.readNotif(notification.notification_id)}
              >
                {t('b_sidebar.messaging.SubmitOffer')} {notification.sender}
              </Link>
            ) : notification.sender_isLogged &&
              notification.notification_type === 'accept-bid' ? (
              <Link
                className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                to='/my-actions/bids'
                onClick={() => this.readNotif(notification.notification_id)}
              >
                {notification.reciever}
                          {' '}
                          {t('b_sidebar.messaging.BidAccepted')}
                          {' '}
                          {notification.sender}

                 
              </Link>
            ) : notification.sender_isLogged &&
              notification.notification_type === 'decline-bid' ? (
              <Link
                className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                to='/my-actions/bids'
                onClick={() => this.readNotif(notification.notification_id)}
              >
                {notification.sender} {t('header.Proposal_declined')}
              </Link>
            ) : notification.sender_isLogged &&
              notification.notification_type === 'bid_made' ? (
              <Link
                className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                to={{
                  pathname: `/listing-detail/${notification.notification_bid_id}`,
                }}
                onClick={() => this.readNotif(notification.notification_id)}
                //to='/my-actions'
              >
                {t('b_sidebar.messaging.BidMade')} {notification.sender}
              </Link>
            ) : null}

            {notification.sender_isLogged &&
            notification.notification_type === 'proposal_sent' ? (
              <div>
                {' '}
                {/* <Link
                  onClick={() => this.readNotif(notification.notification_id)}
                  className='dropdown-item'
                  to='/my-actions'
                >
                  {notification.notification_type === 'accept-bid'
                    ? `Bid accepted by ${notification.sender}`
                    : notification.notification_type === 'decline-bid'
                    ? `Bid declined by ${notification.sender}`
                    : null}
                </Link>{' '}
                {notification.sender_isLogged &&
                notification.notification_type === 'proposal_sent' ? ( */}
                <Link
                  to={{
                    pathname: `/my-actions/offers`,
                  }}
                  className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                  onClick={() => this.readNotif(notification.notification_id)}
                >
                  {notification.sender} {t('b_sidebar.messaging.OfferSent')}{' '}
                </Link>
                {/* ) : null} */}
              </div>
            ) : null}

            {notification.sender_isLogged &&
            notification.notification_type === 'agreement_sent' ? (
              <div>
                {notification.sender_isLogged &&
                notification.notification_type === 'agreement_sent' ? (
                  <Link
                    to={{
                      pathname: `/my-actions/contracts`,
                    }}
                    className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                    onClick={() => this.readNotif(notification.notification_id)}
                  >
                    {t('b_sidebar.messaging.ContractSent')}
                    {''}
                    {notification.sender}
                  </Link>
                ) : null}
              </div>
            ) : null}
            {/* {notification.sender_isLogged && notification.notification_type} */}
            {notification.sender_isLogged &&
            notification.notification_type === 'invoice_sent' ? (
              <div>
                <Link
                  to={{
                    pathname: `/invoice-list`,
                  }}
                  className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                  onClick={() => this.readNotif(notification.notification_id)}
                >
                  {notification.sender_isLogged &&
                  notification.notification_type === 'invoice_sent'
                    ? `Invoice sent by ${notification.sender} on email`
                    : null}
                </Link>{' '}
              </div>
            ) : null}

            {(notification.sender_isLogged &&
              notification.notification_type === 'agreement_accepted') ||
            notification.notification_type === 'agreement_declined' ||
            notification.notification_type === 'agreement_revision' ? (
              <div>
                {notification.sender_isLogged &&
                notification.notification_type === 'agreement_revision' ? (
                  <Link
                    to={{
                      pathname: `/my-actions/contracts`,
                    }}
                    className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                    onClick={() => this.readNotif(notification.notification_id)}
                  >
                    {notification.sender}
                    {t('b_sidebar.messaging.contract_revision')}{' '}
                    {t('b_sidebar.messaging.contract_for_request')}{' '}
                    {notification.notification_user_id}{' '}
                    {notification.notification_bid_id}
                  </Link>
                ) : notification.sender_isLogged &&
                  notification.notification_type === 'agreement_accepted' ? (
                  <Link
                    className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                    onClick={() => this.readNotif(notification.notification_id)}
                  >
                    {notification.sender}
                    {t('b_sidebar.messaging.ContractAccepted')}:{' '}
                    {notification.notification_message}
                  </Link>
                ) : notification.sender_isLogged &&
                  notification.notification_type === 'agreement_declined' ? (
                  <Link
                    className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                    onClick={() => this.readNotif(notification.notification_id)}
                  >
                    {' '}
                    {notification.sender}
                    {t('b_sidebar.messaging.ContractDeclined')}:{' '}
                    {notification.notification_message}
                  </Link>
                ) : notification.sender_isLogged &&
                  notification.notification_type !== 'agreement_revision' ? (
                  <Link
                    className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                    onClick={() => this.readNotif(notification.notification_id)}
                  >
                    {notification.sender}
                    {t('b_sidebar.messaging.ContractAccepted')}:{' '}
                    {notification.notification_message}
                  </Link>
                ) : null}
              </div>
            ) : null}

            {(notification.sender_isLogged &&
              notification.notification_type === 'proposal_accepted') ||
            notification.notification_type === 'proposal_declined' ||
            notification.notification_type === 'proposal_revision' ? (
              <div>
                {notification.sender_isLogged &&
                notification.notification_type === 'proposal_revision' ? (
                  <Link
                    to={{
                      pathname: `/my-actions/offers`,
                    }}
                    className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                    onClick={() => this.readNotif(notification.notification_id)}
                  >
                    {notification.sender}{' '}
                    {t('b_sidebar.messaging.offer_revision')}{' '}
                    {t('b_sidebar.messaging.offer_for_request')}{' '}
                    {notification.notification_user_id}
                    {notification.notification_bid_id}
                  </Link>
                ) : notification.sender_isLogged &&
                  notification.notification_type === 'proposal_accepted' ? (
                  <Link
                    to={{
                      pathname: `/my-actions/offers`,
                    }}
                    className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                    onClick={() => this.readNotif(notification.notification_id)}
                  >
                    {notification.sender}
                    {t('b_sidebar.messaging.offer_accepted')}:{' '}
                    {notification.notification_message}
                  </Link>
                ) : notification.sender_isLogged &&
                  notification.notification_type === 'proposal_declined' ? (
                  <Link
                    to={{
                      pathname: `/my-actions/offers`,
                    }}
                    className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                    onClick={() => this.readNotif(notification.notification_id)}
                  >
                    {notification.sender}
                    {t('b_sidebar.messaging.offer_declined')}:{' '}
                    {notification.notification_message}
                  </Link>
                ) : notification.sender_isLogged &&
                  notification.notification_type !== 'proposal_revision' ? (
                  <Link
                    to={{
                      pathname: `/my-actions/offers`,
                    }}
                    className='block text-xl hover:no-underline text-gray-700 font-medium  border-b border-gray-100 py-2.5 min-w-3xl'
                    onClick={() => this.readNotif(notification.notification_id)}
                  >
                    {notification.sender}
                    {t('b_sidebar.messaging.offer_accepted')}:{' '}
                    {notification.notification_message}
                  </Link>
                ) : null}
              </div>
            ) : null}
          </div>
        ))
      : null;

    const { Login_user_permissions } = this.state;

    const filter_my_business =
      Login_user_permissions.length > 0
        ? JSON.parse(Login_user_permissions)?.filter((x) => x === 'my_business')
        : [];
    const filter_marketplace =
      Login_user_permissions.length > 0
        ? JSON.parse(Login_user_permissions)?.filter((x) => x === 'marketplace')
        : [];

    return (
      <>
        {' '}
        {/* <h1 className='text-3xl font-bold text-white text-red-900 bg-custom-proppu-purple text-center'>
          Demo
        </h1> */}
        <>
          <div className='sticky top-0 z-40 flex h-20 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8'>
            <button
              type='button'
              className='-m-2.5 p-2.5 text-gray-700 lg:hidden'
              onClick={() => setSidebarOpen(true)}
            >
              <span className='sr-only'>Open sidebar</span>
              <Bars3Icon className='h-6 w-6' aria-hidden='true' />
            </button>

            <div
              className='h-8 w-px bg-gray-200 lg:hidden'
              aria-hidden='true'
            />

            <div className='flex flex-1 gap-x-4 self-stretch lg:gap-x-6'>
              <div className='relative flex flex-1'></div>
              {/* <form className='relative flex flex-1' action='#' method='GET'>
                <label htmlFor='search-field' className='sr-only'>
                  Search
                </label>
                <MagnifyingGlassIcon
                  className='pointer-events-none absolute inset-y-0 left-0 h-full w-7 text-gray-400'
                  aria-hidden='true'
                />
                <input
                  id='search-field'
                  className='block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-xl'
                  placeholder='Search...'
                  type='search'
                  name='search'
                />
              </form> */}
              <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                <Menu as='div' className='relative ml-3'>
                  <Menu.Button>
                    <span className='absolute -inset-1.5' />
                    <span className='sr-only'>View notifications</span>

                    <div className='relative rounded-full bg-white p-1 text-gray-400 hover:text-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                      <Link to='/pms/messages'>
                        <FaRegCommentAlt
                          style={{ fontSize: '24px', color: 'bdbdbd' }}
                        />
                      </Link>
                    </div>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100 '
                    enterFrom='transform opacity-0 scale-95 '
                    enterTo='transform opacity-100 scale-100 '
                    leave='transition ease-in duration-75 '
                    leaveFrom='transform opacity-100 scale-100 '
                    leaveTo='transform opacity-0 scale-95 '
                  >
                    <Menu.Items className='absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      <Menu.Item></Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <Menu as='div' className='relative ml-3'>
                  <Menu.Button>
                    <span className='absolute -inset-1.5' />
                    <span className='sr-only'>View notifications</span>

                    <div className='relative rounded-full bg-white p-1 text-gray-400 hover:text-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                      <FaRegBell
                        className='h-10 w-12'
                        style={{ fontSize: '24px', color: 'bdbdbd' }}
                      />
                      {this.state.unread > 0 ? (
                        <span className='bg-red-600 text-white h-7 w-7 text-center absolute rounded-full bottom-2 left-5'>
                          {this.state.unread}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100 '
                    enterFrom='transform opacity-0 scale-95 '
                    enterTo='transform opacity-100 scale-100 '
                    leave='transition ease-in duration-75 '
                    leaveFrom='transform opacity-100 scale-100 '
                    leaveTo='transform opacity-0 scale-95 '
                  >
                    <Menu.Items
                      style={{ width: '250px' }}
                      className='absolute w-3xl right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none px-6 py-6'
                    >
                      <Menu.Item className=''>
                        {({ active }) => (
                          <Link
                            className={`${active ? 'block ' : 'block'}`}
                            to='/my-notif'
                            onClick={() => this.readNotifAll()}
                          >
                            {notif.length >= 10 ? notif.splice(0, 10) : notif}
                            <span className='block text-xl hover:no-underline text-blue-700 font-medium  py-2.5'>
                              {t('header.view_all')}
                            </span>
                          </Link>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <div
                  className='hidden lg:block lg:h-8 lg:w-px lg:bg-gray-200 mx-3'
                  aria-hidden='true'
                />
                {/* Profile dropdown */}
                <Menu as='div' className='relative'>
                  <Menu.Button className='-m-1.5 flex items-center p-1.5'>
                    <span className='sr-only' />

                    {this.state.info?.company_logo ? (
                      <img
                        className='h-10 w-10 rounded-full max-w-full object-cover'
                        src={
                          this.state.info.company_logo === null
                            ? img
                            : url +
                              '/images/marketplace/company_logo/' +
                              this.state.info.company_logo
                        }
                        alt='Logo'
                      />
                    ) : (
                      <i className='fa fa-user text-3xl px-6' aria-hidden='true'></i>
                    )}
                    <span className='m-auto text-2xl forn-medium px-1'>
                      {this.state.info?.first_name}
                    </span>
                    <ChevronDownIcon
                      className='ml-2 h-7 w-7 text-gray-400'
                      aria-hidden='true'
                    />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items
                      style={{ width: '130px' }}
                      className='absolute right-0 z-10 mt-2  origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                    >
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            className={this.classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-xl font-medium text-gray-700 hover:no-underline'
                            )}
                            to='/myaccount'
                          >
                            {t('account.my_account')}
                          </Link>
                        )}
                      </Menu.Item>

                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            className={this.classNames(
                              active ? 'bg-gray-100 no-underline' : '',
                              'block px-4 py-2 text-xl font-medium text-gray-700 hover:no-underline'
                            )}
                            to='/logout'
                          >
                            {t('account.logout')}
                          </Link>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default withTranslation()(Header);
