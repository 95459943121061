import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import ReactFlagsSelect, { Tt } from 'react-flags-select';
const RegStepOne = ({
  t,
  confirmation,
  first_name,
  last_name,
  handleChange,
  subtype,
  state,
  setStep,
  country_id,
  onSelect,
  countries,
  isStepValid,
}) => {
  return (
    <>
      <div className='reg-step-1 xs:hidden md:block'>{t('account.step_1')}</div>
      <span className='signup_error'>{first_name}</span>
      <div className={`mb-4 ${first_name ? 'borderError' : ''}`}>
        <input
          style={confirmation ? { display: 'none' } : { display: 'block' }}
          onChange={handleChange}
          className='px-6 py-3 text-2xl h-form-control w-full border border-gray-300 rounded-md'
          name='first_name'
          type='text'
          value={state?.first_name}
          placeholder={t('account.first_name')}
        />
      </div>
      <div className='signup_error'>{last_name}</div>
      <div className={`mb-4 ${last_name ? 'borderError' : ''}`}>
        <input
          onChange={handleChange}
          className='px-6 py-3 text-2xl h-form-control w-full border border-gray-300 rounded-md'
          name='last_name'
          type='text'
          value={state?.last_name}
          placeholder={t('account.last_name')}
        />
      </div>

      <h4 className='text-center'>{t('login.are_you')}</h4>
      <div className='mb-4'>
        <div className='signup_error'>{subtype}</div>
        <div className={`mb-4 ${subtype ? 'borderError' : ''}`}></div>
        <select
          onChange={handleChange}
          name='subtype'
          value={state?.subtype}
          className='px-6 py-3 text-2xl h-form-control w-full border border-gray-300 rounded-md'
        >
          <option value='' disabled>
            {' '}
            {t('login.Select')}{' '}
          </option>
          <option value='company'> {t('login.company1')} </option>
          <option value='entrepreneur'> {t('login.entrepreneur')} </option>
          <option value='condominium'> {t('login.condominium')} </option>
          <option value='consumer'>{t('login.consumer')}</option>
        </select>
      </div>
      {/* {state.subtype ? (
        <>
          <div className='form-group'>
            <input
              onChange={getUserName}
              className='form-control'
              style={{
                border: getUser(userNameStatus),
              }}
              type='text'
              autocomplete='off'
              name='username'
              placeholder={
                state.subtype === 'company' || state.subtype === 'individual'
                  ? t('login.company')
                  : t('account.username1')
              }
            />
          </div>
        </>
      ) : (
        ''
      )} */}
      {/* {state.subtype === 'consumer' && userNameStatus === true ? (
        <p style={{ fontSize: '15px', color: 'green' }}>
          {' '}
          {t('login.userNameAvailable')}{' '}
        </p>
      ) : state.subtype === 'consumer' && userNameStatus === false ? (
        <p style={{ fontSize: '15px', color: 'red' }}>
          {' '}
          {t('login.userNameNotAvailable')}{' '}
        </p>
      ) : state.subtype !== 'consumer' && userNameStatus === true ? (
        <p style={{ fontSize: '15px', color: 'green' }}>
          {' '}
          {t('login.CompanyNameAvailable')}{' '}
        </p>
      ) : state.subtype !== 'consumer' && userNameStatus === false ? (
        <p style={{ fontSize: '15px', color: 'red' }}>
          {' '}
          {t('login.CompanyNameNotAvailable')}{' '}
        </p>
      ) : (
        ''
      )} */}
      <div className='signup_error'>{country_id}</div>
      <div className={`mb-4 ${country_id ? 'borderError' : ''}`}>
        <ReactFlagsSelect
          selected={state.country !== undefined ? state.country : ''}
          name='country_id'
          onSelect={onSelect}
          countries={countries?.map((countries) => countries.country_code)}
        />
      </div>
      <button
        className='px-6 py-3 text-2xl h-form-control w-full  rounded-md text-white bg-[#333] hover:bg-[#1a1a1a]'
        onClick={(e) => {
          e.preventDefault();
          setStep((prev) => (isStepValid() ? prev + 1 : 1));
        }}
      >
        {t('account.next')}
      </button>
    </>
  );
};

export default withTranslation()(RegStepOne);
