import { useEffect, useRef, useState, useCallback } from 'react';
import Echo from 'laravel-echo';
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import { url } from '../../../../helper/helper';
import Pusher from 'pusher-js';
import { getData, postDataWithToken } from '../../../../helper/api';
const NEW_CHAT_MESSAGE_EVENT = '.project-message'; // Name of the event

window.Pusher = require('pusher-js');
const useChat = (group_id, name) => {
  const params = useParams();
  const messageInputRef = useRef(null);
  const [messages, setMessages] = useState([]); // Sent and received messages
  const [typing, setTyping] = useState(false);
  const [personTyping, setPersonTyping] = useState('');
  const [groupId, setGroupId] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [id, setId] = useState(group_id);
  const [displayUserModal, setDisplayUserModal] = useState('');
  const [sidebarProjects, setSidebarProjects] = useState([]);
  const [personId, setPersonId] = useState(
    Number(localStorage.getItem('Login_user_id'))
  );
  const socketRef = useRef();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const pusher = new Pusher('7efd8ba5abaf94c7ccc4', {
      cluster: 'ap2',
    });
    const echo = new Echo({
      broadcaster: 'pusher',
      key: '7efd8ba5abaf94c7ccc4',
      authEndpoint: `${url}/api/pusher/auth`,
      cluster: 'ap2',
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          //'Access-Control-Allow-Origin': '*',
        },
      },
      useTLS: true,
    });
    //makes connections
    socketRef.current = pusher.subscribe(
      `private-chat-room-${groupId === null ? group_id : groupId}`
    );

    socketRef.current = pusher.subscribe(
      `private-typing-channel-${groupId === null ? group_id : groupId}`
    );

    // Listens for incoming messages
    echo
      .private(`private-chat-room-${groupId === null ? group_id : groupId}`)
      .listen(NEW_CHAT_MESSAGE_EVENT, (message) => {
        const incomingMessage = {
          ...message.data.message[0],
          ownedByCurrentUser:
            (message.group_id === groupId) === null ? group_id : groupId,
        };

        setMessages((messages) => [...messages, incomingMessage]);
        getSidebarProjects();
      });
    //listens for typing events
    echo
      .private(
        `private-typing-channel-${groupId === null ? group_id : groupId}`
      )
      .listen('typing-event', (message) => {
        setPersonId(message.user_id);
        setPersonTyping(message.user);
        setTyping(message.typing);
      });
    // const handleTyping = () => {
    //   const channel = echo.private(
    //     `private-typing-channel-${groupId === null ? group_id : groupId}`
    //   );

    //   setTimeout(async () => {
    //     channel.whisper('typing-event', {
    //       user: name,
    //       typing: true,
    //       user_id: Number(localStorage.getItem('Login_user_id')),
    //     });
    //     const data = new FormData();
    //     data.set('group_id', groupId === null ? group_id : groupId);
    //     data.set('name', name);
    //     data.set('isTyping', true);
    //     await postDataWithToken(`${url}/api/typing`, data, token).then(
    //       (res) => {
    //         //console.log(res)
    //       }
    //     );
    //   }, 3000);
    // };

    // const handleInput = async () => {
    //   const inputValue = messageInputRef.current.value;
    //   console.log('Input value:', inputValue);
    //   const channel = echo.private(
    //     `private-typing-channel-${groupId === null ? group_id : groupId}`
    //   );
    //   channel.whisper('typing-event', {
    //     user: '',
    //     typing: false,
    //     user_id: Number(localStorage.getItem('Login_user_id')),
    //   });
    //   const data = new FormData();
    //   data.set('group_id', groupId === null ? group_id : groupId);
    //   data.set('name', '');
    //   data.set('isTyping', false);
    //   await postDataWithToken(`${url}/api/typing`, data, token).then((res) => {
    //     //console.log(res)
    //   });
    //   // Perform the action using inputValue
    //   // ...
    // };

    // const messageInput = messageInputRef.current;

    // console.log(messageInput);
    // if (messageInput) {
    //   console.log('one');
    //   messageInput.addEventListener('keydown', handleTyping);
    //   messageInput.addEventListener('input', handleInput); // Attach input event listener
    // }

    // Destroys the socket reference when the connection is closed
    return () => {
      // if (messageInput) {
      //   console.log('two');
      //   messageInput.removeEventListener('keydown', handleTyping);
      //   messageInput.removeEventListener('input', handleInput); // Remove input event listener
      // }
      pusher.unsubscribe(
        `private-chat-room-${groupId === null ? group_id : groupId}`
      );
    };
  }, [groupId, group_id, name]);

  // Sends a message to the server that forwards it to all users in the same room
  const sendMessages = async (messageBody, id, name, file) => {
    socketRef.current.bind(NEW_CHAT_MESSAGE_EVENT, {
      message_text: messageBody,
      id: id,
      name: name,
      attachment: file,
      group_id: groupId === null ? group_id : groupId,
    });
    setTyping(false);
    setPersonTyping('');

    const data = new FormData();
    data.set('message_text', messageBody);
    data.set('attachment', file !== undefined ? file[0] : '');
    data.set('group_id', groupId === null ? group_id : groupId);
    data.set('name', name);
    data.set('id', id);
    await postDataWithToken(
      `${url}/api/project/save_message`,
      data,
      token
    ).then((res) => {
      //console.log(res);
    });
  };
  // Sends a file to the server that forwards it to all users in the same room
  const uploadFile = async (file, id, name) => {
    socketRef.current.bind(NEW_CHAT_MESSAGE_EVENT, {
      attachment: file[0],
      id: id,
      group_id: groupId === null ? group_id : groupId,
      name: name,
    });
    setTyping(false);
    setPersonTyping('');
    const data = new FormData();
    data.set('attachment', file[0]);
    data.set('group_id', groupId === null ? group_id : groupId);
    data.set('name', name);
    data.set('id', id);
    await postDataWithToken(
      `${url}/api/project/save_message`,
      data,
      token
    ).then((res) => {
      //console.log(res);
    });
  };
  //Sends typing event
  //const handleTyping = async (name) => {

  // if (!typing) {
  //   setTyping(true);
  //   setPersonTyping(name);
  //   socketRef.current.bind('.typing-event', {
  //     name: name,
  //   });
  //   const data = new FormData();
  //   data.set('group_id', groupId === null ? group_id : groupId);
  //   data.set('name', name);
  //   data.set('isTyping', true);

  //   await postDataWithToken(`${url}/api/typing`, data, token).then((res) => {
  //     //console.log(res);
  //   });
  //   setTimeout(() => {
  //     setTyping(false);
  //     setPersonTyping(name);
  //   }, 2000);
  //   console.log(typing);
  // }

  //};

  //Sends typing event with no user
  const onKeyUp = async () => {
    // if (typing) {
    //   setTyping(false);
    //   setPersonTyping('');
    //   socketRef.current.bind('.typing-event', {
    //     name: '',
    //   });
    //   const data = new FormData();
    //   data.set('group_id', groupId === null ? group_id : groupId);
    //   data.set('name', '');
    //   data.set('isTyping', false);
    //   await postDataWithToken(`${url}/api/typing`, data, token).then((res) => {
    //     //console.log(res);
    //   });
    //   setTimeout(() => {
    //     setTyping(true);
    //   }, 2000); // Clear typing after 2 seconds of inactivity
    // }
  };
  //Gets all messages
  const fetchData = async (id) => {
    await getData(
      `${url}/api/project/get_project_message_new/${id}`,
      token
    ).then((messages) => {
      setMessages(messages?.data);
      setDisplayUserModal(messages?.data[0]?.group?.get_project?.pro_user_id);
    });
    getSidebarProjects();
  };
  //Gets sidebar projects
  const getSidebarProjects = async () => {
    const token = localStorage.getItem('token');
    const data = new FormData();
    data.set('project_id', params?.id);
    await postDataWithToken(`${url}/api/project/chat`, data, token).then(
      (respone) => {
        const { data } = respone;

        setSidebarProjects(data);
      }
    );
  };

  useEffect(() => {
    fetchData(group_id);
  }, [group_id]);

  useEffect(() => {
    getSidebarProjects();
  }, []);

  return {
    messages,
    sendMessages,
    uploadFile,
    //handleTyping,
    onKeyUp,
    setPersonTyping,
    personTyping,
    typing,
    setTyping,
    personId,
    fetchData,
    groupId,
    id,
    sidebarProjects,
    setId,
    setGroupId,
    setProjectId,
    projectId,
    setTyping,
    displayUserModal,
    messageInputRef,
    setDisplayUserModal,
  };
};
export default useChat;
