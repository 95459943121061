import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import i18n from '../../locales/i18n';
import { Translation } from 'react-i18next';
import $ from 'jquery';
import Accordion from 'react-bootstrap/Accordion';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { url } from '../../helper/helper';
import { withRouter } from 'react-router';
import './header.css';
import { getData } from '../../helper/api';
import reportSVG from '../../../src/Report.svg';
import resourceSVG from '../../../src/Resources.svg';
import templateSVG from '../../../src/Template.svg';
import { Fragment, useState } from 'react';
import Logo from '../../images/Full-Logo-lighter.png';
import FavLogo from '../../images/favicon-32.png';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import Header from './Header';
const Sidebar = ({ children, location, dataFromParent }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loggedIn, setloggedIn] = useState(false);
  const [count, setCount] = useState(0);
  const [unread, setUnread] = useState(0);
  const [info, setInfo] = useState([]);
  const [notif, setNotif] = useState([]);
  const [Login_user_permissions] = useState(
    localStorage.getItem('Login_user_permissions')
      ? localStorage.getItem('Login_user_permissions')
      : []
  );
  const [state, setState] = useState({
    isMarketPlace: true,
    isSubMenuOpen: false,
    isSubMenuOpenMaterial: false,
    isSubMenuOpenWork: false,
    isSubMenuOpenFile: false,
    isSubMenuOpenClient: false,
    isSubMenuOpenOffer: false,
    isSubMenuOpenQuotation: false,
    isSubMenuOpenContract: false,
    isSubMenuOpenInvoice: false,
    isSubMenuOpenResources: false,
    isSubMenuOpenReport: false,
    isSubMenuOpenTask: false,
    isMyBusiness: true,
    isProjectManage: true,
  });
  const {
    isMarketPlace,
    isSubMenuOpen,
    isSubMenuOpenMaterial,
    isSubMenuOpenWork,
    isSubMenuOpenClient,
    isSubMenuOpenOffer,
    isSubMenuOpenQuotation,
    isSubMenuOpenContract,
    isSubMenuOpenInvoice,
    isSubMenuOpenResources,
    isSubMenuOpenReport,
    isSubMenuOpenTask,
    isMyBusiness,
    isProjectManage,
  } = state;
  const history = useHistory();
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  const loadToken = async () => {
    const token = await localStorage.getItem('token');
    await getData(`${url}/api/prousers/token/user`, token)
      .then((result) => {
        this.setState({ pms_token: result.data.token });
        window.location.href = `${url}/pms/sso?token=${this.state.pms_token}`;
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleSubMenuToggle = (value) => {
    if (value === 'isSubMenuOpenMaterial') {
      setState({
        ...state,
        isSubMenuOpen: 'isSubMenuOpenMaterial',
      });
    } else if (value === 'isSubMenuOpenOffer') {
      setState({
        ...state,
        isSubMenuOpen: 'isSubMenuOpenOffer',
      });
    } else if (value === 'isSubMenuOpenQuotation') {
      setState({
        ...state,
        isSubMenuOpen: 'isSubMenuOpenQuotation',
      });
    } else if (value === 'isSubMenuOpenFile') {
      setState({
        ...state,
        isSubMenuOpen: 'isSubMenuOpenFile',
      });
    } else if (value === 'isSubMenuOpenClient') {
      setState({
        ...state,
        isSubMenuOpen: 'isSubMenuOpenClient',
      });
    } else if (value === 'isSubMenuOpenWork') {
      setState({
        ...state,
        isSubMenuOpen: 'isSubMenuOpenWork',
      });
    } else if (value === 'isSubMenuOpenContract') {
      setState({
        ...state,
        isSubMenuOpen: 'isSubMenuOpenContract',
      });
    } else if (value === 'isSubMenuOpenInvoice') {
      setState({
        ...state,
        isSubMenuOpen: 'isSubMenuOpenInvoice',
      });
    } else if (value === 'isSubMenuOpenResources') {
      setState({
        ...state,
        isSubMenuOpen: 'isSubMenuOpenResources',
      });
    } else if (value === 'isSubMenuOpenProjects') {
      setState({
        ...state,
        isSubMenuOpen: 'isSubMenuOpenProjects',
      });
    } else if (value === 'isSubMenuOpenReport') {
      setState({
        ...state,
        isSubMenuOpen: 'isSubMenuOpenReport',
      });
    }
  };

  const filter_marketplace_prodesk =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'marketplace_prodesk'
        )
      : [];
  const filter_marketplace_feeds =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'marketplace_feeds'
        )
      : [];
  const filter_marketplace_materials =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'marketplace_materials'
        )
      : [];
  const filter_marketplace_materials_create =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'marketplace_materials_create'
        )
      : [];
  const filter_marketplace_materials_details =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'marketplace_materials_details'
        )
      : [];
  const filter_marketplace_work =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'marketplace_work'
        )
      : [];
  const filter_marketplace_work_create =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'marketplace_work_create'
        )
      : [];
  const filter_marketplace_work_details =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'marketplace_work_details'
        )
      : [];
  const filter_marketplace_mycontact =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'marketplace_mycontact'
        )
      : [];
  const filter_marketplace_mybids =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'marketplace_mybids'
        )
      : [];
  const filter_marketplace_saved_jobs =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'marketplace_saved_jobs'
        )
      : [];

  const filter_mybusiness_prodesk =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_prodesk'
        )
      : [];
  const filter_My_Business_clients =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'My_Business_clients'
        )
      : [];
  const filter_mybusiness_clients_create =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_clients_create'
        )
      : [];

  const filter_mybusiness_proposal =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_proposal'
        )
      : [];
  const filter_mybusiness_proposal_create =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_proposal_create'
        )
      : [];
  const filter_mybusiness_proposal_template =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_proposal_template'
        )
      : [];
  const filter_mybusiness_agreement =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_agreement'
        )
      : [];
  const filter_mybusiness_agreement_create =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_agreement_create'
        )
      : [];
  const filter_mybusiness_project =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_project'
        )
      : [];
  const filter_mybusiness_invoice =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_invoice'
        )
      : [];
  const filter_mybusiness_invoice_create =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_invoice_create'
        )
      : [];
  const filter_mybusiness_resource =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_resource'
        )
      : [];
  const filter_mybusiness_resource_create =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_resource_create'
        )
      : [];
  const filter_mybusiness_report =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_project_create_report'
        )
      : [];
  const filter_mybusiness_report_create =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_project_archive_project'
        )
      : [];
  const filter_mybusiness_create_project =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_project_create'
        )
      : [];
  const filter_mybusiness_manage_project =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_project_archive_project'
        )
      : [];
  const filter_mybusiness_archive_project =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_project_archive'
        )
      : [];
  const filter_mybusiness_phase =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_phase'
        )
      : [];
  const filter_mybusiness_phase_create =
    Login_user_permissions.length > 0
      ? JSON.parse(Login_user_permissions)?.filter(
          (x) => x === 'mybusiness_phase_create'
        )
      : [];

  return (
    <div className='main-content' style={{ height: 'calc(100vh - 0px)' }}>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-50 lg:hidden'
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-900/80' />
          </Transition.Child>

          <div className='fixed inset-0 flex'>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <Dialog.Panel className='sidebar relative mr-16 flex w-full max-w-sm flex-1'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                    <button
                      type='button'
                      className='-m-2.5 p-2.5'
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className='sr-only'>Close sidebar</span>
                      <XMarkIcon
                        className='h-6 w-6 text-white'
                        aria-hidden='true'
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 py-4'>
                  <div className='flex h-16 shrink-0 items-center mx-auto '>
                    <Link
                      to={{
                        pathname: `/index`,
                      }}
                    >
                      <img
                        src={FavLogo}
                        alt='Proppu logo'
                        className='h:14 w:34 xl:hidden md:block lg:hidden sm:block xs:block '
                      />
                    </Link>
                  </div>
                  {/* <div className='sidebar'> */}
                  <div className='wrapper'>
                    <ul
                      role='list'
                      className='nav flex-column sidebar-list flex flex-1 flex-col'
                    >
                      <li className={'nav-item parent-nav'}>
                        <Translation>
                          {(t) => (
                            <Link className={'nav-link'} to='/Dashboard'>
                              <i className='icon-dashboard'></i>
                              <span className='pt-2'>
                                {t('header.Dashboard')}
                              </span>
                            </Link>
                          )}
                        </Translation>
                      </li>
                      {filter_marketplace_prodesk.length > 0 ? (
                        filter_marketplace_prodesk[0] ===
                        'marketplace_prodesk' ? (
                          <li
                            className={
                              dataFromParent === '/index'
                                ? 'nav-item parent-nav active'
                                : 'nav-item parent-nav'
                            }
                          >
                            <Translation>
                              {(t) => (
                                <div
                                  onClick={() =>
                                    setState({
                                      ...state,
                                      isMarketPlace: !isMarketPlace,
                                    })
                                  }
                                  className='nav-link d-flex justify-content-between'
                                >
                                  {t('header.MarketPlace')}
                                  <Button className='sidebarExpandBtn'>
                                    {!isMarketPlace ? '+' : '-'}
                                  </Button>
                                </div>
                              )}
                            </Translation>
                          </li>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                      {isMarketPlace ? (
                        <>
                          {filter_marketplace_feeds[0] ===
                          'marketplace_feeds' ? (
                            <li
                              className={
                                dataFromParent === '/feeds'
                                  ? 'nav-item active'
                                  : 'nav-item'
                              }
                            >
                              <Translation>
                                {(t) => (
                                  <div
                                    className='nav-link'
                                    onClick={() => history.push('/feeds')}
                                  >
                                    <i className='icon-Feeds'></i>
                                    <Translation>
                                      {(t) => t('sidebar.feeds')}
                                    </Translation>
                                  </div>
                                )}
                              </Translation>
                            </li>
                          ) : (
                            ' '
                          )}
                          {filter_marketplace_materials.length > 0 ? (
                            filter_marketplace_materials[0] ===
                            'marketplace_materials' ? (
                              <li
                                onClick={() =>
                                  handleSubMenuToggle(`isSubMenuOpenMaterial`)
                                }
                                className={
                                  dataFromParent === '/create-material-list'
                                    ? 'nav-item active'
                                    : dataFromParent === '/material-list'
                                    ? 'nav-item active'
                                    : 'nav-item'
                                }
                              >
                                <Translation>
                                  {(t) => (
                                    <div className='nav-link'>
                                      <i className='icon-materials'></i>
                                      {t('index.materials')}
                                    </div>
                                  )}
                                </Translation>
                                <ul
                                  className={
                                    isSubMenuOpen === 'isSubMenuOpenMaterial' ||
                                    location.pathname === '/material-list' ||
                                    location.pathname ===
                                      '/create-material-list'
                                      ? 'show sub-nav'
                                      : 'sub-nav'
                                  }
                                >
                                  {filter_marketplace_materials_create.length >
                                  0 ? (
                                    filter_marketplace_materials_create[0] ===
                                    'marketplace_materials_create' ? (
                                      <li>
                                        <Translation>
                                          {(t) => (
                                            <Link to='/create-material-list'>
                                              {t('sidebar.create_listing1')}
                                            </Link>
                                          )}
                                        </Translation>
                                      </li>
                                    ) : (
                                      ''
                                    )
                                  ) : (
                                    ''
                                  )}
                                  {filter_marketplace_materials_details.length >
                                  0 ? (
                                    filter_marketplace_materials_details[0] ===
                                    'marketplace_materials_details' ? (
                                      <li>
                                        <Translation>
                                          {(t) => (
                                            <Link to='/material-list'>
                                              <Translation>
                                                {(t) =>
                                                  t('sidebar.see_listing1')
                                                }
                                              </Translation>
                                            </Link>
                                          )}
                                        </Translation>
                                      </li>
                                    ) : (
                                      ''
                                    )
                                  ) : (
                                    ''
                                  )}
                                </ul>
                              </li>
                            ) : (
                              ' '
                            )
                          ) : (
                            ''
                          )}
                          {filter_marketplace_work.length > 0 ? (
                            filter_marketplace_work[0] ===
                            'marketplace_work' ? (
                              <li
                                onClick={() =>
                                  handleSubMenuToggle(`isSubMenuOpenWork`)
                                }
                                className={
                                  dataFromParent === '/create-work-list'
                                    ? 'nav-item active'
                                    : dataFromParent === '/work-list'
                                    ? 'nav-item active'
                                    : 'nav-item'
                                }
                              >
                                <Translation>
                                  {(t) => (
                                    <a className='nav-link'>
                                      <i className='icon-work'></i>
                                      <Translation>
                                        {(t) => t('index.work')}
                                      </Translation>
                                    </a>
                                  )}
                                </Translation>

                                <ul
                                  className={
                                    isSubMenuOpen === 'isSubMenuOpenWork' ||
                                    location.pathname === '/work-list' ||
                                    location.pathname === '/create-work-list'
                                      ? 'show sub-nav'
                                      : 'sub-nav'
                                  }
                                >
                                  {filter_marketplace_work_create.length > 0 ? (
                                    filter_marketplace_work_create[0] ===
                                    'marketplace_work_create' ? (
                                      <li>
                                        <Translation>
                                          {(t) => (
                                            <Link to='/create-work-list'>
                                              <Translation>
                                                {(t) =>
                                                  t('sidebar.create_listing1')
                                                }
                                              </Translation>
                                            </Link>
                                          )}
                                        </Translation>
                                      </li>
                                    ) : (
                                      ''
                                    )
                                  ) : (
                                    ''
                                  )}
                                  {filter_marketplace_work_details.length >
                                  0 ? (
                                    filter_marketplace_work_details[0] ===
                                    'marketplace_work_details' ? (
                                      <li>
                                        <Translation>
                                          {(t) => (
                                            <Link to='/work-list'>
                                              <Translation>
                                                {(t) =>
                                                  t('sidebar.see_listing')
                                                }
                                              </Translation>
                                            </Link>
                                          )}
                                        </Translation>
                                      </li>
                                    ) : (
                                      ''
                                    )
                                  ) : (
                                    ''
                                  )}
                                </ul>
                              </li>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                          {filter_marketplace_mybids.length > 0 ? (
                            filter_marketplace_mybids[0] ===
                            'marketplace_mybids' ? (
                              <li
                                className={
                                  dataFromParent === '/my-actions/listings'
                                    ? 'nav-item active'
                                    : 'nav-item'
                                }
                              >
                                <Translation>
                                  {(t) => (
                                    <Link
                                      className='nav-link'
                                      to='/my-actions/listings'
                                    >
                                      <i className='icon-My-bid'></i>
                                      <Translation>
                                        {(t) => t('mybid.myBid')}
                                      </Translation>
                                    </Link>
                                  )}
                                </Translation>
                              </li>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                          {filter_marketplace_mycontact.length > 0 ? (
                            filter_marketplace_mycontact[0] ===
                            'marketplace_mycontact' ? (
                              <li
                                style={{ display: 'none' }}
                                className={
                                  dataFromParent === '/my-contracts'
                                    ? 'nav-item active'
                                    : 'nav-item'
                                }
                              >
                                <Translation>
                                  {(t) => (
                                    <Link
                                      className='nav-link'
                                      to='/my-contracts'
                                    >
                                      <i className='icon-mycontract'></i>
                                      <Translation>
                                        {(t) => t('index.contract')}
                                      </Translation>
                                    </Link>
                                  )}
                                </Translation>
                              </li>
                            ) : (
                              ''
                            )
                          ) : (
                            ' '
                          )}
                          {filter_marketplace_saved_jobs.length > 0 ? (
                            filter_marketplace_saved_jobs[0] ===
                            'marketplace_saved_jobs' ? (
                              <li
                                className={
                                  dataFromParent === '/saved'
                                    ? 'nav-item active'
                                    : 'nav-item'
                                }
                              >
                                <Translation>
                                  {(t) => (
                                    <Link className='nav-link' to='/saved'>
                                      <i className='icon-Favourite'></i>
                                      <Translation>
                                        {(t) => t('index.jobs')}
                                      </Translation>
                                    </Link>
                                  )}
                                </Translation>
                              </li>
                            ) : (
                              ''
                            )
                          ) : (
                            ' '
                          )}
                        </>
                      ) : null}

                      {filter_mybusiness_prodesk[0] === 'mybusiness_prodesk' ? (
                        <li
                          className={
                            dataFromParent === '/business-dashboard'
                              ? 'nav-item parent-nav active'
                              : 'nav-item parent-nav'
                          }
                        >
                          <Translation>
                            {(t) => (
                              <div
                                onClick={() =>
                                  setState({
                                    ...state,
                                    isMyBusiness: !isMyBusiness,
                                  })
                                }
                                className='nav-link d-flex justify-content-between'
                              >
                                {t('header.MyBusiness')}
                                <Button className='sidebarExpandBtn'>
                                  {!isMyBusiness ? '+' : '-'}
                                </Button>
                              </div>
                            )}
                          </Translation>
                        </li>
                      ) : (
                        ''
                      )}

                      {isMyBusiness ? (
                        <>
                          {filter_My_Business_clients[0] ===
                            'My_Business_clients' &&
                          filter_mybusiness_clients_create[0] ===
                            'mybusiness_clients_create' ? (
                            <li
                              onClick={() =>
                                handleSubMenuToggle(`isSubMenuOpenFile`)
                              }
                              className={
                                dataFromParent === '/mycustomers'
                                  ? 'nav-item active'
                                  : dataFromParent === '/customers-list'
                                  ? 'nav-item active'
                                  : 'nav-item'
                              }
                            >
                              <p className='nav-link'>
                                <i className='icon-client'></i>
                                <Translation>
                                  {(t) => t('b_sidebar.cus.files')}
                                </Translation>
                              </p>
                              <ul
                                className={
                                  isSubMenuOpen === 'isSubMenuOpenFile' ||
                                  location.pathname === '/drag-and-drop' ||
                                  location.pathname === '/files-list'
                                    ? 'show sub-nav'
                                    : 'sub-nav'
                                }
                              >
                                <li>
                                  <Link to='/drag-and-drop'>
                                    <Translation>
                                      {(t) => t('b_sidebar.cus.customers1')}
                                    </Translation>
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          ) : (
                            ''
                          )}

                          {filter_My_Business_clients[0] ===
                            'My_Business_clients' &&
                          filter_mybusiness_clients_create[0] ===
                            'mybusiness_clients_create' ? (
                            <li
                              onClick={() =>
                                handleSubMenuToggle(`isSubMenuOpenClient`)
                              }
                              className={
                                dataFromParent === '/mycustomers'
                                  ? 'nav-item active'
                                  : dataFromParent === '/customers-list'
                                  ? 'nav-item active'
                                  : 'nav-item'
                              }
                            >
                              <p className='nav-link'>
                                <i className='icon-client'></i>
                                <Translation>
                                  {(t) => t('b_sidebar.cus.customers_client')}
                                </Translation>
                              </p>
                              <ul
                                className={
                                  isSubMenuOpen === 'isSubMenuOpenClient' ||
                                  location.pathname === '/customers-list' ||
                                  location.pathname === '/mycustomers'
                                    ? 'show sub-nav'
                                    : 'sub-nav'
                                }
                              >
                                <li>
                                  <Link to='/mycustomers'>
                                    <Translation>
                                      {(t) => t('b_sidebar.cus.customers1')}
                                    </Translation>
                                  </Link>
                                </li>

                                {filter_mybusiness_clients_create[0] ===
                                'mybusiness_clients_create' ? (
                                  <li>
                                    <Link to='/customers-list'>
                                      <Translation>
                                        {(t) =>
                                          t('b_sidebar.cus.Customer_register')
                                        }
                                      </Translation>
                                    </Link>
                                  </li>
                                ) : (
                                  ''
                                )}
                              </ul>
                            </li>
                          ) : (
                            ''
                          )}

                          {filter_mybusiness_proposal[0] ===
                          'mybusiness_proposal' ? (
                            <li
                              onClick={() =>
                                handleSubMenuToggle(`isSubMenuOpenOffer`)
                              }
                              className={
                                dataFromParent === '/proposal-projectplanning'
                                  ? 'nav-item active'
                                  : dataFromParent === '/myproposal' ||
                                    dataFromParent ===
                                      '/business-proposal-create' ||
                                    dataFromParent === '/proposal-listing'
                                  ? 'nav-item active'
                                  : 'nav-item'
                              }
                            >
                              <a className='nav-link'>
                                <i className='icon-proposal'></i>
                                <Translation>
                                  {(t) => t('b_sidebar.proposal.proposal')}
                                </Translation>
                              </a>

                              <ul
                                className={
                                  isSubMenuOpen === 'isSubMenuOpenOffer' ||
                                  location.pathname === '/proposal-listing' ||
                                  location.pathname === '/myproposal' ||
                                  location.pathname ===
                                    '/proposal-projectplanning'
                                    ? 'show sub-nav'
                                    : 'sub-nav'
                                }
                              >
                                {filter_mybusiness_proposal_create[0] ===
                                'mybusiness_proposal_create' ? (
                                  <li>
                                    <Link to='/myproposal'>
                                      <Translation>
                                        {(t) =>
                                          t(
                                            'b_sidebar.proposal.create_propsoal'
                                          )
                                        }
                                      </Translation>
                                    </Link>
                                  </li>
                                ) : (
                                  ''
                                )}

                                <li>
                                  <Link to='/proposal-listing'>
                                    <Translation>
                                      {(t) =>
                                        t('b_sidebar.proposal.create_manage')
                                      }
                                    </Translation>
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          ) : (
                            ''
                          )}
                          <li
                            onClick={() =>
                              handleSubMenuToggle(`isSubMenuOpenQuotation`)
                            }
                            className={
                              dataFromParent === '/proposal-projectplanning-new'
                                ? 'nav-item active'
                                : dataFromParent ===
                                  '/proposal-projectplanning-listing'
                                ? 'nav-item active'
                                : 'nav-item'
                            }
                          >
                            <a className='nav-link'>
                              <i className='icon-template'></i>

                              <Translation>
                                {(t) => t('b_sidebar.proposal.template')}
                              </Translation>
                            </a>
                            <ul
                              className={
                                isSubMenuOpen === 'isSubMenuOpenQuotation' ||
                                location.pathname ===
                                  '/proposal-projectplanning-listing' ||
                                location.pathname ===
                                  '/proposal-projectplanning-new'
                                  ? 'show sub-nav'
                                  : 'sub-nav'
                              }
                            >
                              <li>
                                <Link to='/proposal-projectplanning-new'>
                                  <Translation>
                                    {(t) =>
                                      t('b_sidebar.proposal.create_propsoal')
                                    }
                                  </Translation>
                                </Link>
                              </li>

                              <li>
                                <Link to='/proposal-projectplanning-listing'>
                                  <Translation>
                                    {(t) =>
                                      t('b_sidebar.proposal.create_manage')
                                    }
                                  </Translation>
                                </Link>
                              </li>
                            </ul>
                          </li>
                          {filter_mybusiness_agreement[0] ===
                          'mybusiness_agreement' ? (
                            <li
                              onClick={() =>
                                handleSubMenuToggle(`isSubMenuOpenContract`)
                              }
                              className={
                                dataFromParent === '/myagreement'
                                  ? 'nav-item active'
                                  : dataFromParent === '/myagreement' ||
                                    dataFromParent ===
                                      '/business-agreement-create' ||
                                    dataFromParent === '/agreement-listing'
                                  ? 'nav-item active'
                                  : 'nav-item'
                              }
                            >
                              <a className='nav-link'>
                                <i className='icon-agreeement'></i>
                                <Translation>
                                  {(t) => t('b_sidebar.agreement.agreement')}
                                </Translation>
                              </a>
                              <ul
                                className={
                                  isSubMenuOpen === 'isSubMenuOpenContract' ||
                                  location.pathname === '/agreement-listing' ||
                                  location.pathname === '/myagreement'
                                    ? 'show sub-nav'
                                    : 'sub-nav'
                                }
                              >
                                {filter_mybusiness_agreement_create[0] ===
                                'mybusiness_agreement_create' ? (
                                  <li>
                                    <Link to='/myagreement'>
                                      <Translation>
                                        {(t) =>
                                          t('b_sidebar.cus.create_customers_a')
                                        }
                                      </Translation>
                                    </Link>
                                  </li>
                                ) : (
                                  ''
                                )}

                                <li>
                                  <Link to='/agreement-listing'>
                                    <Translation>
                                      {(t) => t('b_sidebar.cus.agrement_s')}
                                    </Translation>
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          ) : (
                            ''
                          )}

                          {filter_mybusiness_invoice[0] ===
                            'mybusiness_invoice' &&
                          filter_mybusiness_invoice_create[0] ===
                            'mybusiness_invoice_create' ? (
                            <li
                              onClick={() =>
                                handleSubMenuToggle('isSubMenuOpenInvoice')
                              }
                              className={
                                dataFromParent === '/invoice'
                                  ? 'nav-item active'
                                  : dataFromParent === '/invoice-tabs' ||
                                    dataFromParent === '/invoice' ||
                                    dataFromParent === '/invoice-list'
                                  ? 'nav-item active'
                                  : 'nav-item'
                              }
                            >
                              <a className='nav-link'>
                                <i className='icon-invoice'></i>
                                <Translation>
                                  {(t) => t('b_sidebar.invoice.invoice')}
                                </Translation>
                              </a>

                              <ul
                                className={
                                  isSubMenuOpen === 'isSubMenuOpenInvoice' ||
                                  location.pathname === '/invoice-list' ||
                                  location.pathname === '/invoice-tabs'
                                    ? 'show sub-nav'
                                    : 'sub-nav'
                                }
                              >
                                {filter_mybusiness_invoice_create[0] ===
                                'mybusiness_invoice_create' ? (
                                  <li>
                                    <Link to='/invoice-tabs'>
                                      <Translation>
                                        {(t) =>
                                          t('b_sidebar.cus.create_customers_a')
                                        }
                                      </Translation>
                                    </Link>
                                  </li>
                                ) : (
                                  ''
                                )}
                                <li>
                                  <Link to='/invoice-list'>
                                    <Translation>
                                      {(t) => t('b_sidebar.cus.Manage2')}
                                    </Translation>
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          ) : (
                            ''
                          )}
                          {filter_mybusiness_report[0] ===
                          'mybusiness_project_create_report' ? (
                            <li
                              onClick={() =>
                                handleSubMenuToggle('isSubMenuOpenReport')
                              }
                              className={
                                dataFromParent === '/create-report'
                                  ? 'nav-item active'
                                  : dataFromParent === '/create-report' ||
                                    dataFromParent ===
                                      '/create-report/report-details' ||
                                    dataFromParent === '/create-report'
                                  ? 'nav-item active'
                                  : 'nav-item'
                              }
                            >
                              <a className='nav-link'>
                                <i className='icon-report'></i>
                                <Translation>
                                  {(t) => t('sidebar.report')}
                                </Translation>
                              </a>
                              <ul
                                className={
                                  isSubMenuOpen === 'isSubMenuOpenReport' ||
                                  location.pathname ===
                                    '/create-report/report-details' ||
                                  location.pathname === '/create-report'
                                    ? 'show sub-nav'
                                    : 'sub-nav'
                                }
                              >
                                {filter_mybusiness_report_create[0] ===
                                'mybusiness_project_archive_project' ? (
                                  <li>
                                    <Link to='/create-report/report-details'>
                                      <Translation>
                                        {(t) =>
                                          t('b_sidebar.cus.create_customers_a')
                                        }
                                      </Translation>
                                    </Link>
                                  </li>
                                ) : (
                                  ''
                                )}
                                <li>
                                  <Link to='/report-listing'>
                                    <Translation>
                                      {(t) => t('sidebar.manage')}
                                    </Translation>
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          ) : (
                            ''
                          )}
                        </>
                      ) : null}

                      {filter_mybusiness_prodesk[0] === 'mybusiness_prodesk' ? (
                        <li
                          className={
                            dataFromParent === '/business-dashboard'
                              ? 'nav-item parent-nav active'
                              : 'nav-item parent-nav'
                          }
                        >
                          <Translation>
                            {(t) => (
                              <div
                                onClick={() =>
                                  setState({
                                    ...state,
                                    isProjectManage: !isProjectManage,
                                  })
                                }
                                className='nav-link d-flex justify-content-between'
                              >
                                {t('header.ProjectManagement')}
                                <Button className='sidebarExpandBtn'>
                                  {!isProjectManage ? '+' : '-'}
                                </Button>
                              </div>
                            )}
                          </Translation>
                        </li>
                      ) : (
                        ''
                      )}
                      {isProjectManage ? (
                        <>
                          {filter_mybusiness_project[0] ===
                          'mybusiness_project' ? (
                            <li
                              onClick={() =>
                                handleSubMenuToggle(`isSubMenuOpenProjects`)
                              }
                              className={
                                dataFromParent === '/create-project'
                                  ? 'nav-item active'
                                  : dataFromParent === '/manage-projects'
                                  ? 'nav-item active'
                                  : dataFromParent === '/archive-projects'
                                  ? 'nav-item active'
                                  : 'nav-item'
                              }
                            >
                              <p className='nav-link'>
                                <i className='icon-Project'></i>
                                <Translation>
                                  {(t) => t('b_sidebar.project.project')}
                                </Translation>
                              </p>
                              <ul
                                className={
                                  isSubMenuOpen === 'isSubMenuOpenProjects' ||
                                  location.pathname === '/create-project' ||
                                  location.pathname === '/manage-projects' ||
                                  location.pathname === '/archive-projects'
                                    ? 'show sub-nav'
                                    : 'sub-nav'
                                }
                              >
                                <li>
                                  <Link to='/create-project'>
                                    <Translation>
                                      {(t) =>
                                        t('b_sidebar.project.project_create')
                                      }
                                    </Translation>
                                  </Link>
                                </li>
                                <li>
                                  <Link to='/manage-projects'>
                                    <Translation>
                                      {(t) =>
                                        t('b_sidebar.project.project_manage')
                                      }
                                    </Translation>
                                  </Link>
                                </li>
                                <li>
                                  <Link to='/archive-projects'>
                                    <Translation>
                                      {(t) =>
                                        t('b_sidebar.project.project_archive')
                                      }
                                    </Translation>
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          ) : (
                            ''
                          )}
                          {filter_mybusiness_resource[0] ===
                          'mybusiness_resource' ? (
                            <li
                              onClick={() =>
                                handleSubMenuToggle(`isSubMenuOpenResources`)
                              }
                              className={
                                dataFromParent === '/myresources'
                                  ? 'nav-item active'
                                  : dataFromParent === '/resource-list'
                                  ? 'nav-item active'
                                  : 'nav-item'
                              }
                            >
                              <div className='nav-link'>
                              <i className='icon-My-bid'></i>
                      <Translation>
                              {(t) => t('b_sidebar.resources.resources')}
                         
                        </Translation>
                        </div>

                              <ul
                                className={
                                  isSubMenuOpen === 'isSubMenuOpenResources' ||
                                  location.pathname === '/resource-list' ||
                                  location.pathname === '/myresources' ||
                                  location.pathname === '/permission'
                                    ? 'show sub-nav'
                                    : 'sub-nav'
                                }
                              >
                                {filter_mybusiness_resource_create[0] ===
                                'mybusiness_resource_create' ? (
                                  <li>
                                    <Link to='/myresources'>
                                      <Translation>
                                        {(t) =>
                                          t('b_sidebar.cus.create_customers_a')
                                        }
                                      </Translation>
                                    </Link>
                                  </li>
                                ) : (
                                  ''
                                )}
                                <li>
                                  <Link to='/resource-list'>
                                    <Translation>
                                      {(t) => t('b_sidebar.cus.Manage3')}
                                    </Translation>
                                  </Link>
                                </li>
                                <li>
                                  <Link to='/permission'>
                                    <Translation>
                                      {(t) => t('account.permission')}
                                    </Translation>
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          ) : (
                            ''
                          )}
                        </>
                      ) : null}
                    </ul>
                  </div>
                </div>
                {/* </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className='sidebar hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col'>
        <div className='flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white   py-4'>
          <div className='flex h-16 shrink-0 items-center px-6'>
            <Link
              to={{
                pathname: `/index`,
              }}
            >
              <img
                className='h-14 w-34 xl:block md:hidden lg:block sm:hidden xs:hidden'
                src={Logo}
                alt='proppu logo'
              />
            </Link>
          </div>
          {/* <div className='sidebar'> */}
          <div className='wrapper'>
            <ul role='list' className='nav flex-column sidebar-list'>
              <li className={'nav-item parent-nav'}>
                <Translation>
                  {(t) => (
                    <Link className={'nav-link'} to='/Dashboard'>
                      <i className='icon-dashboard'></i>
                      <span className='pt-2'>{t('header.Dashboard')}</span>
                    </Link>
                  )}
                </Translation>
              </li>
              {filter_marketplace_prodesk.length > 0 ? (
                filter_marketplace_prodesk[0] === 'marketplace_prodesk' ? (
                  <li
                    className={
                      dataFromParent === '/index'
                        ? 'nav-item parent-nav active'
                        : 'nav-item parent-nav'
                    }
                  >
                    <Translation>
                      {(t) => (
                        <div
                          onClick={() =>
                            setState({
                              ...state,
                              isMarketPlace: !isMarketPlace,
                            })
                          }
                          className='nav-link d-flex justify-content-between'
                        >
                          {t('header.MarketPlace')}
                          <Button className='sidebarExpandBtn'>
                            {!isMarketPlace ? '+' : '-'}
                          </Button>
                        </div>
                      )}
                    </Translation>
                  </li>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {isMarketPlace ? (
                <>
                  {filter_marketplace_feeds[0] === 'marketplace_feeds' ? (
                    <li
                      className={
                        dataFromParent === '/feeds'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Translation>
                        {(t) => (
                          <div
                            className='nav-link'
                            onClick={() => history.push('/feeds')}
                          >
                            <i className='icon-Feeds'></i>
                            <Translation>
                              {(t) => t('sidebar.feeds')}
                            </Translation>
                          </div>
                        )}
                      </Translation>
                    </li>
                  ) : (
                    ' '
                  )}
                  {filter_marketplace_materials.length > 0 ? (
                    filter_marketplace_materials[0] ===
                    'marketplace_materials' ? (
                      <li
                        onClick={() =>
                          handleSubMenuToggle(`isSubMenuOpenMaterial`)
                        }
                        className={
                          dataFromParent === '/create-material-list'
                            ? 'nav-item active'
                            : dataFromParent === '/material-list'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Translation>
                          {(t) => (
                            <div className='nav-link'>
                              <i className='icon-materials'></i>
                              {t('index.materials')}
                            </div>
                          )}
                        </Translation>
                        <ul
                          className={
                            isSubMenuOpen === 'isSubMenuOpenMaterial' ||
                            location.pathname === '/material-list' ||
                            location.pathname === '/create-material-list'
                              ? 'show sub-nav'
                              : 'sub-nav'
                          }
                        >
                          {filter_marketplace_materials_create.length > 0 ? (
                            filter_marketplace_materials_create[0] ===
                            'marketplace_materials_create' ? (
                              <li>
                                <Translation>
                                  {(t) => (
                                    <Link to='/create-material-list'>
                                      {t('sidebar.create_listing1')}
                                    </Link>
                                  )}
                                </Translation>
                              </li>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                          {filter_marketplace_materials_details.length > 0 ? (
                            filter_marketplace_materials_details[0] ===
                            'marketplace_materials_details' ? (
                              <li>
                                <Translation>
                                  {(t) => (
                                    <Link to='/material-list'>
                                      <Translation>
                                        {(t) => t('sidebar.see_listing1')}
                                      </Translation>
                                    </Link>
                                  )}
                                </Translation>
                              </li>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                        </ul>
                      </li>
                    ) : (
                      ' '
                    )
                  ) : (
                    ''
                  )}
                  {filter_marketplace_work.length > 0 ? (
                    filter_marketplace_work[0] === 'marketplace_work' ? (
                      <li
                        onClick={() => handleSubMenuToggle(`isSubMenuOpenWork`)}
                        className={
                          dataFromParent === '/create-work-list'
                            ? 'nav-item active'
                            : dataFromParent === '/work-list'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Translation>
                          {(t) => (
                            <a className='nav-link'>
                              <i className='icon-work'></i>
                              <Translation>
                                {(t) => t('index.work')}
                              </Translation>
                            </a>
                          )}
                        </Translation>

                        <ul
                          className={
                            isSubMenuOpen === 'isSubMenuOpenWork' ||
                            location.pathname === '/work-list' ||
                            location.pathname === '/create-work-list'
                              ? 'show sub-nav'
                              : 'sub-nav'
                          }
                        >
                          {filter_marketplace_work_create.length > 0 ? (
                            filter_marketplace_work_create[0] ===
                            'marketplace_work_create' ? (
                              <li>
                                <Translation>
                                  {(t) => (
                                    <Link to='/create-work-list'>
                                      <Translation>
                                        {(t) => t('sidebar.create_listing1')}
                                      </Translation>
                                    </Link>
                                  )}
                                </Translation>
                              </li>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                          {filter_marketplace_work_details.length > 0 ? (
                            filter_marketplace_work_details[0] ===
                            'marketplace_work_details' ? (
                              <li>
                                <Translation>
                                  {(t) => (
                                    <Link to='/work-list'>
                                      <Translation>
                                        {(t) => t('sidebar.see_listing')}
                                      </Translation>
                                    </Link>
                                  )}
                                </Translation>
                              </li>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                        </ul>
                      </li>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                  {filter_marketplace_mybids.length > 0 ? (
                    filter_marketplace_mybids[0] === 'marketplace_mybids' ? (
                      <li
                        className={
                          dataFromParent === '/my-actions/listings'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Translation>
                          {(t) => (
                            <Link
                              className='nav-link'
                              to='/my-actions/listings'
                            >
                              <i className='icon-My-bid'></i>
                              <Translation>
                                {(t) => t('mybid.myBid')}
                              </Translation>
                            </Link>
                          )}
                        </Translation>
                      </li>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                  {filter_marketplace_mycontact.length > 0 ? (
                    filter_marketplace_mycontact[0] ===
                    'marketplace_mycontact' ? (
                      <li
                        style={{ display: 'none' }}
                        className={
                          dataFromParent === '/my-contracts'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Translation>
                          {(t) => (
                            <Link className='nav-link' to='/my-contracts'>
                              <i className='icon-mycontract'></i>
                              <Translation>
                                {(t) => t('index.contract')}
                              </Translation>
                            </Link>
                          )}
                        </Translation>
                      </li>
                    ) : (
                      ''
                    )
                  ) : (
                    ' '
                  )}
                  {filter_marketplace_saved_jobs.length > 0 ? (
                    filter_marketplace_saved_jobs[0] ===
                    'marketplace_saved_jobs' ? (
                      <li
                        className={
                          dataFromParent === '/saved'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Translation>
                          {(t) => (
                            <Link className='nav-link' to='/saved'>
                              <i className='icon-Favourite'></i>
                              <Translation>
                                {(t) => t('index.jobs')}
                              </Translation>
                            </Link>
                          )}
                        </Translation>
                      </li>
                    ) : (
                      ''
                    )
                  ) : (
                    ' '
                  )}
                </>
              ) : null}

              {filter_mybusiness_prodesk[0] === 'mybusiness_prodesk' ? (
                <li
                  className={
                    dataFromParent === '/business-dashboard'
                      ? 'nav-item parent-nav active'
                      : 'nav-item parent-nav'
                  }
                >
                  <Translation>
                    {(t) => (
                      <div
                        onClick={() =>
                          setState({
                            ...state,
                            isMyBusiness: !isMyBusiness,
                          })
                        }
                        className='nav-link d-flex justify-content-between'
                      >
                        {t('header.MyBusiness')}
                        <Button className='sidebarExpandBtn'>
                          {!isMyBusiness ? '+' : '-'}
                        </Button>
                      </div>
                    )}
                  </Translation>
                </li>
              ) : (
                ''
              )}

              {isMyBusiness ? (
                <>
                  {filter_My_Business_clients[0] === 'My_Business_clients' &&
                  filter_mybusiness_clients_create[0] ===
                    'mybusiness_clients_create' ? (
                    <li
                      onClick={() => handleSubMenuToggle(`isSubMenuOpenFile`)}
                      className={
                        dataFromParent === '/drag-and-drop'
                          ? 'nav-item active'
                          : dataFromParent === '/files-list'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <p className='nav-link'>
                        <i className='icon-client'></i>
                        <Translation>
                          {(t) => t('b_sidebar.cus.files')}
                        </Translation>
                      </p>
                      <ul
                        className={
                          isSubMenuOpen === 'isSubMenuOpenFile' ||
                          location.pathname === '/drag-and-drop' ||
                          location.pathname === '/files-list'
                            ? 'show sub-nav'
                            : 'sub-nav'
                        }
                      >
                        <li>
                          <Link to='/drag-and-drop'>
                            <Translation>
                              {(t) => t('b_sidebar.cus.customers1')}
                            </Translation>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    ''
                  )}

                  {filter_My_Business_clients[0] === 'My_Business_clients' &&
                  filter_mybusiness_clients_create[0] ===
                    'mybusiness_clients_create' ? (
                    <li
                      onClick={() => handleSubMenuToggle(`isSubMenuOpenClient`)}
                      className={
                        dataFromParent === '/mycustomers'
                          ? 'nav-item active'
                          : dataFromParent === '/customers-list'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <p className='nav-link'>
                        <i className='icon-client'></i>
                        <Translation>
                          {(t) => t('b_sidebar.cus.customers_client')}
                        </Translation>
                      </p>
                      <ul
                        className={
                          isSubMenuOpen === 'isSubMenuOpenClient' ||
                          location.pathname === '/customers-list' ||
                          location.pathname === '/mycustomers'
                            ? 'show sub-nav'
                            : 'sub-nav'
                        }
                      >
                        <li>
                          <Link to='/mycustomers'>
                            <Translation>
                              {(t) => t('b_sidebar.cus.customers1')}
                            </Translation>
                          </Link>
                        </li>

                        {filter_mybusiness_clients_create[0] ===
                        'mybusiness_clients_create' ? (
                          <li>
                            <Link to='/customers-list'>
                              <Translation>
                                {(t) => t('b_sidebar.cus.Customer_register')}
                              </Translation>
                            </Link>
                          </li>
                        ) : (
                          ''
                        )}
                      </ul>
                    </li>
                  ) : (
                    ''
                  )}

                  {filter_mybusiness_proposal[0] === 'mybusiness_proposal' ? (
                    <li
                      onClick={() => handleSubMenuToggle(`isSubMenuOpenOffer`)}
                      className={
                        dataFromParent === '/proposal-projectplanning'
                          ? 'nav-item active'
                          : dataFromParent === '/myproposal' ||
                            dataFromParent === '/business-proposal-create' ||
                            dataFromParent === '/proposal-listing'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <a className='nav-link'>
                        <i className='icon-proposal'></i>
                        <Translation>
                          {(t) => t('b_sidebar.proposal.proposal')}
                        </Translation>
                      </a>

                      <ul
                        className={
                          isSubMenuOpen === 'isSubMenuOpenOffer' ||
                          location.pathname === '/proposal-listing' ||
                          location.pathname === '/myproposal' ||
                          location.pathname === '/proposal-projectplanning'
                            ? 'show sub-nav'
                            : 'sub-nav'
                        }
                      >
                        {filter_mybusiness_proposal_create[0] ===
                        'mybusiness_proposal_create' ? (
                          <li>
                            <Link to='/myproposal'>
                              <Translation>
                                {(t) => t('b_sidebar.proposal.create_propsoal')}
                              </Translation>
                            </Link>
                          </li>
                        ) : (
                          ''
                        )}

                        <li>
                          <Link to='/proposal-listing'>
                            <Translation>
                              {(t) => t('b_sidebar.proposal.create_manage')}
                            </Translation>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    ''
                  )}
                  <li
                    onClick={() =>
                      handleSubMenuToggle(`isSubMenuOpenQuotation`)
                    }
                    className={
                      dataFromParent === '/proposal-projectplanning-new'
                        ? 'nav-item active'
                        : dataFromParent === '/proposal-projectplanning-listing'
                        ? 'nav-item active'
                        : 'nav-item'
                    }
                  >
                    <a className='nav-link'>
                      <i className='icon-template'></i>
                      <Translation>
                        {(t) => t('b_sidebar.proposal.template')}
                      </Translation>
                    </a>
                    <ul
                      className={
                        isSubMenuOpen === 'isSubMenuOpenQuotation' ||
                        location.pathname ===
                          '/proposal-projectplanning-listing' ||
                        location.pathname === '/proposal-projectplanning-new'
                          ? 'show sub-nav'
                          : 'sub-nav'
                      }
                    >
                      <li>
                        <Link to='/proposal-projectplanning-new'>
                          <Translation>
                            {(t) => t('b_sidebar.proposal.create_propsoal')}
                          </Translation>
                        </Link>
                      </li>

                      <li>
                        <Link to='/proposal-projectplanning-listing'>
                          <Translation>
                            {(t) => t('b_sidebar.proposal.create_manage')}
                          </Translation>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {filter_mybusiness_agreement[0] === 'mybusiness_agreement' ? (
                    <li
                      onClick={() =>
                        handleSubMenuToggle(`isSubMenuOpenContract`)
                      }
                      className={
                        dataFromParent === '/myagreement'
                          ? 'nav-item active'
                          : dataFromParent === '/myagreement' ||
                            dataFromParent === '/business-agreement-create' ||
                            dataFromParent === '/agreement-listing'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <a className='nav-link'>
                        <i className='icon-agreeement'></i>
                        <Translation>
                          {(t) => t('b_sidebar.agreement.agreement')}
                        </Translation>
                      </a>
                      <ul
                        className={
                          isSubMenuOpen === 'isSubMenuOpenContract' ||
                          location.pathname === '/agreement-listing' ||
                          location.pathname === '/myagreement'
                            ? 'show sub-nav'
                            : 'sub-nav'
                        }
                      >
                        {filter_mybusiness_agreement_create[0] ===
                        'mybusiness_agreement_create' ? (
                          <li>
                            <Link to='/myagreement'>
                              <Translation>
                                {(t) => t('b_sidebar.cus.create_customers_a')}
                              </Translation>
                            </Link>
                          </li>
                        ) : (
                          ''
                        )}

                        <li>
                          <Link to='/agreement-listing'>
                            <Translation>
                              {(t) => t('b_sidebar.cus.agrement_s')}
                            </Translation>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    ''
                  )}

                  {filter_mybusiness_invoice[0] === 'mybusiness_invoice' &&
                  filter_mybusiness_invoice_create[0] ===
                    'mybusiness_invoice_create' ? (
                    <li
                      onClick={() =>
                        handleSubMenuToggle('isSubMenuOpenInvoice')
                      }
                      className={
                        dataFromParent === '/invoice'
                          ? 'nav-item active'
                          : dataFromParent === '/invoice-tabs' ||
                            dataFromParent === '/invoice' ||
                            dataFromParent === '/invoice-list'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <a className='nav-link'>
                        <i className='icon-invoice'></i>
                        <Translation>
                          {(t) => t('b_sidebar.invoice.invoice')}
                        </Translation>
                      </a>

                      <ul
                        className={
                          isSubMenuOpen === 'isSubMenuOpenInvoice' ||
                          location.pathname === '/invoice-list' ||
                          location.pathname === '/invoice-tabs'
                            ? 'show sub-nav'
                            : 'sub-nav'
                        }
                      >
                        {filter_mybusiness_invoice_create[0] ===
                        'mybusiness_invoice_create' ? (
                          <li>
                            <Link to='/invoice-tabs'>
                              <Translation>
                                {(t) => t('b_sidebar.cus.create_customers_a')}
                              </Translation>
                            </Link>
                          </li>
                        ) : (
                          ''
                        )}
                        <li>
                          <Link to='/invoice-list'>
                            <Translation>
                              {(t) => t('b_sidebar.cus.Manage2')}
                            </Translation>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    ''
                  )}
                  {filter_mybusiness_report[0] ===
                  'mybusiness_project_create_report' ? (
                    <li
                      onClick={() => handleSubMenuToggle('isSubMenuOpenReport')}
                      className={
                        dataFromParent === '/create-report'
                          ? 'nav-item active'
                          : dataFromParent === '/create-report' ||
                            dataFromParent ===
                              '/create-report/report-details' ||
                            dataFromParent === '/create-report'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <a className='nav-link'>
                        <i className='icon-report'></i>
                        <Translation>{(t) => t('sidebar.report')}</Translation>
                      </a>
                      <ul
                        className={
                          isSubMenuOpen === 'isSubMenuOpenReport' ||
                          location.pathname ===
                            '/create-report/report-details' ||
                          location.pathname === '/create-report'
                            ? 'show sub-nav'
                            : 'sub-nav'
                        }
                      >
                        {filter_mybusiness_report_create[0] ===
                        'mybusiness_project_archive_project' ? (
                          <li>
                            <Link to='/create-report/report-details'>
                              <Translation>
                                {(t) => t('b_sidebar.cus.create_customers_a')}
                              </Translation>
                            </Link>
                          </li>
                        ) : (
                          ''
                        )}
                        <li>
                          <Link to='/report-listing'>
                            <Translation>
                              {(t) => t('sidebar.manage')}
                            </Translation>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    ''
                  )}
                </>
              ) : null}

              {filter_mybusiness_prodesk[0] === 'mybusiness_prodesk' ? (
                <li
                  className={
                    dataFromParent === '/business-dashboard'
                      ? 'nav-item parent-nav active'
                      : 'nav-item parent-nav'
                  }
                >
                  <Translation>
                    {(t) => (
                      <div
                        onClick={() =>
                          setState({
                            ...state,
                            isProjectManage: !isProjectManage,
                          })
                        }
                        className='nav-link d-flex justify-content-between'
                      >
                        {t('header.ProjectManagement')}
                        <Button className='sidebarExpandBtn'>
                          {!isProjectManage ? '+' : '-'}
                        </Button>
                      </div>
                    )}
                  </Translation>
                </li>
              ) : (
                ''
              )}
              {isProjectManage ? (
                <>
                  {filter_mybusiness_project[0] === 'mybusiness_project' ? (
                    <li
                      onClick={() =>
                        handleSubMenuToggle(`isSubMenuOpenProjects`)
                      }
                      className={
                        dataFromParent === '/create-project'
                          ? 'nav-item active'
                          : dataFromParent === '/manage-projects'
                          ? 'nav-item active'
                          : dataFromParent === '/archive-projects'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <p className='nav-link'>
                        <i className='icon-Project'></i>
                        <Translation>
                          {(t) => t('b_sidebar.project.project')}
                        </Translation>
                      </p>
                      <ul
                        className={
                          isSubMenuOpen === 'isSubMenuOpenProjects' ||
                          location.pathname === '/create-project' ||
                          location.pathname === '/manage-projects' ||
                          location.pathname === '/archive-projects'
                            ? 'show sub-nav'
                            : 'sub-nav'
                        }
                      >
                        <li>
                          <Link to='/create-project'>
                            <Translation>
                              {(t) => t('b_sidebar.project.project_create')}
                            </Translation>
                          </Link>
                        </li>
                        <li>
                          <Link to='/manage-projects'>
                            <Translation>
                              {(t) => t('b_sidebar.project.project_manage')}
                            </Translation>
                          </Link>
                        </li>
                        <li>
                          <Link to='/archive-projects'>
                            <Translation>
                              {(t) => t('b_sidebar.project.project_archive')}
                            </Translation>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    ''
                  )}
                  {filter_mybusiness_resource[0] === 'mybusiness_resource' ? (
                    <li
                      onClick={() =>
                        handleSubMenuToggle(`isSubMenuOpenResources`)
                      }
                      className={
                        dataFromParent === '/myresources'
                          ? 'nav-item active'
                          : dataFromParent === '/resource-list'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <div className='nav-link'>
                              <i className='icon-My-bid'></i>
                      <Translation>
                              {(t) => t('b_sidebar.resources.resources')}
                         
                        </Translation>
                        </div>
                         

                      <ul
                        className={
                          isSubMenuOpen === 'isSubMenuOpenResources' ||
                          location.pathname === '/resource-list' ||
                          location.pathname === '/myresources' ||
                          location.pathname === '/permission'
                            ? 'show sub-nav'
                            : 'sub-nav'
                        }
                      >
                        {filter_mybusiness_resource_create[0] ===
                        'mybusiness_resource_create' ? (
                          <li>
                            <Link to='/myresources'>
                              <Translation>
                                {(t) => t('b_sidebar.cus.create_customers_a')}
                              </Translation>
                            </Link>
                          </li>
                        ) : (
                          ''
                        )}
                        <li>
                          <Link to='/resource-list'>
                            <Translation>
                              {(t) => t('b_sidebar.cus.Manage3')}
                            </Translation>
                          </Link>
                        </li>
                        <li>
                          <Link to='/permission'>
                            <Translation>
                              {(t) => t('account.permission')}
                            </Translation>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    ''
                  )}
                </>
              ) : null}
            </ul>
          </div>
          {/* </div> */}
        </div>
      </div>

      <div className='page-content'>
        <Header setSidebarOpen={setSidebarOpen} />
        {/* <div className='sticky top-0 z-40 flex h-20 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8'>
          <button
            type='button'
            className='-m-2.5 p-2.5 text-gray-700 lg:hidden'
            onClick={() => setSidebarOpen(true)}
          >
            <span className='sr-only'>Open sidebar</span>
            <Bars3Icon className='h-6 w-6' aria-hidden='true' />
          </button>

       
          <div className='h-8 w-px bg-gray-200 lg:hidden' aria-hidden='true' />

          <div className='flex flex-1 gap-x-4 self-stretch lg:gap-x-6'>
            <form className='relative flex flex-1' action='#' method='GET'>
              <label htmlFor='search-field' className='sr-only'>
                Search
              </label>
              <MagnifyingGlassIcon
                className='pointer-events-none absolute inset-y-0 left-0 h-full w-7 text-gray-400'
                aria-hidden='true'
              />
              <input
                id='search-field'
                className='block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-xl'
                placeholder='Search...'
                type='search'
                name='search'
              />
            </form>
            <Header />
          </div>
        </div> */}

        <main className='mt-10'>{children}</main>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
