import React, { useEffect, useState } from 'react';
import Button from '../shared/Button';
import Logo from '../../images/Full-Logo-lighter.png';
import axios from 'axios';
import { url } from '../../helper/helper';
import { HashRouter as Router, Link, Redirect } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { withTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';
import ChangeLanguage from '../login/ChangeLanguage';
const Forgot = ({ t }) => {
  const language = localStorage.getItem('_lng');
  const token = localStorage.getItem('token');
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState('');
  const [show_errors, setShow_errors] = useState('');
  const [show_msg, setShow_msg] = useState(false);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('_lng', lng);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!email) {
      setErrors(t('success.all_fields_are_required'));
    }
    axios
      .post(`${url}/api/password/email?`, {
        email: email,
        language: localStorage.getItem('_lng')
          ? localStorage.getItem('_lng')
          : 'fi',
      })
      .then((res) => {
        setShow_msg(true);
      })
      .catch((err) => {
        setShow_errors(true);
        setErrors(t('login.email_not_found'));
      });
  };
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
    if (token) {
      setLoggedIn(true);
    }
  }, [language, token]);

  if (loggedIn === true) {
    return <Redirect to='/index' />;
  }

  let alert;
  if (show_errors === true) {
    alert = (
      <div style={{ paddingTop: '10px' }}>
        <Alert variant='danger' style={{ fontSize: '13px' }}>
          {errors}
        </Alert>
      </div>
    );
  }
  if (show_msg === true) {
    alert = (
      <div style={{ paddingTop: '10px' }}>
        {' '}
        <Alert variant='success' style={{ fontSize: '13px' }}>
          {t('login.Email_sent!')}
        </Alert>
      </div>
    );
  }

  return (
    <div className='login-page'>
      <div className='content'>
        <div className='logo'>
          <img src={Logo} alt='' style={{ width: '196px', height: '45px' }} />
        </div>
        <div className='card'>
          <div className='card-body'>
            <div className='head'>
              <h3>{t('login.for_msg')}</h3>
            </div>
            <form className='formstart' onSubmit={handleSubmit}>
              <div className='form-group'>
                {/* <EmailId /> */}
                <input
                  className='form-control'
                  onChange={handleChange}
                  name='email'
                  type='email'
                  placeholder={t('login.email')}
                />
                <div className='invalid-feedback'>{t('login.valid_email')}</div>
              </div>
              {/* <Button title={t("login.Reset")} /> */}
              <button className='btn btn-dark'> {t('login.Reset')} </button>
              {alert ? alert : null}
            </form>
          </div>
        </div>
        <Link className='back-link' to='/'>
          {t('login.back_msg')}
        </Link>
        <ChangeLanguage changeLanguage={changeLanguage} language={language} />
      </div>
    </div>
  );
};

export default withTranslation()(Forgot);
