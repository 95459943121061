import React, { useState, useEffect } from 'react';
import Header from '../../shared/Header';
import { Link } from 'react-router-dom';
import BussinessSidebar from '../../shared/BussinessSidebar';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { url } from '../../../helper/helper';
import { getData } from '../../../helper/api';

import './permission.css';
import Breadcrumb from '../../shared/Breadcrumb';
import Sidebar from '../../shared/Sidebar';
import { dateFuncExp } from '../../../helper/dateFunc/date';

const Permission = ({ t, location }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    const token = await localStorage.getItem('token');
    const result = await getData(`${url}/api/role_list`, token);

    setData(result.data);
  };

  return (
    <>
      <Sidebar dataFromParent={location.pathname}>
        <Breadcrumb
          pages={[
            {
              name: 'My Business',
              href: '/business-dashboard',
            },
            {
              name: t('Permission'),
              href: '/permission',
            },
          ]}
        />
        <h3 className='permission-title'>
          {t('permission.permission')}
          <Link className='ceva' to='/roles/create'>
            {t('b_sidebar.cus.customers1')}
          </Link>
        </h3>

        <div className='roles-card'>
          <div className='role-created'>
            <p>{t('account.Role')}</p>
            <p className='created_at'>{t('b_sidebar.roles.created_at')}</p>
          </div>
          <div className='roles-table'>
            {data &&
              data?.map((role) => {
                return (
                  <div
                    key={role.id}
                    className='roles-row'
                    style={
                      role.id % 2
                        ? {
                            backgroundColor: 'transparent',
                          }
                        : { backgroundColor: 'rgba(0,0,0,.05)' }
                    }
                  >
                    <Link
                      to={`/permission/${role.id}`}
                      className='permission-link'
                    >
                      {' '}
                      <p>{role.display_name}</p>
                    </Link>
                    <p>
                      {dateFuncExp(
                        role.created_at,
                        localStorage.getItem('_lng')
                      )}
                    </p>
                    {role.default_flag !== '1' ? (
                      <Link
                        className='roles-edit'
                        to='/roles/edit'
                        onClick={() => localStorage.setItem('RoleID', role.id)}
                      >
                        {t('myproposal.Edit')}
                      </Link>
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default withTranslation()(Permission);
