import React from 'react';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { url } from '../helper';
import { useHistory } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';

const HandleLogin = ({ setIsOpenUserModal, setLoggedIn, setGoogleSignin }) => {
  const history = useHistory();
  const handleLogin = async (credentialResponse) => {
    try {
      var obj = jwtDecode(credentialResponse?.credential);
      const userObj = {
        id: obj.sub,
        email: obj.email,
        verified_email: obj.email_verified,
        name: obj.name,
        given_name: obj.given_name,
        family_name: obj.family_name,
        picture: obj.picture,
        locale: obj.locale,
      };

      const config = {
        method: 'POST',
        url: `${url}/api/auth/google/callback`,
        headers: {},
        data: userObj,
      };

      await axios(config).then((res) => {
        localStorage.setItem('token', res?.data?.success.token);
        localStorage.setItem('Login_user_id', res?.data?.success.login_user_id);
        localStorage.setItem('Login_user_role', res?.data?.role[0]);
        localStorage.setItem(
          'Login_user_uuid',
          res?.data?.success.login_user_uuid
        );

        localStorage.setItem(
          'Login_user_permissions',
          JSON.stringify(res?.data?.permissions)
        );

        if (res.data.success.google_signup === 1 && res?.data?.success?.token) {
          setIsOpenUserModal(true);
        }
        if (res.data.success.google_signup === 0 && res?.data?.success?.token) {
          setLoggedIn(true);
          history.push('/Dashboard');
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return <GoogleLogin onSuccess={handleLogin} />;
};
export default HandleLogin;
