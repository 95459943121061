import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Logo from '../../images/Full-Logo-lighter.png';

const Terms_of_use = ({ t }) => {
  const token = localStorage.getItem('token');
  const [loggedIn, setLoggedIn] = useState(false);
  const [state, setState] = useState({
    email: '',
    password: '',
    err: '',
  });
  useEffect(() => {
    if (token) {
      setLoggedIn(true);
    }
  }, [token]);

  return (
    <div className='bg-[#f7f7f6] h-screen overflow-y-auto'>
      <div className='md:p-12 xs:px-6 xs:pt-6 max-w-4xl  my-0 mx-auto'>
        <div className='items-center md:mb-16 xs:mb-8'>
          <img src={Logo} alt={Logo} />
        </div>
        <div
          className='border-none rounded-2xl shadow-login bg-white'
          style={{ marginTop: '-10%' }}
        >
          <div className='p-12'>
            <div className='mb-13 text-2xl text-center'>
              <h3>{t('login.welcome')}</h3>
              <p className='text-center'>{t('login.tag')}</p>
            </div>
          </div>
        </div>
      </div>

      <br />
    </div>
  );
};

export default withTranslation()(Terms_of_use);
