import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Multiselect } from 'multiselect-react-dropdown';

const RegStepThree = ({
  t,
  handleChange,
  confirmation,
  handleChange1,
  company_id,
  company_error,
  subtype,
  condo_name,
  account_type,
  state,
  completed,
  ref,
  loadSkills,
  skills,
  skillsList,
  onSelectSkills,
  onRemoveSkills,
}) => {
  const [address, setAddress] = useState([]);

  const companyAddress = async (id) => {
    if (id) {
      const res = await fetch(`https://avoindata.prh.fi/bis/v1/${id}`);
      const data = await res.json();

      setAddress(data?.results?.map((res) => res?.addresses[0]));
    }
  };

  useEffect(() => {
    if (company_id) {
      companyAddress(company_id);
    }
  }, [company_id]);
  useEffect(() => {
    loadSkills();
  }, []);
  return (
    <>
      <div className='reg-step-1'>{t('account.step_3')}</div>
      {subtype === 'company' || subtype === 'entrepreneur' ? (
        <>
          <span className='signup_error'>{company_error}</span>
          <div className={`mb-4 ${company_error ? 'borderError' : ''}`}>
            <input
              style={confirmation ? { display: 'none' } : { display: 'block' }}
              onChange={handleChange}
              className='px-6 py-3 text-2xl h-form-control w-full border border-gray-300 rounded-md'
              name='company_id'
              type='text'
              value={state?.company_id}
              placeholder={t('account.company_id')}
            />
          </div>
          {address.length > 0 ? (
            <>
              <div className={`mb-4 ${address ? 'borderError' : ''}`}>
                <input
                  style={
                    confirmation ? { display: 'none' } : { display: 'block' }
                  }
                  className='px-6 py-3 text-2xl h-form-control w-full border border-gray-300 rounded-md'
                  name='address'
                  type='text'
                  readOnly
                  value={address.map(
                    ({ street, city, postCode, country }) =>
                      `${street !== null ? street : ''}, ${
                        city !== null ? city : ''
                      } ${postCode !== null ? postCode : ''} ${
                        country !== null ? country : ''
                      }`
                  )}
                  placeholder={t('account.address')}
                />
              </div>
            </>
          ) : (
            ''
          )}
          <span className='signup_error'>{skills}</span>
          <div className={`mb-4 ${skills ? 'borderError' : ''}`}></div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Multiselect
              options={skillsList}
              //selectedValues={savedConstruction}
              displayValue='skills_identifier'
              onRemove={onRemoveSkills}
              onSelect={onSelectSkills}
              //selectedList={savedConstruction}
              placeholder={t('account.areas_interest')}
            />
          </div>
        </>
      ) : (
        ''
      )}
      {subtype === 'condominium' ? (
        <>
          <span className='signup_error'>{company_error}</span>
          <div className={`mb-4 ${company_error ? 'borderError' : ''}`}>
            <input
              style={confirmation ? { display: 'none' } : { display: 'block' }}
              onChange={handleChange}
              className='px-6 py-3 text-2xl h-form-control w-full border border-gray-300 rounded-md'
              name='company_id'
              type='text'
              placeholder={t('account.company_id')}
            />
          </div>
          {address.length > 0 ? (
            <>
              <div className={`mb-4 ${address ? 'borderError' : ''}`}>
                <input
                  style={
                    confirmation ? { display: 'none' } : { display: 'block' }
                  }
                  className='px-6 py-3 text-2xl h-form-control w-full border border-gray-300 rounded-md'
                  name='address'
                  type='text'
                  readOnly
                  value={address.map(
                    ({ street, city, postCode, country }) =>
                      `${street}, ${city} ${postCode}, ${country}`
                  )}
                  placeholder={t('account.address')}
                />
              </div>
            </>
          ) : (
            ''
          )}
          <div className='signup_error'>{condo_name}</div>
          <div className={`mb-4 ${condo_name ? 'borderError' : ''}`}>
            <input
              onChange={handleChange}
              className='px-6 py-3 text-2xl h-form-control w-full border border-gray-300 rounded-md'
              type='text'
              name='condominium_name'
              placeholder={t('account.condo_name')}
            />
          </div>
          <div className='mb-4'>
            <div className='signup_error'>{account_type}</div>
            <div className={`mb-4 ${account_type ? 'borderError' : ''}`}></div>
            <select
              onChange={handleChange}
              name='account_type'
              value={state?.account_type}
              className='px-6 py-3 text-2xl h-form-control w-full border border-gray-300 rounded-md'
            >
              <option value='' disabled>
                {' '}
                {t('login.Select')}{' '}
              </option>

              <option value='property-manager'>
                {' '}
                {t('login.property-manager')}{' '}
              </option>
              <option value='board-member'> {t('login.board-member')} </option>
              <option value='shareholder'>{t('login.shareholder')}</option>
            </select>
          </div>
        </>
      ) : (
        ''
      )}
      <div className='form-group signup-checkbox' style={{ margin: '15px 0' }}>
        <input type='checkbox' checked={completed} onChange={handleChange1} />
        <label className='ml-2 mb-0'>{t('account.tickbox')}</label>
      </div>
    </>
  );
};

export default withTranslation()(RegStepThree);
