import React from 'react';
import FolderCarousel from '../../marketPlace/FolderCarousel/FolderCarousel';
import { Link } from 'react-router-dom';
const Folder = ({ folder, changeState, data, removeFolder }) => {
  console.log(localStorage.getItem('Login_user_id') , folder.user_id);
  return (
    <div
      className='relative overflow-hidden flex flex-1 flex-col p-8'
      onClick={() => changeState(folder.id)}
       
    >
      <div class="absolute inline-flex items-center justify-center w-10 h-10 text-base font-normal text-white bg-red-500 border-2 border-white rounded-full -top-0 -end-0 dark:border-gray-900">
        {folder.image_count}
        </div>
     
    {
  (String(localStorage.getItem('Login_user_id') || '').trim() !== String(folder.user_id || '').trim())
  ? (
    <div class="absolute left-0 top-0 h-16 w-16">
      <div style={{ textTransform: 'capitalize' }} class="absolute transform -rotate-45 bg-custom-proppu-purple text-center text-white font-semibold py-1 left-[-34px] top-[32px] w-[170px]">
      {folder.first_name}
      </div>
    </div>
  ) : (
    <div>
      {/* Content for the else condition */}
    </div>
  )
}
     
      
      {/* ${localStorage.getItem('Login_user_id')}
    ${folder.user_id} */}
      <Link to={`/folder-files/${folder.id}`}>
                            
      {folder?.name}
      {!folder.state ? (
        <svg
          className='col-span-1'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
          aria-hidden='true'
        >
          <path
            vectorEffect='non-scaling-stroke'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
          />
        </svg>
      ) : (
        <div className='flex flex-1 flex-col p-8'>
          <FolderCarousel images={data?.filter((item) => item)} />
        </div>
      )}
      
      </Link>
      {
(
    String(localStorage.getItem('Login_user_id') || '').trim() === 
    String(folder.user_id || '').trim() && 
    typeof folder.user_id !== 'undefined' && 
    folder.image_count === 0 
  )
  ? (
      <div className='-mt-px flex divide-x divide-gray-200'>
        <div
          className='btn btn-danger -ml-px flex w-0 flex-1 text-2xl'
          onClick={() => removeFolder(folder.id)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block">
  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
</svg>
        </div>
        </div>
        ) : (
          <div>
            
          </div>
        )
            }
    </div>
  );
};

const FolderList = ({ folders, changeState, data, removeImage, removeFolder }) => {
  const renderFolder = (folder) => {
    return (
      <li className='col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow mt-6 cursor-pointer'>
        <Folder
          folder={folder}
          changeState={changeState}
          data={data}
          removeImage={removeImage}
          removeFolder={removeFolder}
        />
      </li>
    );
  };
 // console.log(folders);
  return (
    <ul className='grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
      {folders?.map(renderFolder)}
    </ul>
  );
};
export default FolderList;
