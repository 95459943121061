import React, { useState } from "react";
import axios from "axios";
import { url } from "../../../helper/helper";
import { withTranslation } from "react-i18next";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { postDataWithToken } from "../../../helper/api";
import { useParams } from "react-router-dom";

const CustomerMessageModal = ({ show, handleClose, t, id }) => {
  const [message, setMessage] = useState("");
  const [message_err, setMessage_err] = useState(false);
  const [loading, setLoading] = useState(false);

  // const { id } = useParams();
  // console.log(id, "id");

  const handleChange = (e) => {
    const { value } = e.target;
    setMessage(value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);
    const token = localStorage.getItem("token");
    const data = new FormData();
    data.set("message", message);
    await postDataWithToken(
      `${url}/api/resource/confirmation/${id}`,
      data,
      token
    )
      .then((response) => {
        console.log(response.data, ">>>>");
        // setMessage("");
      })
      .catch((err) => {});
  };
  return (
    <Modal
      isOpen={show}
      toggle={() => handleClose()}
      className={"modalPropu"}
      centered
    >
      <ModalHeader toggle={() => handleClose()}>
        {t("myBusiness.offer.listing.View_message")}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-md-12">
            <div>
              <div className="form-group ">
                <textarea
                  className="form-control"
                  id="messageBox"
                  placeholder={t("myBusiness.offer.listing.message_popup")}
                  name="message"
                  onChange={handleChange}
                ></textarea>
                {message_err === true ? (
                  <p style={{ color: "#eb516d " }}>"Message is required"</p>
                ) : null}
                <button
                  type="button"
                  className="btn btn-light mt-3"
                  style={{ background: "#efefef" }}
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    ""
                  )}{" "}
                  {t("myBusiness.offer.listing.Send")}
                </button>
              </div>

              <div className="scroller mt-5" style={{ height: 262, margin: 0 }}>
                <div className="detail-list">
                  {/* {data?.map(
                        ({ message, created_at, user_name, status, index }) => (
                          <dl className="d-flex" key={index}>
                            <dt className="flex-grow-0">{user_name}</dt>
                            <dd>
                              <p>{message}</p>
                              <span className="date">
                                {this.getdate(created_at)}
                              </span>
                            </dd>
                          </dl>
                        )
                      )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(CustomerMessageModal);
