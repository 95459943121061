import React, { useEffect, useState, useRef, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import SidebarChat from './Sidebar';
import { PlusIcon } from '@heroicons/react/20/solid';
import { url } from '../../../../helper/helper';
import { useParams } from 'react-router-dom';
import Files from 'react-files';
import Send from '../../../../images/send.svg';
import Attachment from '../../../../images/Vector.svg';
import UserModal from './userModal';
import NewChatModal from './NewChatModal';
import Breadcrumb from '../../../shared/Breadcrumb';
import { getData } from '../../../../helper/api';
import './PmsMessage.scss';
import useChat from './useChat';
import { dateFunc } from '../../../../helper/dateFunc/date';
import NewChatSidebar from './NewChatSidebar';
import { HandleWindowResizing } from '../../../../helper/chatFunctions/chatFunctions';
import { loadAccount } from '../../../../helper/generalFunctions/generalFunctions';
import Sidebar from '../../../shared/Sidebar';
import Header from '../../../shared/Header';
import arrow from '../../../../images/arrow-left.svg';

const PmsMessages = ({ t, location }) => {
  const params = useParams();
  const { group_id } = location?.state?.groupId || {};console.log("----GN-----",group_id);
  const { group_name: groupName } = location?.state?.groupId || {};console.log("-----group_name-----",groupName);
  const lang = localStorage.getItem('_lang');
  const [name, setName] = useState('');
  const [isOpenModalUser, setIsOpenModalUser] = useState(false);
  const [isOpenChatModal, setIsOpenChatModal] = useState(false);
  const [image, setImage] = useState('');
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [windowSize] = HandleWindowResizing();
  const {
    messages,
    sendMessages,
    uploadFile,
    handleTyping,
    personTyping,
    typing,
    onKeyUp,
    users,
    personId,
    fetchData,
    groupId,
    id,
    sidebarProjects,
    setId,
    setGroupId,
    setProjectId,
    projectId,
    setTyping,
    displayUserModal,
    messageInputRef,
    setDisplayUserModal,
  } = useChat(group_id, name);
console.log("sidebarProjects",sidebarProjects);
  const [newMessage, setNewMessage] = useState('');
  const [fileAttach, setFileAttach] = useState();
  const [userId, setUserId] = useState(null);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    loadAccount().then((res) => {
      setName(res?.full_name);
      setUserId(res?.id);
      setImage(res?.company_logo_path + res?.company_logo);
    });
  }, [name]);
  const lastMessageRef = useRef(null);

  const sendMessage = (e) => {
    e.preventDefault();
    sendMessages(newMessage, localStorage.getItem('Login_user_id'), name);
    setNewMessage('');
  };

  const sendFile = (e) => {
    uploadFile(e, localStorage.getItem('Login_user_id'), name);
  };

  useEffect(() => {
    return lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, windowSize]);

  const filteredArray = messages?.filter((obj, index, arr) => {
    if (index === 0) {
      // Always include the first object
      return true;
    }
    const previousObj = arr[index - 1];
    return obj.message_id !== previousObj.message_id;
  });

  return (
    <>
      <Sidebar>
        <Breadcrumb
          pages={[
            {
              name: t('projectManagment.Manage.title'),
              href: '/business-dashboard',
            },
            {
              name: 'Messages',
              href: '',
            },
          ]}
        />

        <SidebarChat
          dataFromParent={window.location.pathname}
          setGroupId={setGroupId}
          groupId={groupId === null ? group_id : groupId}
          getUser={setId}
          fetchData={fetchData}
          image={image}
          messages={sidebarProjects}
          setId={setId}
          id={id}
          setProjectId={setProjectId}
          windowSize={windowSize}
          setIsOpenChatModal={setIsOpenChatModal}
          isOpenChatModal={isOpenChatModal}
          setOpen={setOpen}
          open={open}
        />
{console.log(group_id,groupId)}
        {!group_id && !groupId ? (
          <>
            <div className='flex justify-end items-end -mt-10 '>
               

              
                            <button className='h-16 w-16 relative bg-white border rounded-full mt-4 mb-4' onClick={() => setOpen(true)}>
                              <img src={arrow} alt='' className='arrow' />
                            </button>
                           
            </div>
            <div className=' '>
              <div className='card h-[470px]' style={{ minHeight: '' }}>
                <div className='card-body'>Start chat on a project</div>
              </div>
            </div>
          </>
        ) : (
          <div   className='h-full -mt-10'>
          <div   className='h-max'>
          <div className="border-b border-gray-200 bg-custom-proppu-purple  px-4   sm:px-6">
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-2">
          <h3 className="text-base font-semibold leading-6 text-gray-900"> {groupName} {sidebarProjects.map((project) => (
  // Check if the current project's group_id matches the desired group ID
  project.group_id === groupId && (
     
    <h3 className="text-base font-semibold leading-6 text-gray-900"> {project.group_name} </h3>
       
       
  )
))} </h3>
        </div>
{console.log('displayUserModal',displayUserModal)}
            { 
            displayUserModal ===
              Number(localStorage.getItem('Login_user_id')) ? (

        <div className="ml-4 mt-2 flex-shrink-0">
          <button
           onClick={() => {
            setIsOpenModalUser(true);
          }}
            type="button"
            className="p-3 relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
             &#43; {t('myBusiness.chat.add_user')}
          </button>
        </div>
        
                 

              
             
            ) : (
               <></>


               
            )}
            <div className="ml-4 mt-2 flex-shrink-0">
        <div className='h-full my-auto flex-initial justify-end items-end mb-6'>
                
                            <button className='h-16 w-16 relative bg-white border rounded-full mt-4 mb-4' onClick={() => setOpen(true)}>
                              <img src={arrow} alt='' className='arrow' />
                            </button>
                           
                </div>   
                </div>

      </div>
    </div>

            <div className='divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow h-full'>
              <div className='px-4 py-5 sm:p-6 h-[430px]  overflow-auto'>
    
                {/* Content goes here */}
                {filteredArray &&
                  filteredArray.map((message, idx) => {
                    if (
                      (message.message_group_id === groupId &&
                        message.message_group_id !== group_id) ||
                      (message.message_group_id === group_id &&
                        groupId === null) ||
                      (message.message_group_id === groupId &&
                        message.message_group_id === group_id)
                    ) {
                      return message?.message_user_id ===
                        Number(localStorage.getItem('Login_user_id')) ? (
                        <div className='message__chats' key={idx}>
                          <p className='sender__name'>
                          {t('myBusiness.chat.you')} &#9679; {dateFunc(message?.created_at, lang)}
                          </p>
                          <div className='message__sender' ref={lastMessageRef}>
                            <p>{message?.message_text}</p>

                            {message.message_image && (
                              <img
                                style={{
                                  width: '150px',
                                  margin: 'auto',
                                  display: 'block',
                                }}
                                src={
                                  message.chat_file_path + message.message_image
                                }
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className='message__chats' key={idx}>
                          <p>
                            {message?.user?.full_name} &#9679;{' '}
                            {dateFunc(message?.created_at, lang)}
                          </p>
                          <div
                            className='message__recipient'
                            ref={lastMessageRef}
                          >
                            <p>{message.message_text}</p>

                            {message.message_image && (
                              <img
                                style={{
                                  width: '150px',
                                  margin: 'auto',
                                  display: 'block',
                                }}
                                // src={`data:image/png;base64,${convertBufferToImage(
                                //   message.file
                                // )}`}
                                src={
                                  message.chat_file_path + message.message_image
                                }
                              />
                            )}
                          </div>
                        </div>
                      );
                    }
                  })}
                <div className='message__status'>
                  {fileAttach && (
                    <img width={150} src={fileAttach[0].preview.url} />
                  )}
                </div>

                
              </div>
              <div className='px-4 py-4 sm:px-6'>
                {/* Content goes here */}
                {/* We use less vertical padding on card footers at all sizes than on headers or body sections */}
                {typing &&
                  personTyping &&
                  personId !==
                    Number(localStorage.getItem('Login_user_id')) && (
                    <p>{`${personTyping} is typing...`}</p>
                  )}

                <form className='form' onSubmit={sendMessage}>
                  <input
                    type='text'
                    placeholder={t('myBusiness.chat.write_message')}
                    className='message'
                    id='message'
                    autoComplete='off'
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    ref={messageInputRef}
                    //onKeyDown={() => handleTyping(name)}
                  />

                  <div>
                    <Files
                      className='files-dropzone'
                      onChange={(e) => sendFile(e)}
                      // onError={(e) => onFilesError(e)}
                      accepts={[
                        'image/gif',
                        '.doc ',
                        '.docx',
                        'image/jpeg',
                        'image/png',
                        'image/jpg',
                        '.svg',
                        '.pdf',
                      ]}
                      multiple={true}
                      maxFileSize={10000000}
                      minFileSize={10}
                      // clickable={edit}
                    >
                      <img
                        src={Attachment}
                        alt='attachment'
                        style={{ width: '25px' }}
                      />
                    </Files>
                  </div>

                  <button className='sendBtn' type='submit'>
                    <img
                      src={Send}
                      alt='send-button'
                      onClick={sendMessage}
                      className='send-image'
                    />
                  </button>
                </form>
                <UserModal
                  show={isOpenModalUser}
                  handleClose={() => {
                    setIsOpenModalUser(false);
                  }}
                  params={params}
                
                  groupId={groupId === null ? group_id : groupId}
                  projectId={projectId}
                />
                <NewChatModal
                  show={isOpenChatModal}
                  handleClose={() => {
                    setIsOpenChatModal(false);
                  }}
                  fetchData={fetchData}
                />
              </div>
            </div>
          </div>
          </div>
        )}
      </Sidebar>
    </>
  );
};

export default withTranslation()(PmsMessages);
