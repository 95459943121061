import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  createRef,
} from 'react';
import Dropzone from './Dropzone';
import ImageList from './ImageList';
import { PlusIcon } from '@heroicons/react/20/solid';
import { getData } from '../../../helper/api';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';
import Breadcrumb from '../../shared/Breadcrumb';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Sidebar from '../../shared/Sidebar';
import { v4 as uuidv4 } from 'uuid';
import Alerts from '../../shared/Alerts';
import { url } from '../../../helper/helper';
import { postDataWithToken } from '../../../helper/api';
import DropzoneFolder from './DropzoneFolder';
import FolderList from './FolderList';

const DragAndDrop = ({ t, location }) => {
  const inputReference = useRef();
  const dropzoneRef = createRef();
  let display;
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [data, setData] = useState([]);
  const [folders, setFolders] = useState([]);
  const [folderFiles, setFolderFiles] = useState([]);
  const [name, setName] = useState('');
  const [folderId, setFolderId] = useState(null);
  const [displayAlert, setDisplayAlert] = useState(false);
  const saveFiles = async () => {
    const token = localStorage.getItem('token');
    const data = new FormData();
    data.set('folder', folderId ? folderId : name);
    for (const key of Object.keys(files)) {
      data.append('image[]', files[key]);
    }

    await postDataWithToken(`${url}/api/filemanager/upload`, data, token).then(
      (res) => {
        if (res?.status === 201) {
          setDisplayAlert(true);
        }
        setTimeout(() => {
          setDisplayAlert(false);
        }, 3000);
        setName('');
      }
    );
    getFolder();
    setImages([]);
  };

  const getFolder = async () => {
    const token = localStorage.getItem('token');
    const res = await getData(
      `${url}/api/filemanager/get_files_folders`,
      token
    );
    //console.log(res);
    setData(res?.data?.files);
    setFolders(
      res?.data?.folders.map((folder) => ({ ...folder, state: false }))
      //.filter((folder) => folder.folder_name !== null)
    );
  };
  //console.log(folders);
  const removeImage = (id) => {
    const filteredImages = images.filter((image) => image.id !== id);
    setImages(filteredImages);
  };
  const deleteImage = (id) => {
    const token = localStorage.getItem('token');
    const confirmDelete = window.confirm(t('myBusiness.files.file_delete_alert'));
    if (confirmDelete) {
      getData(`${url}/api/filemanager/delete-file/${id}`, token).then((res) => {
      // console.log(res);
      const filteredImages = data.filter((image) => image.id !== id);
      setData(filteredImages);
      }).catch((error) => {
        // Handle errors, such as network issues or server errors
        console.error('Error deleting folder:', error);
        alert('An error occurred while deleting the folder. Please try again later.');
      });
      
    } else {
      // If the user cancels, do nothing
      console.log('Deletion cancelled');
    }
  };
  // const removeFolder = (id) => {
  //   const filteredImages = images.filter((image) => image.id !== id);
  //   setImages(filteredImages);
  // };
  const deleteFolder = (id) => {
    const token = localStorage.getItem('token');
    
    // Display a confirmation dialog
    const confirmDelete = window.confirm(t('myBusiness.files.delete_alert'));
    
    if (confirmDelete) {
      // If the user confirms, proceed with deletion
      getData(`${url}/api/filemanager/delete-folder/${id}`, token)
        .then((res) => {
          // Optionally, you can check the response for success
          // and provide feedback to the user
          //alert('Folder deleted successfully');
          
          // Refresh folder list after deletion
          getFolder();
        })
        .catch((error) => {
          // Handle errors, such as network issues or server errors
          console.error('Error deleting folder:', error);
          alert('An error occurred while deleting the folder. Please try again later.');
        });
    } else {
      // If the user cancels, do nothing
      console.log('Deletion cancelled');
    }
  };
  
  // onDrop function
  const onDrop = useCallback((acceptedFiles) => {
    // this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too
    // Loop through accepted files
    setFiles(acceptedFiles);
    acceptedFiles.map((file, index) => {
      // Initialize FileReader browser API
      const reader = new FileReader();
      // onload callback gets called after the reader reads the file data
      reader.onload = function (e) {
        // add the image into the state. Since FileReader reading process is asynchronous, its better to get the latest snapshot state (i.e., prevState) and update it.
        setImages((prevState) => [
          ...prevState,
          { id: index, src: e.target.result },
        ]);
      };
      // Read the file as Data URL (since we accept only images)
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  useEffect(() => {
    getFolder();
  }, []);

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.click();
    }
  };
  //console.log(name);
  const saveFolderName = async () => {
    const token = localStorage.getItem('token');
    const data = new FormData();
    data.set('name', name);
    data.set('parent_folder_id', '0');
    await postDataWithToken(
      `${url}/api/filemanager/create_folder`,
      data,
      token
    ).then((res) => {
      if (res.status === 201) {
        //openDialog();
        getFolder();
        if (inputReference.current) {
          inputReference.current.value = '';
        }
        setFolderId(res.data);
      }
    });
  };

  const changeState = async (id) => {
    const token = localStorage.getItem('token');
 

    // const updatedState = folders.map((folder) => ({
    //   ...folder,
    //   state: folder.id === id,
    // }));
    // setFolders(updatedState);
    // await getData(
    //   `${url}/api/filemanager/get_files_by_folders/${id}`,
    //   token
    // ).then((res) => {
    //   setFolderFiles(res?.data?.files);
    // });
  };

  return (
    <div>
      <Sidebar dataFromParent={location.pathname}>
        <Breadcrumb
          pages={[
            {
              name: t('myBusiness.task.heading'),
              href: '/business-dashboard',
            },
            {
              name: 'My Files',
              href: '',
            },
          ]}
        />
        <div className='card'>
          {displayAlert ? (
            <Alerts
              children={'Saved Successfully'}
              setDisplayAlert={setDisplayAlert}
            />
          ) : (
            ''
          )}
          <div className='card-body'>
            <h1 className='text-center'> {t('myBusiness.files.drag_n_drop')}</h1>

            <Dropzone
              onDrop={onDrop}
              accept={'image/*,mime/type,application/pdf'}
            />

            <ImageList
              images={images}
              removeImage={removeImage}
              display={false}
            />
            <div className='relative'>
              <div
                className='absolute inset-0 flex items-center'
                aria-hidden='true'
              >
                <div className='w-full border-t border-gray-300' />
              </div>
              <div className='relative flex justify-center'>
                <button
                  onClick={saveFiles}
                  type='button'
                  className='inline-flex w-1/3 h-form-control items-center justify-center gap-x-1.5 rounded-full bg-[#0790c9] px-3 py-1.5 text-2xl text-white shadow-sm ring-1 ring-inset ring-gray-300'
                >
                  <PlusIcon
                    className='-ml-1 -mr-0.5 h-7 w-7 text-white'
                    aria-hidden='true'
                  />
                  {t('myBusiness.files.save')} 
                </button>
              </div>
            </div>
            {/* <div className='col-span-1 divide-y divide-gray-200 rounded-lg bg-white text-center shadow mt-6'>
              <div className='text-center cursor-pointer'>
                <svg
                  className='mx-auto h-12 w-12 text-gray-400'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  aria-hidden='true'
                >
                  <path
                    vectorEffect='non-scaling-stroke'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
                  />
                </svg>
                <input
                  className='mt-2 text-lg text-center font-semibold text-gray-900'
                  placeholder='No Folder'
                  ref={inputReference}
                  onChange={(e) => setName(e.target.value)}
                  onBlur={saveFolderName}
                />

                <p className='mt-1 text-sm text-gray-500'>
                  Get started by creating a new folder.
                </p>
                <div className='mt-6'>
                  <button
                    onClick={() => inputReference.current.focus()}
                    type='button'
                    className='inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  >
                    <PlusIcon
                      className='-ml-0.5 mr-1.5 h-5 w-5'
                      aria-hidden='true'
                    />
                    New Folder
                  </button>
                </div>
              </div>
              <DropzoneFolder
                onDrop={onDrop}
                accept={'image/*,mime/type,application/pdf'}
                dropzoneRef={dropzoneRef}
              />
            </div> */}
            <ImageList
              images={data?.filter((image) => image.folder_name === null)}
              removeImage={deleteImage}
              inputReference={inputReference}
              setName={setName}
              saveFolderName={saveFolderName}
              dropzoneRef={dropzoneRef}
              onDrop={onDrop}
              display={true}
            />

            <FolderList
              folders={folders?.filter((folder) => folder.folder_name !== null)}
              changeState={changeState}
              data={folderFiles}
              removeImage={removeImage}
              removeFolder={deleteFolder}
              onDrop={onDrop}
            />
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default withTranslation()(DragAndDrop);
