import React from 'react';
import { withTranslation } from 'react-i18next';

const LanguageComponent = ({ t, language, changeLanguage }) => {
  return (
    <div className='justify-center pt-4 flex'>
      <button
        className={language === 'fi' ? 'text-xl p-1 font-bold' : 'text-xl p-1 '}
        onClick={() => changeLanguage('fi')}
      >
        {t('login.Finland')}
      </button>
      <div className='mx-2 py-3'>|</div>
      <button
        className={language === 'en' ? 'text-xl p-1 font-bold' : 'text-xl p-1 '}
        onClick={() => changeLanguage('en')}
      >
        {t('login.English(US)')}
      </button>
      <div className='mx-2 py-3'>|</div>
      <button
        className={language === 'es' ? 'text-xl p-1 font-bold' : 'text-xl p-1 '}
        onClick={() => changeLanguage('es')}
      >
        {t('login.Spain')}
      </button>
      <div className='mx-2 py-3'>|</div>
      <button
        className={
          language === 'est' ? 'text-xl p-1 font-bold' : 'text-xl p-1 '
        }
        onClick={() => changeLanguage('est')}
      >
        {t('login.Estonian')}
      </button>
    </div>
  );
};
export default withTranslation()(LanguageComponent);
