import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../../shared/Breadcrumb';
import Sidebar from '../../../shared/Sidebar';
import Tabs from './tab/Tabs';

const CreateProject = ({ t, location }) => {
  return (
    <div>
      <div className=''>
        <Sidebar dataFromParent={window.location.pathname}>
          <Breadcrumb
            pages={[
              {
                name: t('projectManagment.Manage.title'),
                href: '/business-dashboard',
              },
              {
                name: t('projectManagment.Create.create_project'),
                href: '',
              },
            ]}
          />
          <div className=''>
            <Tabs />
          </div>
        </Sidebar>
      </div>
    </div>
  );
};

export default withTranslation()(CreateProject);
