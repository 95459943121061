import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { withTranslation } from 'react-i18next';

const RegStepTwo = ({
  t,
  phone,
  country,
  state,
  changePhone,
  email,
  handleChange,
  password,
  c_password,
  pass_noMach,
  setStep,
  isStepValid,
  setState,
}) => {
  return (
    <>
      <div className='reg-step-1'>{t('account.step_2')}</div>
      <div className='signup_error'>{phone}</div>
      <div className={`custom_phn ${phone ? 'borderError' : ''}`}>
        <PhoneInput
          name='phone'
          searchClass='custon_sign'
          country={country ? country.toLowerCase() : 'fi'}
          enableAreaCodes={true}
          countryCodeEditable={false}
          onChange={changePhone}
          value={state.phone}
          className='px-6 py-3 text-2xl h-form-control w-full border border-gray-300 rounded-md'
        />
      </div>
      <div className='signup_error'>{email}</div>
      <div className={`mb-4 ${email ? 'borderError' : ''}`}>
        <input
          className='px-6 py-3 text-2xl h-form-control w-full border border-gray-300 rounded-md'
          onChange={handleChange}
          name='email'
          type='email'
          value={state?.email}
          placeholder={t('account.email')}
        />
        <div className='invalid-feedback'>{t('login.valid_email')}</div>
      </div>
      <div className='signup_error'>{password}</div>
      <div className={`mb-4 ${password ? 'borderError' : ''}`}>
        <input
          onChange={handleChange}
          className='px-6 py-3 text-2xl h-form-control w-full border border-gray-300 rounded-md'
          type='password'
          name='password'
          value={state?.password}
          placeholder={t('account.password')}
        />
        <span className='icon-eye show-pwd'></span>
      </div>
      <div className='signup_error'>{c_password}</div>
      <div className={`mb-4 ${c_password ? 'borderError' : ''}`}>
        <input
          onChange={handleChange}
          className={`px-6 py-3 text-2xl h-form-control w-full border border-gray-300 rounded-md ${
            pass_noMach ? 'validation_err' : ''
          }`}
          type='password'
          name='c_password'
          value={state?.c_password}
          placeholder={t('account.old_password1')}
        />
      </div>
      {pass_noMach ? (
        <p className='color_red error_font'> {pass_noMach} </p>
      ) : (
        ''
      )}{' '}
      <div className='btn-group-login'>
        <button
          className='px-6 py-3 text-2xl h-form-control w-full  rounded-md text-white bg-[#333] hover:bg-[#1a1a1a]'
          onClick={() => {
            setStep((prev) => prev - 1);
          }}
        >
          {t('account.previous')}
        </button>
        <div style={{ width: '20px' }}></div>
        <button
          className='px-6 py-3 text-2xl h-form-control w-full  rounded-md text-white bg-[#333] hover:bg-[#1a1a1a]'
          onClick={(e) => {
            e.preventDefault();

            setStep((prev) => (isStepValid() ? prev + 1 : 2));
          }}
        >
          {t('account.next')}
        </button>
      </div>
    </>
  );
};

export default withTranslation()(RegStepTwo);
