import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import './UserTypeModal.css';
const UserTypeModal = ({ show, handleChange, handleClose }) => {
  return (
    <>
      <Modal
        isOpen={show}
        toggle={() => {
          handleClose();
        }}
        className={'modalPropu'}
        style={{ width: '100%' }}
        centered
        dialogClassName='modal-20w'
        aria-labelledby='contained-modal-title-vcenter'
      >
        <ModalHeader
          style={{ alignSelf: 'center' }}
          //   toggle={() => {
          //     props.handleClose();
          //   }}
          //   className='d-flex justify-content-between'
        ></ModalHeader>
        <ModalBody>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              //minHeight: '15em',
            }}
          >
            <div className='user-container'>
              <div
                className='modal-title'
                style={{ marginBottom: '2rem', textAlign: 'center' }}
              >
                Please choose the user type!
              </div>
              <div
                className='select subtype-modal'
                style={{ width: '30em !important' }}
              >
                <select
                  name='subtype'
                  onChange={handleChange}
                  style={{ paddingBottom: '1rem', fontSize: '13px' }}
                >
                  <option value>Select</option>
                  <option value='company'>Company</option>

                  {/* <option value='individual'>Individual</option> */}
                  <option value='consumer'>Consumer</option>

                  {/* <option value='proppu-handyman'>Proppu Hnadyman</option>
                  <option value='property-manager'>Property Manager</option> */}
                </select>
              </div>
            </div>
          </div>
          <br />
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTranslation()(UserTypeModal);
