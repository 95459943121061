import React, { useState } from 'react';
import { getData } from '../api';
import { url } from '../helper';

export const currencyFormat = (num) => {
  return num?.replace('.', ',');
};

export const loadConfig = async () => {
  try {
    const token = await localStorage.getItem('token');
    const result = await getData(`${url}/api/config/currency`, token);
    if (result) {
      const { left, right } = result?.data;

      return { left, right };
    }
  } catch (err) {
    console.error(err);

    return { left: '', right: '' };
  }
};

export const loadCategory = async () => {
  try {
    const token = await localStorage.getItem('token');
    let lang = await localStorage.getItem('_lng');
    const result = await getData(`${url}/api/category/${lang}`, token);
    if (result) {
      return result?.data.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const loadState = async () => {
  try {
    const token = await localStorage.getItem('token');
    let lang = await localStorage.getItem('_lng');
    const result = await getData(`${url}/api/state_new/${lang}`, token);
    if (result) {
      return result?.data?.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const loadCities = async (id) => {
  try {
    const token = await localStorage.getItem('token');
    const lang = await localStorage.getItem('_lng');
    if (id && lang) {
      const result = await getData(`${url}/api/cityId/${id}/${lang}`, token);
      if (result) {
        return result?.data?.data;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const loadAccount = async () => {
  try {
    const token = await localStorage.getItem('token');
    const result = await getData(`${url}/api/account`, token);
    if (result) {
      return result?.data[0];
    }
  } catch (error) {
    console.log(error);
  }
};
export const loadResources = async () => {
  const options = [];
  try {
    const token = await localStorage.getItem('token');
    const result = await getData(`${url}/api/resources-list`, token);
    if (result) {
      return result?.data;
    }
  } catch (error) {
    console.log(error);
  }
};
