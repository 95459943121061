import React, { useState, useEffect } from 'react';
import Header from '../../shared/Header';
import { Link } from 'react-router-dom';
import BussinessSidebar from '../../shared/BussinessSidebar';
import { withTranslation } from 'react-i18next';
import { url } from '../../../helper/helper';
import axios from 'axios';
import Breadcrumb from '../../shared/Breadcrumb';
import './roles.css';
import Sidebar from '../../shared/Sidebar';
import { useHistory } from 'react-router-dom';
const CreateRoles = ({ t, location }) => {
  const [name, setName] = useState('');
  const [errors, setErrors] = useState('');
  const history = useHistory();
  const handleChange = (e) => {
    setName(e.target.value);
    const newErrors = errors;
    setErrors(newErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let hasErrors = false;
    let newErrors = errors;

    if (!name) {
      hasErrors = true;
      newErrors = 'Name Required';
    }
    if (name.length > 1 && name.length < 5) {
      hasErrors = true;
      newErrors = 'Please enter at least 5 characters';
    }
    if (hasErrors) {
      setErrors(newErrors);
      return;
    }
    createRole();
    history.push('/permission');
  };

  const createRole = async () => {
    const token = await localStorage.getItem('token');

    axios
      .post(
        `${url}/api/role/store`,
        { name: name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Sidebar dataFromParent={location.pathname}>
        <Breadcrumb
          pages={[
            {
              name: t('header.my_bussiness'),
              href: '/business-dashboard',
            },
            {
              name: t('b_sidebar.roles.create'),
              href: '/roles/create',
            },
          ]}
        />
        <h3 className='project-title'>{t('permission.create_new_role')}</h3>
        <section className='create-section'>
          <form className='role-form' onSubmit={handleSubmit}>
            <div className='form-group'>
              <label>{t('account.Role')}</label>
              <input
                className={errors ? 'hasError' : 'noError'}
                type='text'
                placeholder={t('permission.add_name')}
                value={name}
                onChange={handleChange}
                name='input'
              />
              <div className='error'>{errors}</div>
            </div>
            <button type='submit' className='create'>
              {t('b_sidebar.roles.create')}
            </button>
          </form>
        </section>
      </Sidebar>
    </>
  );
};

export default withTranslation()(CreateRoles);
