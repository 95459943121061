import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Logo from '../../images/Full-Logo-lighter.png';
import i18n from '../../locales/i18n';
import { withTranslation } from 'react-i18next';
import ChangeLanguage from './ChangeLanguage';
const SignupSuccess = (props) => {
  const { t } = props;

  const [lng, setLng] = useState(localStorage.getItem('_lng'));

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('_lng', lng);
    setLng(localStorage.getItem('_lng'));
  };

  return (
    <div className='bg-[#f7f7f6] h-screen overflow-y-auto'>
      <div className='md:p-12 xs:px-6 xs:pt-6 max-w-4xl  my-0 mx-auto'>
        <div className='items-center md:mb-16 xs:mb-8'>
          <img src={Logo} alt={Logo} className='max-w-xs mx-auto min-h-43' />
        </div>
        <div className='border-none rounded-2xl shadow-login bg-white'>
          <div className='p-12'>
            <div className='flex flex-col p-10 text-2xl rounded-lg text-center'>
              {t('registerSuccess.login')}

              <button
                className='mt-20 bg-green-600 text-white text-2xl rounded-2xl py-3 px-6'
                onClick={() => props.history.push('/')}
              >
                {t('registerSuccess.back')}
              </button>
            </div>
          </div>
        </div>
        <p className='block md:my-12 xs:my-8 text-2xl text-center font-semibold hover:no-underline xs:mb-0'>
          {' '}
          <Link to='/'>{t('registerSuccess.signIn')}</Link>
        </p>

        <ChangeLanguage changeLanguage={changeLanguage} language={lng} />
      </div>
    </div>
  );
};

export default withTranslation()(SignupSuccess);
