import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { postDataWithToken } from '../../../../helper/api';
import { url } from '../../../../helper/helper';
import { withTranslation } from 'react-i18next';

const ReleaseModal = (props) => {
  const t = props.t;
  console.log(props);
  const [isCustomer, setIsCustomer] = useState(false);
  const [isAssignee, setIsAssignee] = useState(false);

  const handleChangeCustomer = () => {
    setIsCustomer(!isCustomer);
  };
  const handleChangeAssignee = () => {
    setIsAssignee(!isAssignee);
  };

  const releaseProject = async () => {
    const token = localStorage.getItem('token');
    const data = new FormData();
    data.set('project_id', props.projectId);
    if (isAssignee === true) {
      data.set('permission[]', 'my_resources');
    }
    if (isCustomer === true) {
      data.set('permission[]', 'customer');
    }
    if (isAssignee === true || isCustomer === true) {
      data.set('permission[]', 'customer,my_resources');
    }

    await postDataWithToken(`${url}/api/project/release_project`, data, token)
      .then((result) => {
        console.log(result);
        props.handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        isOpen={props.show}
        toggle={() => {
          props.handleClose();
        }}
        className={'modalPropu'}
        style={{ width: '40%' }}
        centered
        dialogClassName='modal-20w'
        aria-labelledby='contained-modal-title-vcenter'
      >
        <ModalHeader
          toggle={() => {
            props.handleClose();
          }}
          className='d-flex justify-content-between'
        >
          <div className='modal-title'>
            {t('projectManagment.ReleaseModal.notify_parties')}
          </div>
        </ModalHeader>
        <ModalBody>
          <div
            className='form-check form-check-inline'
            style={{ fontSize: '15px', fontWeight: '500' }}
          >
            <input
              onChange={handleChangeCustomer}
              type='checkbox'
              defaultChecked={isCustomer}
              className='form-check-input'
            />
            <label className='form-check-label'>
              {t('projectManagment.ReleaseModal.customer')}
            </label>
          </div>
          <br />
          <div
            className='form-check form-check-inline'
            style={{ fontSize: '15px', fontWeight: '500' }}
          >
            <input
              onChange={handleChangeAssignee}
              type='checkbox'
              defaultChecked={isAssignee}
              className='form-check-input'
            />
            <label className='form-check-label'>
              {t('projectManagment.ReleaseModal.assignee')}
            </label>
          </div>
          <hr />
          {t('projectManagment.ReleaseModal.text')}
          <hr />
          <button className='btn btn-primary p-2' onClick={releaseProject}>
            {t('projectManagment.Manage.release_project')}
          </button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTranslation()(ReleaseModal);
