import React from 'react';
import { useDropzone } from 'react-dropzone';
import { withTranslation } from 'react-i18next';
const Dropzone = ({ t, onDrop, accept }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });
  
  return (
    <div
      className='p-5 mx-auto w-3/5 border-dashed border-2 border-custom-proppu-purple'
      {...getRootProps()}
    >
      <input className='dropzone-input' {...getInputProps()} />
      <div className='text-center'>
        {isDragActive ? (
          <p className='dropzone-content'>Release to drop the files here</p>
        ) : (
          <p className='dropzone-content'>
            {t('myBusiness.files.drag_n_select')} 
          </p>
        )}
      </div>
    </div>
  );
};

export default withTranslation() (Dropzone);
