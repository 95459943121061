import React, { Component } from 'react';
import axios from 'axios';
import Header from '../shared/Header';
import Sidebar from '../shared/Sidebar';
import Spinner from 'react-bootstrap/Spinner';
import { Helper, url } from '../../helper/helper';
import { HashRouter as Router, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Image from '../../images/DefaultImg.png';
import Pagination from '../myBussiness/pagination/pagination';
import Breadcrumb from '../shared/Breadcrumb';
import moment from 'moment';
import { getData } from '../../helper/api';

import {
  currencyFormat,
  loadConfig,
} from '../../helper/generalFunctions/generalFunctions';
class Savedjobs extends Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);

    this.state = {
      jobs: [],
      removed: false,
      savedLoaded: false,
      loading: false,
      currentPage: 1,
      postsPerPage: 10,
      left: null,
      right: null,
    };
  }

  // loadConfig = async () => {
  //   const token = await localStorage.getItem('token');
  //   await getData(`${url}/api/config/currency`, token)
  //     .then((result) => {
  //       const { left, right } = result.data;
  //       this.setState({ left, right });
  //     })
  //     .catch((err) => {
  //       //console.log(err);
  //     });
  // };

  componentDidMount = () => {
    this._isMounted = true;
    this.axiosCancelSource = axios.CancelToken.source();

    this.loadData(this.axiosCancelSource);
    loadConfig().then((res) => {
      this.setState({
        left: res?.left,
        right: res?.right,
      });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.removed !== this.state.removed) {
      this.loadData(this.axiosCancelSource);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel();
  }
  budget(budget, cost_per_unit, unit) {
    const { t } = this.props;
    if (budget !== null) {
      return t('marketplace.feeds.budget');
    }
    if (cost_per_unit !== null) {
      return t('marketplace.feeds.budget');
    }
    if (unit !== null) {
      return 'unit';
    }
  }

  loadData = async (axiosCancelSource) => {
    const token = await localStorage.getItem('token');
    axios
      .get(`${url}/api/saved`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: axiosCancelSource.token,
      })
      .then((result) => {
        //console.log("result jobs", result)
        if (this._isMounted) {
          this.setState({ jobs: result.data.data, loaded: true });
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          //console.log("Request canceled", err.message);
        } else {
          //console.log(err.response);
        }
      });
  };

  remove = async (id) => {
    this.setState({ feedid: id, savedLoaded: true });
    const token = await localStorage.getItem('token');
    await axios
      .delete(`${url}/api/saved/remove/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        //console.log("result", result)
        // this.setState({ removed: false });
        this.setState({ removed: true, savedLoaded: false });
        // window.location.reload(false);
      })
      .catch((err) => {
        // if (err.response.status === 404) {
        //   return alert("Saved job doesn't belong to the user");
        // }
        // return alert("Some issue occured");
      });
    this.setState({ savedLoaded: false });
    this.axiosCancelSource = axios.CancelToken.source();

    this.loadData(this.axiosCancelSource);
  };

  url(type, category) {
    if (category === 'Material') {
      return 'material-offer-detail';
    }
    if (category === 'Work') {
      return 'work-detail';
    }
    return null;
  }

  paginate = (number) => {
    this.setState({
      currentPage: number,
    });
  };

  handleChange = (e) => {
    this.setState({
      postsPerPage: e.target.value,
    });
  };

  // Render Funtion
  render() {
    const { t, i18n } = this.props;
    let i = 1;
    const saveJob = this.state.jobs ? this.state.jobs : '';

    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPosts = saveJob?.slice(indexOfFirstPost, indexOfLastPost);
    const length = currentPosts ? currentPosts.length : '';

    //console.log("vcurrentPosts", currentPosts)

    return (
      <div>
        <Sidebar dataFromParent={this.props.location.pathname}>
          <Breadcrumb
            pages={[
              { name: t('header.marketplace'), href: '/feeds' },
              {
                name: t('marketplace.saved_jobs.Saved'),
                href: '',
              },
            ]}
          />
          <div className='container-fluid'>
            <div className='card'>
              <div className='card-header'>
                <h3 className='head3 mb-0'>
                  {t('marketplace.saved_jobs.saved_job')}
                </h3>
              </div>
              <div className='card-body'>
                <div className='feeds' style={{ maxWidth: '100%' }}>
                  {currentPosts ? (
                    currentPosts.length <= 0 ? (
                      <div className='item'>
                        <h3> {t('marketplace.saved_jobs.No_found')} </h3>
                      </div>
                    ) : (
                      currentPosts.map((feed) => (
                        <Link
                          to={{
                            pathname: `/${this.url(
                              feed[0].tender_type,
                              feed[0].tender_category_type
                            )}/${feed[0].tender_id}`,
                          }}
                          style={{
                            textDecoration: 'none',
                            color: 'black',
                          }}
                        >
                          <div className='item' key={feed[0].tender_id}>
                            <div className='img-box'>
                              {feed[0].tender_featured_image ? (
                                <img
                                  src={`${url}/images/marketplace/material/${feed[0].tender_featured_image}`}
                                  alt='featured'
                                  style={{ maxHeight: '160px' }}
                                />
                              ) : (
                                <img
                                  src={Image}
                                  alt='Trulli'
                                  width='350'
                                  height='100%'
                                />
                              )}
                            </div>
                            <div className='content-box'>
                              <h3>{feed[0].tender_title}</h3>
                              <p>
                                {feed[0].tender_description &&
                                feed[0].tender_description.length > 100
                                  ? `${feed[0].tender_description.substring(
                                      0,
                                      150
                                    )}...`
                                  : feed[0].tender_description}
                                .
                              </p>
                              <p className='m-0'>
                                <span className='badge badge-secondary'>
                                  {feed[0].tender_type === 'Request'
                                    ? t(
                                        'marketplace.saved_jobs.tender_type_request'
                                      )
                                    : t(
                                        'marketplace.saved_jobs.tender_type_offer'
                                      )}
                                </span>
                                <span className='badge badge-secondary'>
                                  {feed[0].tender_category_type === 'Work'
                                    ? t(
                                        'marketplace.saved_jobs.tender_category_type_work'
                                      )
                                    : t(
                                        'marketplace.saved_jobs.tender_category_type_material'
                                      )}
                                </span>
                                <span className='badge badge-secondary'>
                                  {feed[0].extra === 2
                                    ? t('marketplace.saved_jobs.work_included')
                                    : feed[0].extra === 1
                                    ? t(
                                        'marketplace.saved_jobs.material_included'
                                      )
                                    : null}
                                </span>
                              </p>
                              <ul>
                                <li>
                                  <span
                                    className='cl-light'
                                    style={{ fontWeight: '700' }}
                                  >
                                    {this.budget(
                                      feed[0].tender_budget,
                                      feed[0].tender_cost_per_unit,
                                      feed[0].tender_unit
                                    )}
                                  </span>
                                  <span
                                    className='cl-light'
                                    style={{ fontWeight: '400' }}
                                  >
                                    {feed[0].tender_category_type === 'Work' ? (
                                      feed[0].tender_budget === 'Fixed' ? (
                                        <span
                                          className='cl-light'
                                          style={{ fontWeight: '400' }}
                                        >
                                          {t('marketplace.feeds.qnt')}
                                        </span>
                                      ) : feed[0].tender_budget === 'Hourly' ? (
                                        <span
                                          className='cl-light'
                                          style={{ fontWeight: '400' }}
                                        >
                                          {t('marketplace.feeds.qnt1')}
                                        </span>
                                      ) : feed[0].tender_budget === 'per_m2' ? (
                                        <span
                                          className='cl-light'
                                          style={{ fontWeight: '400' }}
                                        >
                                          {t('marketplace.feeds.qnt2')}
                                        </span>
                                      ) : feed[0].tender_budget === null ? (
                                        'null'
                                      ) : null
                                    ) : feed[0].tender_unit === 'Pcs' ? (
                                      <span
                                        className='cl-light'
                                        style={{ fontWeight: '400' }}
                                      >
                                        {t('marketplace.feeds.qnt3')}
                                      </span>
                                    ) : feed[0].tender_unit === 'Kg' ? (
                                      <span
                                        className='cl-light'
                                        style={{ fontWeight: '400' }}
                                      >
                                        {t('marketplace.feeds.qnt4')}
                                      </span>
                                    ) : feed[0].tender_unit === 'M2' ? (
                                      <span
                                        className='cl-light'
                                        style={{ fontWeight: '400' }}
                                      >
                                        {t('marketplace.feeds.qnt5')}
                                      </span>
                                    ) : feed[0].tender_unit === 'Liter' ? (
                                      <span
                                        className='cl-light'
                                        style={{ fontWeight: '400' }}
                                      >
                                        {t('marketplace.feeds.qnt6')}
                                      </span>
                                    ) : feed[0].tender_unit ===
                                      'Per package' ? (
                                      <span
                                        className='cl-light'
                                        style={{ fontWeight: '400' }}
                                      >
                                        {t('marketplace.feeds.qnt9')}
                                      </span>
                                    ) : feed[0].tender_unit === 'Total cost' ? (
                                      <span
                                        className='cl-light'
                                        style={{ fontWeight: '400' }}
                                      >
                                        {t('marketplace.feeds.qnt8')}
                                      </span>
                                    ) : feed[0].tender_unit === null ? (
                                      'null'
                                    ) : null}
                                  </span>
                                </li>
                                <li>
                                  {feed[0].tender_type === 'Request' ? (
                                    <span
                                      className='cl-light'
                                      style={{ fontWeight: '700' }}
                                    >
                                      {t('marketplace.feeds.qnt_7')}
                                    </span>
                                  ) : (
                                    <span
                                      className='cl-light'
                                      style={{ fontWeight: '700' }}
                                    >
                                      {t('marketplace.feeds.qnt7')}
                                    </span>
                                  )}
                                  {feed[0].tender_category_type === 'Work' ? (
                                    feed[0].tender_budget === 'Fixed' ? (
                                      <span
                                        className='cl-light'
                                        style={{ fontWeight: '400' }}
                                      >
                                        {currencyFormat(feed[0]?.tender_rate)}
                                        {this.state.right}
                                      </span>
                                    ) : feed[0].tender_budget === 'Hourly' ? (
                                      <span
                                        className='cl-light'
                                        style={{
                                          textTransform: 'lowercase',
                                          fontWeight: '400',
                                        }}
                                      >
                                        {currencyFormat(feed[0]?.tender_rate)}
                                        {`${this.state.right}/h`}
                                      </span>
                                    ) : feed[0].tender_budget === 'per_m2' ? (
                                      <span
                                        className='cl-light'
                                        style={{ fontWeight: '400' }}
                                      >
                                        {currencyFormat(feed[0]?.tender_rate)}
                                        {`${this.state.right}/m2`}
                                      </span>
                                    ) : feed[0].tender_budget === null ? (
                                      'null'
                                    ) : null
                                  ) : feed[0].tender_unit === 'Pcs' ? (
                                    <span
                                      className='cl-light'
                                      style={{ fontWeight: '400' }}
                                    >
                                      {currencyFormat(
                                        feed[0]?.tender_cost_per_unit
                                      )}
                                      {this.state.right}/
                                      {t('marketplace.feeds.kpl')}
                                    </span>
                                  ) : feed[0].tender_unit === 'Kg' ? (
                                    <span
                                      className='cl-light'
                                      style={{ fontWeight: '400' }}
                                    >
                                      {currencyFormat(
                                        feed[0]?.tender_cost_per_unit
                                      )}
                                      {this.state.right}/
                                      {t('marketplace.feeds.kg')}
                                    </span>
                                  ) : feed[0].tender_unit === 'M2' ? (
                                    <span
                                      className='cl-light'
                                      style={{ fontWeight: '400' }}
                                    >
                                      {currencyFormat(
                                        feed[0]?.tender_cost_per_unit
                                      )}
                                      {this.state.right}/
                                      {t('marketplace.feeds.m2')}
                                    </span>
                                  ) : feed[0].tender_unit === 'Liter' ? (
                                    <span
                                      className='cl-light'
                                      style={{ fontWeight: '400' }}
                                    >
                                      {currencyFormat(
                                        feed[0]?.tender_cost_per_unit
                                      )}
                                      {this.state.right}/
                                      {t('marketplace.feeds.l')}
                                    </span>
                                  ) : feed[0].tender_unit === 'Per package' ? (
                                    <span
                                      className='cl-light'
                                      style={{ fontWeight: '400' }}
                                    >
                                      {currencyFormat(
                                        feed[0]?.tender_cost_per_unit
                                      )}
                                      {this.state.right}/
                                      {t('marketplace.feeds.pckg')}
                                    </span>
                                  ) : feed[0].tender_unit === 'Total cost' ? (
                                    <span
                                      className='cl-light'
                                      style={{ fontWeight: '400' }}
                                    >
                                      {currencyFormat(
                                        feed[0]?.tender_cost_per_unit
                                      )}
                                      {this.state.right}
                                    </span>
                                  ) : feed[0].tender_unit === null ? (
                                    'null'
                                  ) : null}
                                </li>
                                <li>
                                  <span
                                    className='cl-light'
                                    style={{ fontWeight: '700' }}
                                  >
                                    {t('marketplace.feeds.Time_left')}
                                  </span>
                                  <span
                                    className='cl-light'
                                    style={{ fontWeight: '400' }}
                                  >
                                    {moment(feed[0].tender_expiry_date).format(
                                      'DD.MM.YYYY'
                                    )}
                                  </span>
                                </li>
                              </ul>
                              {this.state.savedLoaded === true &&
                              this.state.feedid === feed[0].tender_id ? (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a
                                  id={feed[0].tender_id}
                                  className='add-favorites'
                                >
                                  <Spinner
                                    animation='border'
                                    role='status'
                                    key={feed[0].tender_id}
                                  >
                                    <span
                                      key={feed[0].tender_id}
                                      className='sr-only'
                                    >
                                      {' '}
                                      {t('marketplace.saved_jobs.Loading')}{' '}
                                    </span>
                                  </Spinner>
                                </a>
                              ) : (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a
                                  key={feed[0].tender_id}
                                  id={feed[0].tender_id}
                                  onClick={() => this.remove(feed[0].tender_id)}
                                  className={`add-favorites ${
                                    this.state.savedLoaded == true
                                      ? 'disable'
                                      : ''
                                  }`}
                                >
                                  <i className='icon-heart'></i>
                                </a>
                              )}
                            </div>
                          </div>
                        </Link>
                      ))
                    )
                  ) : null}
                  {length > 10 ? (
                    <div className='row' style={{ width: '100%' }}>
                      <div className='col-md-4'>
                        <h3 className='total_rec'> Total {length} </h3>
                      </div>
                      <div className='col-md-4 '>
                        <select
                          id='dropdown_custom'
                          className='custom_pag'
                          onChange={this.handleChange}
                          value={this.state.postsPerPage}
                        >
                          <option value='10'>10</option>
                          <option value='20'>20</option>
                          <option value='40'>40</option>
                          <option value='80'>80</option>
                          <option value='100'>100</option>
                        </select>
                      </div>
                      <div className='col-md-4'>
                        <Pagination
                          postsPerPage={this.state.postsPerPage}
                          totalPosts={length}
                          paginate={this.paginate}
                          currentPage={this.state.currentPage}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Sidebar>
      </div>
    );
  }
}

export default withTranslation()(Savedjobs);
