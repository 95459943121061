import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import './UserTypeModal.css';
const NewChatModal = ({ show, handleClose }) => {
  const closeModal = () => {
    setTimeout(() => {
      handleClose();
    }, 3000);
  };
  return (
    <>
      <Modal
        isOpen={show}
        toggle={() => {
          closeModal();
        }}
        className={'modalPropu'}
        style={{ width: '100%' }}
        centered
        dialogClassName='modal-20w'
        aria-labelledby='contained-modal-title-vcenter'
      >
        <ModalHeader style={{ alignSelf: 'center' }}>
          {' '}
          Thank you for registering.
        </ModalHeader>
        <ModalBody>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <>
              Please confirm your email address from the link sent to your
              email. After this, you can log in and startusing ProppU. Our team
              will contact you to help you get started. Here are some useful
              links: (Link to youtube channel)
            </>
          </div>
          <br />
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTranslation()(NewChatModal);
