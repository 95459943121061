import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getData, postDataWithToken } from '../../../../helper/api';
import { url } from '../../../../helper/helper';
import { Multiselect } from 'multiselect-react-dropdown';
import { withTranslation } from 'react-i18next';

const NewChatModal = (props) => {
  const token = localStorage.getItem('token');
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState('');
  const [chatName, setChatName] = useState('');
  const [userType, setuserType] = useState([]);
  const t = props.t;

  const getClientsResources = async () => {
    await getData(`${url}/api/all-resources`, token).then((res) => {
      let data = res?.data?.map(
        ({ first_name, last_name, ur_resource_id, ur_resource_type }) => {
          return {
            id: ur_resource_id,
            ur_resource_id,
            ur_resource_type,
            full_name: first_name + ' ' + last_name,
          };
        }
      );
      setUsers(data);
    });
  };

  const onRemoveUsers = (selectedList, removedItem) => {
    const mapUsers = selectedList.map((user) => user.id);
    const filter = mapUsers.filter(
      (user) => user !== removedItem.ur_resource_id
    );
    setUser(filter);
    filterOnRemoveType(selectedList, removedItem);
  };

  const onSelectUsers = (selectedList, selectedItem) => {
    const selected = selectedList.map((value) => value.id);
    let list = [];
    list.push(...selected, selectedItem.ur_resource_id);
    let unique = [...new Set(list)];
    setUser(unique);
    filterOnSelectType(selectedList, selectedItem);
  };

  const filterOnSelectType = (selectList, _selectItem) => {
    const selected = selectList.map((value) => value.ur_resource_type);
    let list = [];
    list.push(...selected);
    setuserType(list);
  };

  const filterOnRemoveType = (selectList, _selectItem) => {
    const mapUsers = selectList.map((user) => user.ur_resource_type);
    setuserType(mapUsers);
  };

  const postResourceType = (value) => {
    if (value[0] === 'Client' && value.length === 1) {
      return 1;
    } else if (value[0] !== 'Client' && value.length === 1) {
      return 2;
    } else if (value.length > 1) {
      return 3;
    } else {
      return;
    }
  };

  const chatCreation = async () => {
    const token = localStorage.getItem('token');
    const data = new FormData();
    data.set('name', chatName);
    data.set('users[]', user);
    data.set('type', postResourceType(userType));
    await postDataWithToken(`${url}/api/chat/start-new-chat`, data, token).then(
      (res) => {
        console.log(res);
        props.handleClose();
      }
    );
  };

  useEffect(() => {
    getClientsResources();
  }, []);
  return (
    <>
      <Modal
        isOpen={props.show}
        toggle={() => {
          props.handleClose();
        }}
        className={'modalPropu'}
        style={{ width: '100%' }}
        centered
        dialogClassName='modal-20w'
        aria-labelledby='contained-modal-title-vcenter'
      >
        <ModalHeader
          toggle={() => {
            props.handleClose();
          }}
          className='d-flex justify-content-between'
        >
          <div className='modal-title'>New Chat</div>
        </ModalHeader>
        <ModalBody>
          <div>
            <input
              type='text'
              className='create-chat'
              placeholder={'New chat'}
              onChange={(e) => setChatName(e.target.value)}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Multiselect
              options={users}
              //selectedValues={savedConstruction}
              displayValue='full_name'
              onRemove={onRemoveUsers}
              onSelect={onSelectUsers}
              //selectedList={savedConstruction}
              placeholder={t('account.professional.Select')}
            />
          </div>
          <br />
          {/* <hr />
          Add and remove users from this chat
          <hr /> */}
          <button className='btn btn-primary p-2' onClick={chatCreation}>
            Save chat
          </button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTranslation()(NewChatModal);
