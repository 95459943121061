import './_dashboard.css';
import * as React from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import Header from '../shared/Header';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { url } from '../../helper/helper';
import Sidebar from '../shared/Sidebar';
import { Si } from 'react-flags-select';
import Overlay from '../shared/Overlay';
const QuicklinkCard = ({ title, children, location }) => (
  <div className='quicklink-card'>
    <h3 className='quicklink-card__title'>{title}</h3>
    <div className='quicklink-card__content'>{children}</div>
  </div>
);

const MarketplaceCard = () => {
  const { t } = useTranslation();

  return (
    <QuicklinkCard title={t('firtDashboard.quicklinks.marketplace.title')}>
      <ul>
        <li>
          <Link to='/myaccount'>
            {t('firtDashboard.quicklinks.marketplace.userAccount')}
          </Link>
        </li>
        <li>
          <Link to='/feeds'>
            {t('firtDashboard.quicklinks.marketplace.browseNotifications')}
          </Link>
        </li>
        <li>
          <Link to='/create-work-list'>
            {t('firtDashboard.quicklinks.marketplace.newJobOpening')}
          </Link>
        </li>
        <li>
          <Link to='/create-material-list'>
            {t('firtDashboard.quicklinks.marketplace.newMaterialNotification')}
          </Link>
        </li>
        <li>
          <Link to='/my-actions/listings'>
            {t('firtDashboard.quicklinks.marketplace.viewOffers')}
          </Link>
        </li>
      </ul>
    </QuicklinkCard>
  );
};

const BusinessCard = () => {
  const { t } = useTranslation();

  return (
    <QuicklinkCard title={t('firtDashboard.quicklinks.business.title')}>
      <ul>
        <li>
          <Link to='/business-proposal-create'>
            {t('firtDashboard.quicklinks.business.newOffer')}
          </Link>
        </li>
        <li>
          <Link to='/business-agreement-create'>
            {t('firtDashboard.quicklinks.business.newContract')}
          </Link>
        </li>
        <li>
          <Link to='/invoice'>
            {t('firtDashboard.quicklinks.business.newInvoice')}
          </Link>
        </li>
        <li>
          <Link to='/create-report/report-details'>
            {t('firtDashboard.quicklinks.business.newReport')}
          </Link>
        </li>
        <li>
          <Link to='/mycustomers'>
            {t('firtDashboard.quicklinks.business.newCustomer')}
          </Link>
        </li>
        <li>
          <Link to='/myresources'>
            {t('firtDashboard.quicklinks.business.newResource')}
          </Link>
        </li>
      </ul>
    </QuicklinkCard>
  );
};

const ProjectToolsCard = () => {
  const _didUnmountRef = React.useRef(false);

  const [pmsToken, setPmsToken] = React.useState(null);

  React.useEffect(
    () => () => {
      _didUnmountRef.current = true;
    },
    []
  );

  React.useEffect(() => {
    axios
      .get(`${url}/api/prousers/token/user`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data: { token } }) => {
        if (!_didUnmountRef.current) {
          setPmsToken(token);
        }
      });
  }, [setPmsToken]);

  const { t } = useTranslation();

  if (!pmsToken) {
    return (
      <QuicklinkCard title={t('firtDashboard.quicklinks.projectTools.title')} />
    );
  } else {
    return (
      <QuicklinkCard title={t('firtDashboard.quicklinks.projectTools.title')}>
        <ul>
          <li>
            <a href='/myresources'>
              {t('firtDashboard.quicklinks.projectTools.addResources')}
            </a>
          </li>
          <li>
            <a href='/mycustomers'>
              {t('firtDashboard.quicklinks.projectTools.addCustomer')}
            </a>
          </li>
          <li>
            <a href={`${url}/pms/sso?token=${pmsToken}`}>
              {t('firtDashboard.quicklinks.projectTools.newProject')}
            </a>
          </li>
          <li>
            <a href={`${url}/pms/sso?token=${pmsToken}`}>
              {t('firtDashboard.quicklinks.projectTools.newQuicktask')}
            </a>
          </li>
          <li>{t('firtDashboard.quicklinks.projectTools.communicate')}</li>
          <li>
            <a href={`${url}/pms/sso?token=${pmsToken}`}>
              {t('firtDashboard.quicklinks.projectTools.followProjects')}
            </a>
          </li>
        </ul>
      </QuicklinkCard>
    );
  }
};

const IntroCard = () => {
  const { t } = useTranslation();

  return (
    <QuicklinkCard title={t('firtDashboard.quicklinks.intro.title')}>
      <ul>
        <li>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.youtube.com/watch?v=SEg6rl38eZU'
          >
            {t('firtDashboard.quicklinks.intro.marketplace')}
          </a>
        </li>
        <li>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.youtube.com/watch?v=SEg6rl38eZU'
          >
            {t('firtDashboard.quicklinks.intro.business')}
          </a>
        </li>
        <li>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.youtube.com/watch?v=SEg6rl38eZU'
          >
            {t('firtDashboard.quicklinks.intro.projectTools')}
          </a>
        </li>
      </ul>
    </QuicklinkCard>
  );
};

const OtherServicesCard = () => {
  const { t } = useTranslation();

  return (
    <QuicklinkCard title={t('firtDashboard.quicklinks.otherServices.title')}>
      <ul>
        <li>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='/proppu-muut-palvelut.pdf'
          >
            {t('firtDashboard.quicklinks.otherServices.ownWebPages')}
          </a>
        </li>
        <li>
          {' '}
          <a href='mailto:noora.kuisma@proppu.com'>
            {' '}
            {t('firtDashboard.quicklinks.otherServices.commonInvestment')}
          </a>
        </li>
        <li>
          <a href=' https://kirjanpito.proppu.com/' target='_blank'>
            {t('firtDashboard.quicklinks.otherServices.bookKeeping')}
          </a>
        </li>
      </ul>
    </QuicklinkCard>
  );
};

const ContactCard = () => {
  const { t } = useTranslation();

  return (
    <QuicklinkCard title={t('firtDashboard.quicklinks.contact.title')}>
      <ul>
        <li>
          <a href='mailto:noora.kuisma@proppu.com'>
            {t('firtDashboard.quicklinks.contact.sendEmail')}
          </a>
        </li>
        <li>
          {' '}
          <a href='tel: 0401613060'>
            {t('firtDashboard.quicklinks.contact.call')}
          </a>
        </li>
      </ul>
    </QuicklinkCard>
  );
};

const Dashboard = ({ location }) => {
  const { t } = useTranslation();

  const header = t('firtDashboard.header');
  const secondary = t('firtDashboard.second_h');
  const p1 = t('firtDashboard.firstDescription');
  const p2 = t('firtDashboard.secondDescription');
  const p3 = t('firtDashboard.third_des');

  const steps = [
    {
      name: 'Create account',
      description: 'Vitae sed mi luctus laoreet.',
      href: '#',
      status: 'complete',
    },
    {
      name: 'Profile information',
      description: 'Cursus semper viverra facilisis et et some more.',
      href: '#',
      status: 'complete',
    },
    {
      name: 'Business information',
      description: 'Penatibus eu quis ante.',
      href: '#',
      status: 'upcoming',
    },
    {
      name: 'Theme',
      description: 'Faucibus nec enim leo et.',
      href: '#',
      status: 'upcoming',
    },
    {
      name: 'Preview',
      description: 'Iusto et officia maiores porro ad non quas.',
      href: '#',
      status: 'upcoming',
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  function Example() {
    return (
      <nav aria-label='Progress'>
        <ol role='list' className='overflow-hidden'>
          {steps.map((step, stepIdx) => (
            <li
              key={step.name}
              className={classNames(
                stepIdx !== steps.length - 1 ? 'pb-10' : '',
                'relative'
              )}
            >
              {step.status === 'complete' ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className='absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600'
                      aria-hidden='true'
                    />
                  ) : null}
                  <a
                    href={step.href}
                    className='group relative flex items-start'
                  >
                    <span className='flex h-9 items-center'>
                      <span className='relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800'>
                        <CheckIcon
                          className='h-5 w-5 text-white'
                          aria-hidden='true'
                        />
                      </span>
                    </span>
                    <span className='ml-4 flex min-w-0 flex-col'>
                      <span className='text-sm font-medium'>{step.name}</span>
                      <span className='text-sm text-gray-500'>
                        {step.description}
                      </span>
                    </span>
                  </a>
                </>
              ) : step.status === 'current' ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className='absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300'
                      aria-hidden='true'
                    />
                  ) : null}
                  <a
                    href={step.href}
                    className='group relative flex items-start'
                    aria-current='step'
                  >
                    <span className='flex h-9 items-center' aria-hidden='true'>
                      <span className='relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white'>
                        <span className='h-2.5 w-2.5 rounded-full bg-indigo-600' />
                      </span>
                    </span>
                    <span className='ml-4 flex min-w-0 flex-col'>
                      <span className='text-sm font-medium text-indigo-600'>
                        {step.name}
                      </span>
                      <span className='text-sm text-gray-500'>
                        {step.description}
                      </span>
                    </span>
                  </a>
                </>
              ) : (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className='absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300'
                      aria-hidden='true'
                    />
                  ) : null}
                  <a
                    href={step.href}
                    className='group relative flex items-start'
                  >
                    <span className='flex h-9 items-center' aria-hidden='true'>
                      <span className='relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400'>
                        <span className='h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300' />
                      </span>
                    </span>
                    <span className='ml-4 flex min-w-0 flex-col'>
                      <span className='text-sm font-medium text-gray-500'>
                        {step.name}
                      </span>
                      <span className='text-sm text-gray-500'>
                        {step.description}
                      </span>
                    </span>
                  </a>
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>
    );
  }

  return (
    <section className='dashboard'>
      <Sidebar dataFromParent={location.pathname}>
        <div className='dashboard-wrapper'>
          {/* 
<Overlay children={<Example />} />
*/}
          <div className='dashboard-wrapper-content'>
            <h1 className='dashboard__heading'>{header}</h1>
            <h2 className='dashboard__heading'>{secondary}</h2>
            <div className='dashboard__text-content'>
              <p>{p1}</p>
              <p>{p2}</p>
              <p>{p3}</p>
            </div>
            <div className='quicklink-container'>
              <MarketplaceCard />
              <BusinessCard />
              <ProjectToolsCard />
              <IntroCard />
              <OtherServicesCard />
              <ContactCard />
            </div>
          </div>
        </div>
      </Sidebar>
    </section>
  );
};

export default Dashboard;
