import React, { Component } from 'react';

class Button extends Component {
  render() {
    return (
      <button
        disabled={!this.props.dis}
        className={`px-6 py-3 text-2xl w-full h-form-control text-white rounded-md ${
          !this.props.dis ? 'bg-gray-400' : 'bg-[#333]'
        }`}
      >
        {this.props.title}
      </button>
    );
  }
}
export default Button;
