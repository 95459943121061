import React, { useEffect, useState } from 'react';
import Button from '../shared/Button';
import Logo from '../../images/Full-Logo-lighter.png';
import axios from 'axios';
import { HashRouter as Router, Link, Redirect } from 'react-router-dom';
import { url, google_id } from '../../helper/helper';
import Alert from 'react-bootstrap/Alert';
import { withTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useHistory } from 'react-router-dom';
import HandleLogin from '../../helper/googleLogin/googleLogin';
import UserTypeModal from './UserTypeModal';
import { postDataWithToken } from '../../helper/api';
import LanguageComponent from './ChangeLanguage';
const Login = ({ t }) => {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [err, setErr] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [user_id, setUser_id] = useState('');
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [googleSignin, setGoogleSignin] = useState(false);
  const token = localStorage.getItem('token');
  const Lang = localStorage.getItem('_lng');

  const handleChange = async (event) => {
    const token = localStorage.getItem('token');
    localStorage.setItem('Login_user_role', event.target.value);
    await postDataWithToken(
      `${url}/api/google_assign_role`,
      { subtype: event.target.value },
      token
    ).then((res) => {
      if (res.status === 200) {
        setIsOpenUserModal(false);
        setLoggedIn(true);
        history.push('/Dashboard');
      }
    });
  };

  const handleChange1 = (event) => {
    setEmail(event.target.value);
  };
  const handleChange2 = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!email || !password) {
      return setErr(t('success.all_fields_are_required'));
    }

    const data = new FormData();
    data.set('email', email);
    data.set('password', password);

    axios
      .post(`${url}/api/login`, data)
      .then((res) => {
        setUser_id(res?.data?.success.login_user_id);

        localStorage.setItem('token', res?.data?.success.token);
        localStorage.setItem('Login_user_id', res?.data?.success.login_user_id);
        localStorage.setItem('Login_user_role', res?.data?.role[0]);
        localStorage.setItem(
          'Login_user_uuid',
          res?.data?.success.login_user_uuid
        );

        localStorage.setItem(
          'Login_user_permissions',
          JSON.stringify(res?.data?.permissions)
        );

        history.push('/Dashboard');
        //window.location.reload();
      })
      .catch((err) => {
        if (err?.response?.data?.error === 'not_verified') {
          setErr('Email address is not verified');
        } else if (err?.response?.data?.error === 'deactivated') {
          setErr(t('login.deactivated'));
        } else setErr(t('success.authentication_failed'));
        //console.log(err.response);
      });
  };

  const changeLanguage = async (lng) => {
    i18n.changeLanguage(lng);
    const token = localStorage.getItem('token');
    const value = localStorage.setItem('_lng', lng);
    const formData = new FormData();
    formData.append('user_id', user_id);
  };

  useEffect(() => {
    if (Lang) {
      i18n.changeLanguage(Lang);
    }
    if (token) {
      setLoggedIn(true);
    }
  }, [Lang, token]);

  let alert;
  if (err) {
    alert = (
      <div style={{ paddingTop: '10px' }}>
        <Alert variant='danger' style={{ fontSize: '15px' }}>
          {err}
        </Alert>
      </div>
    );
  }

  return (
    <div className='bg-[#f7f7f6] h-screen overflow-y-auto'>
      <div className='p-6 md:p-12 max-w-4xl h-screen my-0 mx-auto'>
        <div className='items-center py-6 md:mb-16 xs:mb-8'>
          <img src={Logo} alt='' className='max-w-xs mx-auto min-h-43 ' />
        </div>
        <div className='border-none rounded-2xl shadow-login bg-white'>
          <div className='p-12'>
            <div className='-mb-2'>
              <h3 className='md:text-4xl font-semibold text-center xs:text-3xl'>
                {t('login.welcome')}
              </h3>
              <h4 className='text-2xl font-semibold my-4 text-center'>
                {t('login.login')}
              </h4>
            </div>
            <form className='formstart' onSubmit={handleSubmit}>
              <div className='mb-4'>
                <input
                  className='px-6 py-3 text-2xl h-form-control w-full border border-gray-300 rounded-md'
                  onChange={handleChange1}
                  name='email'
                  type='email'
                  placeholder={t('login.email')}
                />

                <div className='invalid-feedback'>{t('login.valid_crede')}</div>
              </div>
              <div className='mb-4'>
                <input
                  className='px-6 py-3 text-2xl h-form-control w-full border border-gray-300 rounded-md'
                  onChange={handleChange2}
                  name='password'
                  type='password'
                  placeholder={t('login.Password')}
                />
              </div>
              <button className='px-6 py-3 text-2xl h-form-control w-full  rounded-md text-white bg-[#333] hover:bg-[#1a1a1a]'>
                {' '}
                {t('login.signin')}{' '}
              </button>
              {alert ? alert : null}

              <div className='mt-6'>
                <GoogleOAuthProvider clientId={google_id}>
                  <HandleLogin
                    setIsOpenUserModal={setIsOpenUserModal}
                    setLoggedIn={setLoggedIn}
                    setGoogleSignin={setGoogleSignin}
                  />
                </GoogleOAuthProvider>
              </div>
            </form>
          </div>
        </div>

        <Link
          className='block my-12 md:my-12 xs:my-8 text-2xl text-center font-semibold hover:no-underline'
          to='/forgot'
        >
          {t('login.for_msg')}
        </Link>
        <div className=''>
          <p className='text-center'>
            {t('login.reg_msg')}
            <Link
              className='py-1 px-2 ml-5 border-2 border-blue-500 rounded-md hover:no-underline hover:bg-blue-500 hover:text-white'
              to='/register'
            >
              {t('login.get_started')}
            </Link>
          </p>
        </div>

        <LanguageComponent changeLanguage={changeLanguage} language={Lang} />
        <UserTypeModal
          show={isOpenUserModal}
          handleClose={() => {
            setIsOpenUserModal(false);
          }}
          handleChange={handleChange}
        />
      </div>
    </div>
  );
};

export default withTranslation()(Login);
