import React, { useEffect, useRef, useState } from 'react';
import Button from '../shared/Button';
import Logo from '../../images/Full-Logo-lighter.png';
import axios from 'axios';
import { google_id, url, web_url } from '../../helper/helper';
import Alert from 'react-bootstrap/Alert';
import { withTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import './signup.css';
import Spinner from 'react-bootstrap/Spinner';
import 'react-phone-input-2/lib/style.css';
import i18n from '../../locales/i18n';
import { getData, postDataWithToken } from '../../helper/api';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useHistory, useParams } from 'react-router-dom';
import UserTypeModal from './UserTypeModal';
import RegModal from './RegModal';
import HandleLogin from '../../helper/googleLogin/googleLogin';
import ChangeLanguage from './ChangeLanguage';
import RegStepOne from './RegStepOne';
import RegStepTwo from './RegStepTwo';
import RegStepThree from './RegStepThree';

const Signup = ({ t }) => {
  const token = localStorage.getItem('token');
  const lang = localStorage.getItem('_lng');
  const history = useHistory();
  const ref = useRef();
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [isOpenRegModal, setIsOpenRegModal] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [step, setStep] = useState(1);

  const [state, setState] = useState({
    first_name: '',
    last_name: '',
    email: '',
    country: 'FI',
    phone: '',
    username: '',
    password: '',
    type: '',
    c_password: '',
    subtype: '',
    show_errors: false,
    status: null,
    isChecked: false,
    userName_error: '',
    userNameStatus: '',
    complete: false,
    confirmation: false,
    promo: '',
    countries: [],
    country_id: '72',
    code: '',
    packages: [],
    package_id: '',
    change: false,
    package_price: '',
    package_term_period: '',
    language: 'fi',
    errors: '',
    company_id: '',
    completed: false,
    address: {},
    skills: [],
    condominium_name: '',
    account_type: '',
    selected_skills: '',
  });
  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    email: '',
    country_id: '',
    phone: '',
    password: '',
    c_password: '',
    package_id: '',
    subtype: '',
    company_id: '',
    condominium_name: '',
    account_type: '',
    category: [],
    selected_skills: '',
  });
  const {
    country,
    show_errors,
    pass_noMach,
    userNameStatus,
    isChecked,
    confirmation,
    countries,
    code,
    language,
    address,
    selected_skills,
  } = state || {};

  const getCountries = async () => {
    const res = await getData(`${url}/api/country/${lang}`);
    setState({
      ...state,
      countries: res?.data?.data,
    });
  };

  const loadSkills = async () => {
    const lang = localStorage.getItem('_lng');
    const token = localStorage.getItem('token');
    const res = await getData(`${url}/api/skills/${lang}`, token);

    const skills = res?.data?.data?.map(
      ({ id, skills_id, skills_identifier, skills_name }) => {
        return {
          id,
          skills_id,
          skills_identifier,
          skills_name,
        };
      }
    );
    setState({
      ...state,
      skills: skills,
    });
  };

  const onRemoveSkills = (selectedList, removedItem) => {
    const mapUsers = selectedList.map((skill) => skill.id);
    const filter = mapUsers.filter((skill) => skill !== removedItem.id);
    setState({
      ...state,
      selected_skills: filter,
    });
  };

  const onSelectSkills = (selectedList, selectedItem) => {
    const selected = selectedList.map((value) => value.id);

    let list = [];
    list.push(...selected, selectedItem.id);
    let unique = [...new Set(list)];

    setState({
      ...state,
      selected_skills: unique,
    });
  };

  const confirmationModal = () => {
    return (
      <div className='registration-confirm'>
        {this.props.t('success.confirmation')}
        <button onClick={() => history.push('/')}>
          {t('login.back_to_login')}
        </button>
      </div>
    );
  };

  const setToActive = (step) => {
    switch (step) {
      case 1:
        return 'bg-[#90ee90]';
      case 2:
        return 'bg-[#90ee90]';
      case 3:
        return 'bg-[#90ee90]';

      default:
        break;
    }
  };
  const onSelect = (code) => {
    const newErrors = { ...errors };

    newErrors['country_id'] = '';
    setErrors(newErrors);
    setState((prev) => ({
      ...prev,
      code: code,
      country: code,
      country_id: countries?.find((country) =>
        country?.country_code === code
          ? 67
          : country?.country_code === code
          ? 195
          : country?.country_code === code
          ? 72
          : null
      )?.country_id,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
    localStorage.setItem('Login_user_role', value);
    if (name === 'subtype') {
      postDataWithToken(
        `${url}/api/google_assign_role`,
        { subtype: value },
        token
      ).then((res) => {
        if (res.status === 200) {
          setLoggedIn(true);
          setIsOpenUserModal(false);
          history.push('/Dashboard');
        }
      });
    }
    let newErrors = { ...errors };

    newErrors[name] = '';

    setErrors(newErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isStepValid()) {
      axios
        .post(`${url}/api/register?`, {
          first_name: state.first_name,
          last_name: state.last_name,
          email: state.email,
          country_id: state.country_id,
          country: state.country,
          phone: state.phone,
          password: state.password,
          email_notification: state.isChecked === true ? 1 : 0,
          c_password: state.c_password,
          subtype: state.subtype,
          username: state.username,
          package_id: state.package_id,
          package_price: state.package_price,
          package_term_period: state.package_term_period,
          user_type: state.user_type,
          company_id: state.company_id,
          account_type: state.account_type,
          condominium_name: state.condominium_name,
          selected_category: state.selected_category,
          language: localStorage.getItem('_lng')
            ? localStorage.getItem('_lng')
            : 'fi',
          company_id: '_' + Math.random().toString(36).substr(2, 9),
          promo_code: state.promo,
        })
        .then((res) => {
          setLoading(false);

          if (res.status) {
            setIsOpenRegModal(true);
            setTimeout(() => {
              history.push('/register-success');
            }, 5000);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 401) {
            setState({
              ...state,
              errors: t('success.all_fields_are_required'),
              show_errors: true,
            });
          }

          if (
            err?.response?.status === 401 &&
            err?.response?.data?.error?.email?.length &&
            err?.response?.data?.error?.email[0] ===
              'The email has already been taken.'
          ) {
            setState({
              ...state,
              errors: t('success.email_taken'),
              show_errors: true,
            });
          }
          if (
            err?.response?.status === 401 &&
            err?.response?.data?.error?.subtype?.length &&
            err?.response?.data?.error?.subtype[0] ===
              'The subtype field is required.'
          ) {
            setState({
              ...state,
              errors: 'The subtype field is required.',
              show_errors: true,
            });
          }

          if (err?.response?.status === 500) {
            setStatus(500);
          }
          setLoading(false);
        });
    }
  };

  const changeLanguage = (lng) => {
    const newErrors = { ...errors };

    newErrors['country_id'] = '';
    setErrors(newErrors);
    i18n.changeLanguage(lng);
    localStorage.setItem('_lng', lng);

    if (lng === 'fi') {
      setState({
        ...state,
        country: 'FI',
        country_id: 72,
        language: 'fi',
      });
    } else if (lng === 'es') {
      setState({
        ...state,
        country: 'ES',
        country_id: 195,
        language: 'es',
      });
    } else if (lng === 'est') {
      setState({
        ...state,
        country: 'EE',
        country_id: 67,
        language: 'est',
      });
    } else if (lng === 'en') {
      setState({
        ...state,
        country: '',
        country_id: countries.find((country) => country.country_code === code)
          ?.country_id,
        language: 'en',
      });
    }
  };

  const handleChange1 = (e) => {
    // setState({
    //   ...state,
    //   complete: ref.current?.checked,
    // });
    setCompleted(e.target.value);
  };

  const handleOnChange = () => {
    setState({
      ...state,
      isChecked: !isChecked,
    });
  };

  const getUserName = async (e) => {
    setState({
      ...state,
      username: e.target.value,
    });

    if (!e.target.value) {
      setState({
        ...state,
        userNameStatus: '',
      });
    }
    if (e.target.value) {
      axios
        .get(`${url}/api/check_username?username=${e.target.value}`)
        .then((result) => {
          setState({
            ...state,
            userNameStatus: result.data.status,
            userName_error: '',
          });
        })
        .catch((err) => {
          if (err.response?.data) {
            setState({
              ...state,
              userNameStatus: err.response?.data?.status,
            });
          }
        });
    }
  };
  const changePhone = (e) => {
    setState({
      ...state,
      phone: e,
    });

    const newErrors = { ...errors };

    newErrors['phone'] = '';
    setErrors(newErrors);
  };

  const getUser = (userNameStatus) => {
    if (userNameStatus === true) {
      return '3px solid green';
    } else if (userNameStatus === false) {
      return '3px solid red';
    } else {
      return '2px solid black';
    }
  };
  const {
    first_name,
    last_name,
    email,
    phone,
    password,
    c_password,
    country_id,
    subtype,
    company_id,
    condominium_name,
    account_type,
  } = errors;
  const renderForms = (step) => {
    switch (step) {
      case 1:
        return (
          <RegStepOne
            confirmation={confirmation}
            first_name={first_name}
            last_name={last_name}
            handleChange={handleChange}
            subtype={subtype}
            state={state}
            getUser={getUser}
            getUserName={getUserName}
            userNameStatus={userNameStatus}
            completed={completed}
            setCompleted={setCompleted}
            setStep={setStep}
            country_id={country_id}
            onSelect={onSelect}
            countries={countries}
            isStepValid={isStepValid}
          />
        );
      case 2:
        return (
          <RegStepTwo
            phone={phone}
            country={country}
            state={state}
            changePhone={changePhone}
            email={email}
            handleChange={handleChange}
            password={password}
            c_password={c_password}
            pass_noMach={pass_noMach}
            completed={completed}
            setStep={setStep}
            isStepValid={isStepValid}
            setState={setState}
          />
        );
      case 3:
        return (
          <RegStepThree
            handleChange={handleChange}
            confirmation={confirmation}
            handleChange1={handleChange1}
            address={address}
            company_id={state.company_id}
            company_error={company_id}
            subtype={state.subtype}
            condo_name={condominium_name}
            account_type={account_type}
            state={state}
            completed={completed}
            loadSkills={loadSkills}
            skills={errors?.selected_skills}
            skillsList={state.skills}
            onSelectSkills={onSelectSkills}
            onRemoveSkills={onRemoveSkills}
          />
        );
    }
  };

  const isStepValid = () => {
    // Validate each step based on its step number
    const {
      first_name,
      last_name,
      email,
      phone,
      password,
      c_password,
      subtype,
      company_id,
      condominium_name,
      account_type,
    } = state;

    let newErrors = {};

    switch (step) {
      case 1:
        if (!first_name.trim()) {
          newErrors.first_name = t('account.first_name_req');
        }
        if (!last_name.trim()) {
          newErrors.last_name = t('account.last_name_req');
        }
        if (!subtype.trim()) {
          newErrors.subtype = t('account.user_type_req');
        }
        break;

      case 2:
        let pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );

        if (!email.trim()) {
          newErrors.email = t('account.email_req');
        } else if (!pattern.test(email)) {
          newErrors.email = t('account.invalid_email');
        }
        if (phone && phone.length < 11) {
          newErrors.phone = t('account.invalid_phone');
        }
        if (!password) {
          newErrors.password = t('account.password_req');
        }
        if (!c_password) {
          newErrors.c_password = t('account.confirm_pass');
        }
        if (password !== c_password) {
          newErrors.password = t('account.pass_not_match');
        }

        break;

      case 3:
        if (!company_id.trim()) {
          newErrors.company_id = t('account.company_id_req');
        }
        if (
          (subtype === 'company' && !selected_skills) ||
          (subtype === 'entrepreneur' && !selected_skills)
        ) {
          newErrors.selected_skills = t('account.areas_interest_req');
        }

        if (subtype === 'condominium' && !condominium_name.trim()) {
          newErrors.condominium_name = t('account.condo_name_req');
        }
        if (subtype === 'condominium' && !account_type.trim()) {
          newErrors.account_type = t('account.account_type_req');
        }
        // if (isChecked === false) {
        //   newErrors.isChecked = 'Please select the checkbox to register';
        // }
        break;

      default:
        break;
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
      changeLanguage(language);
    }
    getCountries();

    if (token) {
      setLoggedIn(true);
    }
    if (selected_skills) {
      isStepValid();
    }
  }, [lang, token, selected_skills]);

  // if (loggedIn === true) {
  //   return <Redirect to='/index' />;
  // }
  let alert, loader;

  if (show_errors === true) {
    alert = (
      <div style={{ paddingTop: '10px' }}>
        <Alert variant='danger' style={{ fontSize: '13px' }}>
          {state.errors}
        </Alert>
      </div>
    );
  }
  if (status === 500) {
    alert = (
      <div style={{ paddingTop: '10px' }}>
        <Alert variant='danger' style={{ fontSize: '13px' }}>
          {t('success.unique')}
        </Alert>
      </div>
    );
  }
  if (loading === true) {
    loader = (
      <Spinner animation='border' role='status'>
        <span className='sr-only'> {t('login.Loading')} </span>
      </Spinner>
    );
  }

  return (
    <div className='bg-[#f7f7f6] h-screen overflow-y-auto'>
      <div className='xs:h-mobile sm:h-mobile'>
        <div className='md:p-12 xs:px-6 xs:pt-6 max-w-4xl  my-0 mx-auto'>
          <div className='items-center md:mb-16 xs:mb-8'>
            <img src={Logo} alt={Logo} className='max-w-xs mx-auto min-h-43' />
          </div>
          <div className='border-none rounded-2xl shadow-login bg-white '>
            <div className='p-12 '>
              {confirmation ? confirmationModal() : ''}
              <div
                className=''
                style={
                  confirmation ? { display: 'none' } : { display: 'block' }
                }
              >
                <h3 className='md:text-4xl font-semibold text-center xs:text-3xl'>
                  {t('login.welcome')}
                </h3>
                <p className='text-2xl font-semibold my-4 text-center'>
                  {t('login.tag')}
                </p>
              </div>
              <div className='relative h-20'>
                <div className='absolute flex justify-center items-center  bg-white z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                  <span
                    className={`relative inline-block w-5 h-5 rounded-full   ${
                      step === 1 ? setToActive(step) : 'bg-gray-300'
                    }`}
                  ></span>
                  <span
                    className={`relative inline-block w-5 h-5 rounded-full ml-4  ${
                      step === 2 ? setToActive(step) : 'bg-gray-300'
                    }`}
                  ></span>
                  <span
                    className={`relative inline-block w-5 h-5 rounded-full ml-4  ${
                      step === 3 ? setToActive(step) : 'bg-gray-300'
                    }`}
                  ></span>
                </div>
              </div>
              <form
                noValidate
                className='md:mt-5 xs:m-0'
                onSubmit={handleSubmit}
                style={
                  confirmation ? { display: 'none' } : { display: 'block' }
                }
              >
                {renderForms(step)}

                {step === 3 ? (
                  <>
                    {loading ? (
                      loader
                    ) : (
                      <div className='flex flex-row justify-between w-full'>
                        <button
                          className='px-6 py-3 text-2xl h-form-control w-full rounded-md text-white bg-[#333] hover:bg-[#1a1a1a]'
                          onClick={() => {
                            setStep((prev) => prev - 1);
                          }}
                        >
                          {t('account.previous')}
                        </button>
                        <div style={{ width: '20px' }}></div>

                        <Button dis={completed} title={t('account.Register')} />
                      </div>
                    )}
                  </>
                ) : (
                  ''
                )}

                {alert ? alert : null}
                {step === 1 ? (
                  <div style={{ marginTop: '2rem' }}>
                    <GoogleOAuthProvider clientId={google_id}>
                      <HandleLogin
                        setIsOpenUserModal={setIsOpenUserModal}
                        setLoggedIn={setLoggedIn}
                      />
                    </GoogleOAuthProvider>
                  </div>
                ) : (
                  ''
                )}
              </form>
            </div>
          </div>

          <p className='block md:my-12 xs:my-8 text-2xl text-center font-semibold hover:no-underline xs:mb-0'>
            {' '}
            <Link to='/'> {t('login.back_to')}</Link>
          </p>
          <ChangeLanguage changeLanguage={changeLanguage} language={language} />

          <UserTypeModal
            show={isOpenUserModal}
            handleClose={() => {
              setIsOpenUserModal(false);
            }}
            handleChange={handleChange}
          />
          <RegModal
            show={isOpenRegModal}
            handleClose={() => {
              setIsOpenRegModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Signup);
