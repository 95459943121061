import React, { useEffect, useState, useCallback } from 'react';

export const filterContacts = (searchField, contacts) => {
  return contacts?.filter((contact) =>
    contact?.group_name.toLowerCase().includes(searchField.toLowerCase())
  );
};

export const filterClients = (searchField, contacts) => {
  return contacts
    ?.map((contact) => {
      return {
        ...contact,
        group_name: contact.first_name + ' ' + contact.last_name,
      };
    })
    .filter((contact) => {
      return contact?.group_name
        .toLowerCase()
        .includes(searchField.toLowerCase());
    });
};

export const HandleWindowResizing = () => {
  const [windowSize, setWindowSize] = useState(0);
  const handleWindowResize = useCallback((event) => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    handleWindowResize();
  }, []);
  return [windowSize];
};

const AccordionItemChat = ({
  messages,
  setId,
  groupId,
  isExpanded,
  fetchData,
  setGroupId,
  setProjectId,
  windowSize,
  setOpen,
}) => {
  return (
    <>
      {messages?.map((message) => {
        return (
          <div className='accord-box' key={message.group_id}>
            <div className='accord-heading'>
              <div
                className='accord__container'
                style={{ borderBottom: isExpanded ? '1px solid #f2f4f4' : '' }}
              ></div>
            </div>
            {isExpanded ? (
              <div
                className={
                  windowSize <= 574 ? 'sidebar-messages' : 'sidebar-message'
                }
              >
                <li
                  onClick={() => {
                    setId(message?.group_id);
                    setGroupId(message?.group_id);
                    fetchData(message?.group_id);
                    setProjectId(message?.project_id);
                    setOpen(false);
                  }}
                  key={message.id}
                  className={
                    message.group_id === groupId
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      //borderBottom: '1px solid  #F2F4F3',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p
                      className='nav-link-text'
                      style={{ padding: '5px', margin: '5px 1rem' }}
                    >
                      {message?.group_name}
                    </p>
                    <p
                      className='nav-link-text'
                      style={{ margin: '5px 1rem' }}
                      onClick={fetchData}
                    >
                      {message.unread_message_count > 0 &&
                      message.group_id !== groupId ? (
                        <span className='badge badge-danger'>
                          {message?.unread_message_count}
                        </span>
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                </li>
              </div>
            ) : null}
          </div>
        );
      })}
    </>
  );
};
export default AccordionItemChat;
