import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import arrow from '../../../../images/arrow-left.svg';
import NewChatSidebar from './NewChatSidebar';
import { Fragment } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog, Menu, Transition } from '@headlessui/react';
import UserModal from './userModal';
import { useParams } from 'react-router-dom';
import downArrow from '../../../../images/arrow-down.svg';
import './PmsMessage.scss';
import NewChatModal from './NewChatModal';
import { filterContacts } from '../../../../helper/chatFunctions/chat';
import AccordionItemChat from '../../../../helper/chatFunctions/chat';
import Overlay from '../../../shared/Overlay';
const Sidebar = ({
  setGroupId,
  fetchData,
  messages,
  setId,
  groupId,
  setProjectId,
  displayUserModal,
  windowSize,
  projectId,
  param,
  setDisplayUserModal,
  isOpenChatModal,
  setIsOpenChatModal,
  setOpen,
  open,
}) => {
  const params = useParams();
  const [searchField, setSearchField] = useState('');
  const [isOpenModalUser, setIsOpenModalUser] = useState(false);
  const [isExpanded, setIsExpanded] = useState({
    activeProjects: true,
    completedProjects: false,
    clients: false,
    resources: false,
    group: false,
  });

  const addScroll = () => {
    return { overflowY: 'scroll', height: 'calc(100vh - 64px)' };
  };

  useEffect(() => {
    addScroll();
  }, [messages]);

  const reloadPage = () => {
    if (windowSize < 575) {
      setGroupId(null);
      setDisplayUserModal(undefined);
    }
  };
  console.log(open);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <div className='fixed inset-0' />
        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                    <div className='p-6'>
                      <div className='flex items-start justify-between'>
                        <Dialog.Title className='text-base font-semibold leading-6 text-gray-900'>
                          Team
                        </Dialog.Title>
                        <div className='ml-3 flex h-7 items-center'>
                          <button
                            type='button'
                            className='relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500'
                            onClick={() => setOpen(false)}
                          >
                            <span className='absolute -inset-2.5' />
                            <span className='sr-only'>Close panel</span>
                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='border-b border-gray-200'>
                      <div className='px-6'>
                        <nav className='-mb-px flex space-x-6'>
                          {/* {tabs.map((tab) => (
                              <a
                                key={tab.name}
                                href={tab.href}
                                className={classNames(
                                  tab.current
                                    ? 'border-indigo-500 text-indigo-600'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                                )}
                              >
                                {tab.name}
                              </a>
                            ))} */}
                        </nav>
                      </div>
                    </div>
                    <ul
                      role='list'
                      className='flex-1 divide-y divide-gray-200 overflow-y-auto mt-9'
                    >
                      <div className='sidebar-header'>
                        {/* {windowSize > 575 ||
                        (windowSize < 575 && */}
                        {param?.id !== undefined &&
                        param?.title !== undefined ? (
                          <div className='ellipse'>
                            <Link to='/manage-projects'>
                              <img src={arrow} alt='' className='arrow' />
                            </Link>
                          </div>
                        ) : (
                          
                            <button className='ellipse' onClick={() => setOpen(false)}>
                              <img src={arrow} alt='' className='arrow' />
                            </button>
                           
                        )}

                        {displayUserModal !== undefined &&
                        displayUserModal ===
                          Number(localStorage.getItem('Login_user_id')) ? (
                          <div
                            className='add-chat-sidebar cursor-pointer'
                            onClick={() => {
                              setIsOpenModalUser(true);
                            }}
                          >
                            &#43; Add user
                          </div>
                        ) : (
                          <div
                            className='add-chat-sidebar cursor-pointer'
                            onClick={() => {
                              setIsOpenChatModal(true);
                            }}
                          >
                            &#43; New Chat
                          </div>
                        )}
                      </div>

                      <div>
                        <input
                          type='text'
                          className='search-contacts'
                          placeholder={'Search projects'}
                          onChange={(e) => setSearchField(e.target.value)}
                        />
                      </div>
                      <div className='sidebar-header-contact'></div>
                       
                      <div   className="relative">
          <div className="sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-3 text-sm   leading-3 text-gray-600">
            <h4>Active Projects</h4>
          </div>
                      <ul role="list" className="flex-1 divide-y divide-gray-200 overflow-y-auto"> 
                      <AccordionItemChat
                        isExpanded={isExpanded.activeProjects}
                        messages={filterContacts(
                          searchField,
                          messages?.filter(
                            (message) => message?.active_projects_count === 1
                          )
                        )}
                        setId={setId}
                        groupId={groupId}
                        addScroll={addScroll}
                        fetchData={fetchData}
                        setGroupId={setGroupId}
                        setProjectId={setProjectId}
                        windowSize={windowSize}
                        setOpen={setOpen}
                      />
                      </ul>
                      </div>
                       
                       
                        
                   
                      <div   className="relative">
          <div className="sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-3 text-sm   leading-3 text-gray-600">
            <h4>Completed Projects</h4>
          </div>
                      <ul role="list" className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                      <AccordionItemChat
                        isExpanded={isExpanded.completedProjects}
                        messages={filterContacts(
                          searchField,
                          messages?.filter(
                            (message) => message?.completed_projects_count === 1
                          )
                        )}
                        setId={setId}
                        groupId={groupId}
                        addScroll={addScroll}
                        fetchData={fetchData}
                        setGroupId={setGroupId}
                        setProjectId={setProjectId}
                        windowSize={windowSize}
                        setOpen={setOpen}
                      />
                       </ul>
                      </div>
                     
                      <div   className="relative">
          <div className="sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-3 text-sm   leading-3 text-gray-600">
            <h4>Clients</h4>
          </div>
                      <ul role="list" className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                     
                      <AccordionItemChat
                        isExpanded={isExpanded.clients}
                        messages={filterContacts(
                          searchField,
                          messages?.filter(
                            (message) => message.group_type === 1
                          )
                        )}
                        setId={setId}
                        groupId={groupId}
                        addScroll={addScroll}
                        fetchData={fetchData}
                        setGroupId={setGroupId}
                        setProjectId={setProjectId}
                        windowSize={windowSize}
                        setOpen={setOpen}
                      />
                      </ul>
                      </div>
                       
                      <div   className="relative">
          <div className="sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-3 text-sm   leading-3 text-gray-600">
            <h4>Resources</h4>
          </div>
                      <ul role="list" className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                     
                      <AccordionItemChat
                        isExpanded={isExpanded.resources}
                        messages={filterContacts(
                          searchField,
                          messages?.filter(
                            (message) => message.group_type === 2
                          )
                        )}
                        setId={setId}
                        groupId={groupId}
                        addScroll={addScroll}
                        fetchData={fetchData}
                        setGroupId={setGroupId}
                        setProjectId={setProjectId}
                        windowSize={windowSize}
                        setOpen={setOpen}
                      />
                      </ul></div>
                     
                      <div   className="relative">
          <div className="sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-3 text-sm   leading-3 text-gray-600">
            <h4>Group Chat</h4>
          </div>
                      <ul role="list" className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                     
                      <AccordionItemChat
                        isExpanded={isExpanded.group}
                        messages={filterContacts(
                          searchField,
                          messages?.filter(
                            (message) => message.group_type === 3
                          )
                        )}
                        setId={setId}
                        groupId={groupId}
                        addScroll={addScroll}
                        fetchData={fetchData}
                        setGroupId={setGroupId}
                        setProjectId={setProjectId}
                        windowSize={windowSize}
                        setOpen={setOpen}
                      />
                        </ul></div>
                      {/* </ul> */}
                      <UserModal
                        show={isOpenModalUser}
                        handleClose={() => {
                          setIsOpenModalUser(false);
                        }}
                        params={params}
                        groupId={groupId}
                        projectId={projectId}
                      />
                      <NewChatModal
                        show={isOpenChatModal}
                        handleClose={() => {
                          setIsOpenChatModal(false);
                        }}
                      />
                    </ul>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Sidebar;
