import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getData, postDataWithToken } from '../../../../helper/api';
import { url } from '../../../../helper/helper';

import { withTranslation } from 'react-i18next';
let selectedUsers = [];
const UserModal = (props) => {
  const [users, setUsers] = useState([]);
  const t = props.t;

  //Get all users
  const getUsers = async (id) => {
    console.log(id);
    const token = localStorage.getItem('token');
    await getData(`${url}/api/project/get_project_users/${id}`, token)
      .then((res) => {
        const users = res?.data?.selected_users?.map((user) =>
          user.users_group?.map((user) => user?.cug_pro_user_id)
        );
        const selectedUser = Array.from(new Set(users));

        let data2 = res?.data?.all_users?.map((user) => {
          return {
            ...user,
            status: selectedUser[0].includes(user.ur_resource_id),
          };
        });

        for (const single of data2) {
          if (single.status === true) {
            selectedUsers.push(single.ur_resource_id);
          }
        }
        setUsers(data2);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  //Get selected(checked) users
  const handleCheckboxChange = (e) => {
    const check = selectedUsers.includes(e);
    if (check === true) {
      const index = selectedUsers.indexOf(e);

      if (index > -1) {
        selectedUsers.splice(index, 1);
      }
    } else {
      selectedUsers.push(e);
    }

    const newState = users.map((obj) => {
      if (obj.ur_resource_id === e) {
        return { ...obj, status: !obj.status };
      }
      return obj;
    });

    setUsers(newState);
  };
  //Save selected users in db
  const saveSelectedUsers = async () => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('user_group_id', props.groupId);
    for (const key of Object.keys(selectedUsers)) {
      if (selectedUsers[key] !== null) {
        formData.append('user_id[]', selectedUsers[key]);
      }
    }
    await postDataWithToken(
      `${url}/api/project/add_remove_users`,
      formData,
      token
    )
      .then((res) => {
        //console.log(res);
        props.handleClose();
        // if (res.status === 200) {
        //   setShowAlert(!showAlert);
        // }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    try {
      getUsers(
        props.projectId === undefined || props.projectId === null
          ? Number(props.params.id)
          : props.projectId
      );
    } catch (error) {
      console.log(error);
    }
  }, [props.projectId, props.params.id]);

  return (
    <>
      <Modal
        isOpen={props.show}
        toggle={() => {
          props.handleClose();
        }}
        className={'modalPropu'}
        style={{ width: '100%' }}
        centered
        dialogClassName='modal-20w'
        aria-labelledby='contained-modal-title-vcenter'
      >
        <ModalHeader
          toggle={() => {
            props.handleClose();
          }}
          className='d-flex justify-content-between'
        >
          <div className='modal-title'>Users</div>
        </ModalHeader>
        <ModalBody>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {users?.map((user) => {
              return (
                <div
                  className='form-check form-check-inline'
                  style={{ fontSize: '15px', fontWeight: '500' }}
                  key={user.ur_id}
                >
                  <input
                    checked={user?.status}
                    id={user?.ur_resource_id}
                    type='checkbox'
                    name={`${user?.ur_resource_id}`}
                    className='form-check-input'
                    onClick={() => {
                      handleCheckboxChange(user?.ur_resource_id);
                    }}
                  />
                  <label className='form-check-label'>
                    {user?.first_name} {user?.last_name}
                  </label>
                </div>
              );
            })}
          </div>
          <br />
          <hr />
          Add and remove users from this chat
          <hr />
          <button className='btn btn-primary p-2' onClick={saveSelectedUsers}>
            Update users
          </button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTranslation()(UserModal);
