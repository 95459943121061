import React,  {Component} from 'react'

class EmailId extends Component {
  render() {
    return (
        <input className="form-control" name="email" type="email" placeholder="Email id" />
    )
  }
}

export default EmailId
