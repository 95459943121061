import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PageNotFound = ({ t }) => {
  return (
    <>
      <main>
        <div className='relative h-screen'>
          <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-screen-md w-full leading-5 text-center px-4'>
            <div className='absolute h-24 min-w-8xl top-0 left-1/2 transform -translate-x-1/2 z-[-1]'>
              <h1 className='font-maven-pro font-extrabold text-21xl text-gray-300 m-0 absolute left-1/2 top-1/2s transform -translate-x-1/2 -translate-y-1/2'>
                404
              </h1>
            </div>
            <h2 className='font-maven-pro  text-6xl text-blue-500 font-semibold uppercase m-0'>
              {t('error404.heading')}
            </h2>
            <p className='mb-5'>{t('error404.headingdescription')}</p>

            <Link
              to='/'
              className='font-maven-pro text-lg uppercase py-4 px-9 rounded-full border-2 border-blue-500 text-blue hover:text-white hover:no-underline hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-500 font-medium text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-500'
            >
              {t('error404.buttonbackhome')}
            </Link>
          </div>
        </div>
      </main>
    </>
  );
};

export default withTranslation()(PageNotFound);
