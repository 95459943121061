import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../shared/Breadcrumb';
import { Link } from 'react-router-dom';
import BussinessSidebar from '../../shared/BussinessSidebar';
import { withTranslation } from 'react-i18next';
import { url } from '../../../helper/helper';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './roles.css';
import Sidebar from '../../shared/Sidebar';
const EditRoles = ({ t, location }) => {
  const [name, setName] = useState('');
  const [errors, setErrors] = useState('');
  const history = useHistory();
  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = () => {
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('RoleID');

    axios
      .get(`${url}/api/role_list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const match = res.data.find((result) => String(result.id) === id);

        setName(match.display_name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    setName(e.target.value);
    const newErrors = errors;
    setErrors(newErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let hasErrors = false;
    let newErrors = errors;

    if (!name) {
      hasErrors = true;
      newErrors = 'Name Required';
    }
    if (name.length > 1 && name.length < 5) {
      hasErrors = true;
      newErrors = 'Please enter at least 5 characters';
    }
    if (hasErrors) {
      setErrors(newErrors);
      return;
    }
    EditRole();
    history.push('/permission');
  };

  const EditRole = () => {
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('RoleID');

    axios
      .post(
        `${url}/api/role/update/${id}`,
        { name: name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Sidebar dataFromParent={location.pathname}>
        <Breadcrumb
          pages={[
            {
              name: t('header.my_bussiness'),
              href: '/business-dashboard',
            },
            {
              name: t('create_report.edit_roles'),
              href: '/roles/edit',
            },
          ]}
        />
        <h3 className='project-title'>Create Project</h3>
        <section className='create-section'>
          <form className='role-form' onSubmit={handleSubmit}>
            <label>
              Role
              <input
                className={errors ? 'hasError' : 'noError'}
                type='text'
                placeholder='Role'
                value={name}
                onChange={handleChange}
                name='input'
              />
              <div className='error'>{errors}</div>
            </label>
            <button type='submit' className='create'>
              {t('create_report.edit')}
            </button>
          </form>
        </section>
      </Sidebar>
    </>
  );
};

export default withTranslation()(EditRoles);
