import React, { useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import DropzoneFolder from './DropzoneFolder';
import { Helper, url } from '../../../helper/helper';
import { withTranslation } from 'react-i18next';
const Image = ({ t, image, removeImage, index }) => {
  console.log('image'+image);
  return (
    <div className='relative overflow-hidden flex flex-1 flex-col p-8'>
       {
  (
    String(localStorage.getItem('Login_user_id') || '').trim() !== 
    String(image.user_id || '').trim() && 
    typeof image.user_id !== 'undefined'
  )
  ? (
    <div class="absolute left-0 top-0 h-16 w-16">
      <div style={{ textTransform: 'capitalize' }} class="absolute transform -rotate-45 bg-custom-proppu-purple text-center text-white font-semibold py-1 left-[-34px] top-[32px] w-[170px]">
        {image.first_name}
      </div>
    </div>
  ) : (
    <div>
      {/* Content for the else condition */}
    </div>
  )
}
      {/* {image?.folder_name !== null ? (
        <div className='-mt-px flex divide-x divide-gray-200'>
          <div className='btn btn-info -ml-px flex w-0 flex-1 text-2xl'>
            {`Folder name: ${image?.folder_name}`}
          </div>
        </div>
      ) : (
        <div className='-mt-px flex divide-x divide-gray-200'>
          <div className='btn btn-info -ml-px flex w-0 flex-1 text-2xl'>
            {'No folder'}
          </div>
        </div>
      )} */}
      <img
        alt={`img - ${image.id}`}
        src={
          image.src
            ? image.src
            :  url +`/images/filemanager/${image.name}`
        }
        className='mx-auto flex-shrink-0 rounded-full w-full h-60'
      />
      {
(
    String(localStorage.getItem('Login_user_id') || '').trim() === 
    String(image.user_id || '').trim() && 
    typeof image.user_id !== 'undefined'
  )
  ? (
      <div className='-mt-px flex divide-x divide-gray-200'>
        <div
          className='btn btn-danger -ml-px flex w-0 flex-1 text-2xl items-center justify-center'
          onClick={() => removeImage(image.src ? image.id : image.id)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block">
  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
</svg>

        </div>
      </div>
      ) : (
    <div>
      
    </div>
  )
      }
    </div>
  );
};

// ImageList Component
const ImageList = ({
  t,
  images,
  removeImage,
  inputReference,
  setName,
  saveFolderName,
  dropzoneRef,
  onDrop,
  display,
}) => {
  // render each image by calling Image component
  const renderImage = (image, index) => {
    return (
      <li className='col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow mt-6'>
        <Image
          index={index}
          image={image}
          key={`${image.id}-image`}
          removeImage={removeImage}
        />
      </li>
    );
  };

  // Return the list of files
  return (
    <ul
      id='draggable-list'
      className='grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'
    >
      <div
        className={`col-span-1 ${
          display ? 'block' : 'hidden'
        } divide-y divide-gray-200 rounded-lg bg-white text-center shadow mt-6`}
      >
        <div className='text-center cursor-pointer flex flex-col justify-center items-center h-full'
         onMouseOver={() => inputReference.current.focus()}>
          <svg
            className='mx-auto h-12 w-12 text-gray-400'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            aria-hidden='true'
          >
            <path
              vectorEffect='non-scaling-stroke'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
            />
          </svg>
          <input
            className='mt-2 text-lg text-center font-semibold text-gray-900'
            placeholder={t('myBusiness.files.no_folder')}
            ref={inputReference}
            onChange={(e) => setName(e.target.value)} 
           // onBlur={saveFolderName}
            id="folder_name"
            
          />

          <p className='mt-1 text-sm text-gray-500'>
          {t('myBusiness.files.folder_text')} 
          </p>
          <div className='mt-6 mb-6'>
            <button
              onClick={saveFolderName}
             
              type='button'
              className='inline-flex items-center rounded-md bg-indigo-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >
              <PlusIcon className='-ml-0.5 mr-1.5 h-5 w-5' aria-hidden='true' />
              {t('myBusiness.files.new_folder')} 
            </button>
          </div>
        </div>
        <DropzoneFolder
          onDrop={onDrop}
          accept={'image/*,mime/type,application/pdf'}
          dropzoneRef={dropzoneRef}
        />
      </div>
      {images?.map(renderImage)}
    </ul>
  );
};

export default withTranslation() (ImageList);
