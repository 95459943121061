import React from 'react';
import { useDropzone } from 'react-dropzone';

const DropzoneFolder = ({ onDrop, accept, dropzoneRef }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
  });

  return (
    <div className='dropzone border-none' {...getRootProps()} ref={dropzoneRef}>
      <input className='dropzone-input' {...getInputProps()} />
      <div className='text-center'></div>
    </div>
  );
};

export default DropzoneFolder;
