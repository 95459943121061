import React from 'react';
import { Redirect } from 'react-router-dom';

const Logout = () => {
  localStorage.clear();
  localStorage.setItem('_lng', 'fi');

  return <Redirect to='/' />;
};

export default Logout;
