import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Breadcrumb from '../../../../shared/Breadcrumb';
import BussinessSidebar from '../../../../shared/BussinessSidebar';
import { url } from '../../../../../helper/helper';

import './ReportData.css';
import Sidebar from '../../../../shared/Sidebar';
import { getData, postDataWithToken } from '../../../../../helper/api';

const ReportData = ({ t, location }) => {
  const [reportData, setReportData] = useState({});

  const { id } = useParams();

  useEffect(() => {
    getData(`${url}/api/project_report/report_data/view_pdf/${id}`).then(
      (res) => console.log(res)
    );
  }, []);

  useEffect(() => {
    getData(`${url}/api/project_report/report_data/${id}`).then((res) =>
      setReportData(res.data.project_report)
    );
  }, []);

  const sendReport = async (e) => {
    e.preventDefault();
    await postDataWithToken(`${url}/api/project_report/report_data/create`, {
      prd_data: '',
      prd_image: '',
    }).then((res) => console.log(res));
  };

  return (
    <>
      <Sidebar dataFromParent={location.pathname}>
        <Breadcrumb
          pages={[
            {
              name: t('myBusiness.report.my_bussiness'),
              href: '/business-dashboard',
            },
            {
              name: t('b_sidebar.create_report.create'),
              href: '/roles/create',
            },
          ]}
        />

        <h3 className='report-data-title'>
          {' '}
          {t('b_sidebar.create_report.create')}
        </h3>
        <a
          href={`${url}/pms/project_report/report/view/${id}`}
          className='report-data-view'
        >
          View Report
        </a>

        <form className='report-data' onSubmit={sendReport}>
          <h4>Add Report</h4>

          <p>File</p>
          <div className='report-data-file'>
            <label>
              <input type='file' />
            </label>
          </div>
          <label className='report-data-description'>
            <p>Description</p>
            <textarea placeholder='Description'></textarea>
          </label>
          <button type='submit'>Save</button>
        </form>
        <div className='report-data-image'>
          <img src='' alt='' />
          <p>{reportData && reportData.updated_at}</p>
          <p>
            {reportData && reportData.pr_status === 'active'
              ? 'Work in progress'
              : 'Done'}
          </p>
        </div>
      </Sidebar>
    </>
  );
};

export default withTranslation()(ReportData);
