import React, { useState, useEffect } from 'react';
import { ReactComponent as LeftArrow } from '../../../images/left-arrowCar.svg';
import { ReactComponent as RightArrow } from '../../../images/right-arrowCar.svg';
import { Helper, url } from '../../../helper/helper';
const FolderCarousel = ({ images }) => {
  const [current, setCurrent] = useState(0);
  const [gallery, setGallery] = useState([]);

  const nextSlide = () => {
    setCurrent(current === images?.length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? images.length - 1 : current - 1);
  };

  if (!Array.isArray(images) && images.length <= 0) {
    return null;
  }

  const Buttons = () => {
    let li = [];
    gallery && gallery.forEach((img, index) => li.push(index));
    return (
      <ol className='carousel_indicators bottom-[-40px]'>
        {li.map((slide, i) => {
          console.log(slide);
          return (
            <li
              key={i}
              className={current === slide ? 'activeBTN' : ''}
              value={i}
              onClick={(e) => setCurrent(e.target.value)}
            >
              {slide}
            </li>
          );
        })}
      </ol>
    );
  };

  return (
    <>
      {images[0] === undefined || images[0] === null ? (
        ''
      ) : (
        <section
          className='carousel-slider ml-0 mb-0'
          style={
            images[0] !== undefined ? { display: 'Flex' } : { display: 'None' }
          }
        >
          <LeftArrow className='carousel-arrow-left' onClick={prevSlide} />
          <RightArrow className='carousel-arrow-right' onClick={nextSlide} />
          {images?.map((slide, index) => {
            return (
              <>
                <div
                  className={
                    index === current
                      ? 'carousel-slide active'
                      : 'carousel-slide'
                  }
                  key={index}
                >
                  {index === current && (
                    <>
                      <img
                        alt={`img - ${slide.id}`}
                        src={
                          slide.src
                            ? slide.src
                            : url +`/images/filemanager/${slide.name}`
                        }
                        className='mx-auto flex-shrink-0 rounded-full'
                      />
                    </>
                  )}
                </div>
              </>
            );
          })}
        </section>
      )}
    </>
  );
};

export default FolderCarousel;
